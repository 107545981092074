import { AppName } from 'src/appConfig/constants';

export const urlAppMap = {
  [AppName.DCDEcms]: 'dcd-ecms',
  [AppName.Forms]: 'forms',
  [AppName.TDI]: 'tdi',
  [AppName.TDICoverage]: 'tdi-coverage',
  [AppName.WCCoverage]: 'wc-coverage',
  [AppName.PHCCoverage]: 'phc-coverage',
  [AppName.Admin]: 'admin',
};
