import { isEmpty } from 'src/validations';
import { PaginationResponseType, toAuditData } from '../helpers';
import { TDIPlanKey } from './keys';
import { TDIPlan } from './types';

export const toTdiPlanDetail = (data) => ({
  ...data,
  [TDIPlanKey.InsuranceCarrierCarrierId]: data?.insuranceCarrier?.carrierId,
  [TDIPlanKey.EmployerObject]: data?.employer,
  ...toAuditData(data),
});

export const toTDIPlans = (data): PaginationResponseType<TDIPlan> => {
  return {
    ...data,
    data: data?.data?.map((item) => ({
      ...item,
      [TDIPlanKey.CarrierID]: item?.insuranceCarrier?.carrierId,
      [TDIPlanKey.InsuranceCarrierId]: item?.insuranceCarrier?.id,
      [TDIPlanKey.InsuranceCarrierStatusDate]: item?.insuranceCarrier?.dcdStatusDate,
      [TDIPlanKey.Employer]: item?.employer?.accountName,
      [TDIPlanKey.EmployerId]: item?.employer?.id,
      [TDIPlanKey.EmployerDOLNumber]: item?.employer?.laborNumber,
      [TDIPlanKey.DCDStatusDate]: item?.employer?.dcdStatusDate,
      [TDIPlanKey.DCDStatus]: item?.employer?.dcdStatus,
      [TDIPlanKey.InsuranceCarrierStatus]: item?.insuranceCarrier?.dcdStatus,
      [TDIPlanKey.NumberOfEmployees]: isEmpty(item?.numberOfEmployees)
        ? ''
        : item?.numberOfEmployees?.toString(),
      ...toAuditData(item),
    })),
  };
};
