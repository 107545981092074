export enum VocationalRehabilitationKey {
  Id = 'id',
  CaseId = 'caseId',
  CaseNumber = 'caseNumber',
  ClaimantName = 'claimantName',
  ClaimantPhoneNumber = 'claimantPhoneNumber',
  ClaimantAddress = 'claimantAddress',
  DateOfInjury = 'dateOfInjury',
  ProcessNumber = 'processNumber',
  ReferralDate = 'referralDate',
  Status = 'status',
  WCRP23Received = 'wcrp23ReceivedDate',
  WCRP24Received = 'wcrp24ReceivedDate',
  WCRP3AReceived = 'wcrp3aReceivedDate',
  WCRP14Received = 'wcrp14ReceivedDate',
  ClosureComment = 'closureComment',
  Case = 'case',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedByName = 'createdByName',
  UpdatedByName = 'updatedByName',
}
