export enum RelatedTDICoveragesKey {
  Id = 'id',
  RecordNum = 'recordNum',
  TdiCoverageNumber = 'tdiCoverageNumber',
  TdiCoverageAction = 'tdiCoverageAction',
  TdiPlanType = 'tdiPlanType',
  TdiPlanTypeId = 'tdiPlanTypeId',
  TdiPlanTypeNumber = 'tdiPlanNumber',
  ActionTaken = 'actionTaken',
  EmployeeClass = 'employeeClass',
  EmployerId = 'employerId',
  EffectiveDate = 'effectiveDate',
  Plan = 'plan',
  InsuranceCarrierAccountName = 'insuranceCarrierAccountName',
  InsuranceCarrierId = 'insuranceCarrierId',
  CarrierId = 'carrierId',
  WagesReplacedPercent = 'wagesReplacedPercent',
  WaitingPeriodDay = 'waitingPeriodDay',
  WeekDuration = 'weekDuration',
  PlanName = 'planName',
  PlanType = 'planType',

  //===================== Audit Information =====================
  UpdatedUser = 'updatedUser',
  CreatedUser = 'createdUser',
}

export enum TDIActionType {
  Stat = 'New Stat Action',
  Other = 'New Other Action',
}
