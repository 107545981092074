export enum TDI46Key {
  Id = 'id',
  TDI46Number = 'tdi46Number',
  TDICase = 'tdiCase',
  TDICaseNumber = 'tdiCaseNumber',
  TDICaseId = 'tdiCaseId',
  Claimant = 'claimant',
  ClaimantId = 'claimantId',
  ClaimantName = 'claimantName',
  Employer = 'employer',
  EmployerId = 'employerId',
  EmployerName = 'employerName',
  Carrier = 'insuranceCarrier',
  CarrierId = 'carrierId',
  InsuranceCarrierId = 'insuranceCarrierId',
  InsuranceCarrierName = 'insuranceCarrierName',
  DateReceived = 'dateReceived',
  AdjustingCompanyName = 'adjustingCompanyName',
  AdjustingCompanyId = 'adjustingCompanyId',

  CreatedByUser = 'createdUser',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  UpdatedUser = 'updatedUser',
  UpdatedAt = 'updatedAt',
  LastModifiedDate = 'updatedAt',

  CreatedUser = 'createdUser',
  // ============= Header =============
  Type = 'type',
  NewAmend = 'newAmend',
  AppealReceivedDate = 'appealReceivedDate',

  // ============= Claimant =============
  RawClaimantFirstName = 'rawClaimantFirstName',
  RawClaimantLastName = 'rawClaimantLastName',
  RawClaimantMiddleInitial = 'rawClaimantMiddleInitial',
  RawClaimantSuffixName = 'rawClaimantSuffix',
  RawClaimantAddress = 'rawClaimantAddress',
  RawClaimantCity = 'rawClaimantCity',
  RawClaimantState = 'rawClaimantState',
  RawClaimantZipCode = 'rawClaimantZipcode',
  RawClaimantEmail = 'rawClaimantEmail',
  RawClaimantPhone = 'rawClaimantPhone',
  RawClaimantSSN = 'rawClaimantSSN',
  RawClaimantPassport = 'rawClaimantPassport',
  RawClaimantFirstDateOfDisabilityClaimed = 'rawClaimantFirstDateOfDisabilityClaimed',
  RawClaimantFiledDate = 'rawClaimantFiledDate',
  RawClaimantClaimOrFileNumber = 'rawClaimantClaimOrFileNumber',
  RawClaimantDateNoticeSentDLIR = 'rawClaimantDateNoticeSentDLIR',
  RawClaimantDateNoticeSentClaimant = 'rawClaimantDateNoticeSentClaimant',

  // ============= Denial Reasons =============
  IsFailureToMeetEligibility = 'isDenyFailureToMeetEligibility',
  IsDidNotPerformRegularService = 'isDenyDidNotPerformRegularService',
  IsNotDisabledBeyond7Days = 'isDenyNotDisabledBeyond7Days',
  IsReceived26WeeksOfBenefitPayment = 'isDenyReceived26WeeksOfBenefitPayment',
  Denial26WeeksOfBenefitStartDate = 'denial26WeeksOfBenefitStartDate',
  Denial26WeeksOfBenefitEndDate = 'denial26WeeksOfBenefitEndDate',
  IsLateFillingAfter90Days = 'isDenyLateFillingAfter90Days',
  LateFillingClaimedFiledOn = 'denialLateFillingClaimedFiledOn',
  DenialLateFillingNoBenefitArePayable = 'denialLateFillingNoBenefitArePayable',
  IsPaymentStart14DaysBeforeFileDate = 'isPaymentStart14DaysBeforeFileDate',
  IsClaimingBenefitUnderWCLaw = 'isDenyClaimingBenefitUnderWCLaw',
  IsNotDisabledAccordingMedicalRecord = 'isDenyNotDisabledAccordingMedicalRecord',
  AbleToPerformRegularWorkOnDate = 'ableToPerformRegularWorkOnDate',
  PaymentOfBenefitIsDeniedAfterDate = 'paymentOfBenefitIsDeniedAfterDate',
  IsMedCertDoesntEstablishUnableToWork = 'isDenyMedCertDoesntEstablishUnableToWork',
  IsNotUnderCareOfPhysician = 'isDenyNotUnderCareOfPhysician',
  NotUnderCarePhysicianStartDate = 'notUnderCarePhysicianStartDate',
  NotUnderCarePhysicianEndDate = 'notUnderCarePhysicianEndDate',
  NoBenefitsArePayable = 'notUnderCarePhysicianNoBenefitArePayable',
  PaymentsWillStart = 'notUnderCarePhysicianPaymentWillStart',
  PaymentsWillStartOn = 'notUnderCarePhysicianPaymentWillStartOn',
  IsBenefitEntitledUnderUnionContract = 'isDenyBenefitEntitledUnderUnionContract',
  IsNotInsuranceCarrierForEmployer = 'isDenyNotInsuranceCarrierForEmployer',
  IsClaimHasBeenForwarded = 'notCarrierForEmplIsClaimForwarded',
  ClaimHasBeenForwardedTo = 'notCarrierForEmplClaimForwardedTo',
  IsClaimIsReturned = 'notCarrierForEmplIsClaimReturned',
  IsOtherReasonsForDenial = 'isDenyOtherReason',
  OtherReasonsForDenial = 'denialOtherReasons',

  // ============= Employer =============
  RawEmployerName = 'rawEmployerName',
  RawEmployerDolNumber = 'rawEmployerDolNumber',
  RawEmployerAddress = 'rawEmployerAddress',
  RawEmployerCity = 'rawEmployerCity',
  RawEmployerState = 'rawEmployerState',
  RawEmployerZipCode = 'rawEmployerZipcode',

  // ============= Insurance Carrier =============
  RawInsuranceCarrierName = 'rawCarrierName',
  RawInsuranceCarrierClaimAdjustingServiceName = 'rawCarrierClaimAdjustingServiceName',
  RawInsuranceCarrierAddress = 'rawCarrierAdjustingAddress',
  RawInsuranceCarrierCity = 'rawCarrierCity',
  RawInsuranceCarrierState = 'rawCarrierState',
  RawInsuranceCarrierZipCode = 'rawCarrierZipcode',
  RawInsuranceCarrierPointOfContactName = 'rawCarrierPointOfContactName',
  RawInsuranceCarrierPhone = 'rawCarrierPhone',
  RawInsuranceCarrierEmail = 'rawCarrierEmail',

  // ============= Authorized Representative =============
  AuthorizedRepresentativeName = 'representativeName',
  AuthorizedRepresentativeTitle = 'representativeTitle',

  // ============= Signature  =============
  SignaturePrintedName = 'signaturePrintedName',
  SignatureEmail = 'signatureEmail',
  SignatureDate = 'signatureDate',

  // ============= Claimant’s Appeal  =============
  ClaimantAppeal = 'claimantAppeal',

  // ============= Claimant’s Signature  =============
  SignatureClaimantLastName = 'signatureClaimantLastName',
  SignatureClaimantFirstName = 'signatureClaimantFirstName',
  SignatureClaimantMiddleInitial = 'signatureClaimantMiddleInitial',
  SignatureClaimantSuffix = 'signatureClaimantSuffix',
  SignatureClaimantReceiveDenialNoticeDate = 'signatureClaimantReceiveDenialNoticeDate',
  SignatureClaimantEmail = 'signatureClaimantEmail',
  SignatureClaimantDate = 'signatureClaimantDate',

  // ============= Form Review  =============
  IsCount = 'isCount',
  ReasonNotCounted = 'reasonNotCounted',
}

export enum TDI46NewAmend {
  New = 'NEW',
  Amend = 'AMEND',
}

export enum TDI46Type {
  Denial = 'DENIAL',
  Appeal = 'APPEAL',
}

export enum ReasonNotCounted {
  Duplicate = 'DUPLICATE',
  Incomplete = 'INCOMPLETE',
  Other = 'OTHER',
}
