export enum WC3RawKey {
  RawHeaderNewAment = 'rawNewAmend',
  RawHeaderCaseNumber = 'rawHeaderCaseNumber',
  RawHeaderReceived = 'rawDateReceived',
  RawHeaderPrintedNameOfCarrier = 'rawSignaturePrintName',
  RawHeaderPhoneNumber = 'rawSignaturePhone',
  RawHeaderEmailAddress = 'rawSignatureEmail',
  RawHeaderSignatureDate = 'rawSignatureDate',
  RawHeaderOnBehalfOf = 'rawOnBehalfOf',

  RawClaimant = 'rawClaimant',
  RawClaimantFirstName = 'rawClaimantFirstName',
  RawClaimantLastName = 'rawClaimantLastName',
  RawClaimantMiddleInitial = 'rawClaimantMiddleInitial',
  RawClaimantSuffix = 'rawClaimantSuffix',
  RawClaimantSSN = 'rawClaimantSSN',
  RawClaimantPassportNumber = 'rawClaimantPassportNumber',
  RawClaimantAddress = 'rawClaimantAddress',
  RawClaimantCity = 'rawClaimantCity',
  RawClaimantState = 'rawClaimantState',
  RawClaimantZipCode = 'rawClaimantZipcode',
  RawClaimantEmailAddress = 'rawClaimantEmail',
  RawClaimantPhoneNumber = 'rawClaimantPhone',
  RawClaimantIdNumber = 'rawClaimantIdNumber',
  RawClaimantDateOfInjury = 'rawClaimantDateOfInjury',

  RawEmployerName = 'rawEmployerName',
  RawEmployerDBAName = 'rawEmployerDbaName',
  RawEmployerDOL = 'rawEmployerDolNumber',
  RawEmployerAddress = 'rawEmployerAddress',
  RawEmployerCity = 'rawEmployerCity',
  RawEmployerState = 'rawEmployerState',
  RawEmployerZipCode = 'rawEmployerZipcode',
  RawEmployerPOC = 'rawEmployerPointOfContact',
  RawEmployerPOCPhoneNumber = 'rawEmployerPhone',
  RawEmployerPOCEmailAddress = 'rawEmployerEmail',

  RawInsuranceCarrier = 'rawCarrierName',
  RawInsuranceCarrierId = 'rawCarrierId',
  RawInsuranceCarrierClaimNumber = 'rawCarrierClaimNumber',
  RawInsuranceCarrierPolicyNumber = 'rawCarrierPolicyNumber',
  RawInsuranceCarrierPeriodFrom = 'rawCarrierPolicyFrom',
  RawInsuranceCarrierPeriodTo = 'rawCarrierPolicyTo',
  RawInsuranceCarrierMedicalDeducible = 'rawCarrierMedicalDeductible',
  RawInsuranceCarrierEmailAddress = 'rawCarrierEmail',
  RawInsuranceCarrierAdjusterName = 'rawCarrierAdjusterName',
  RawInsuranceCarrierPhoneNumber = 'rawCarrierPhone',
  RawInsuranceCarrierAdjusterId = 'rawCarrierAdjusterIdNumber',
  RawInsuranceCarrierAddress = 'rawCarrierAddress',
  RawInsuranceCarrierCity = 'rawCarrierCity',
  RawInsuranceCarrierState = 'rawCarrierState',
  RawInsuranceCarrierZipCode = 'rawCarrierZipcode',
  RawInsuranceCarrierWC3Type = 'rawWc3Type',
  RawInsuranceCarrierWC3TypeValue = 'rawWc3TypeValue',
  RawInsuranceCarrierReturnToWorkDate = 'rawReturnToWorkDate',
  RawWc3TypeAdditionInformation = 'rawWc3TypeAdditionInformation',
  RawInsuranceCarrierWeeklyCompensationRate = 'rawWeeklyCompensationRate',
  RawInsuranceCarrierBenefitAdjustment = 'rawBenefitAdjustment',
  RawInsuranceCarrierReimbursement = 'rawBenefitReimbursement',
  RawInsuranceCarrierCarrierComment = 'rawCarrierComment',

  //====================================TTD
  RawTTDPeriod1Days = 'rawBenefitPaymentTtd1Days',
  RawTTDPeriod1StartDate = 'rawBenefitPaymentTtd1DateFrom',
  RawTTDPeriod1EndDate = 'rawBenefitPaymentTtd1DateTo',
  RawTTDPeriod1Payment = 'rawBenefitPaymentTtd1PriorPayment',
  RawTTDPeriod1PaymentNotPreviouslyReported = 'rawBenefitPaymentTtd1PaymentNotPrevReport',
  RawTTDPeriod1TotalPaymentMadeToDate = 'rawBenefitPaymentTtd1TotalPaymentToDate',

  RawTTDPeriod2Days = 'rawBenefitPaymentTtd2Days',
  RawTTDPeriod2StartDate = 'rawBenefitPaymentTtd2DateFrom',
  RawTTDPeriod2EndDate = 'rawBenefitPaymentTtd2DateTo',
  RawTTDPeriod2Payment = 'rawBenefitPaymentTtd2PriorPayment',
  RawTTDPeriod2PaymentNotPreviouslyReported = 'rawBenefitPaymentTtd2PaymentNotPrevReport',
  RawTTDPeriod2TotalPaymentMadeToDate = 'rawBenefitPaymentTtd2TotalPaymentToDate',

  RawTTDPeriod3Days = 'rawBenefitPaymentTtd3Days',
  RawTTDPeriod3DateStartDate = 'rawBenefitPaymentTtd3DateFrom',
  RawTTDPeriod3DateEndDate = 'rawBenefitPaymentTtd3DateTo',
  RawTTDPeriod3Payment = 'rawBenefitPaymentTtd3PriorPayment',
  RawTTDPeriod3PaymentNotPreviouslyReported = 'rawBenefitPaymentTtd3PaymentNotPrevReport',
  RawTTDPeriod3TotalPaymentMadeToDate = 'rawBenefitPaymentTtd3TotalPaymentToDate',

  RawTTDPeriod4Days = 'rawBenefitPaymentTtd4Days',
  RawTTDPeriod4DateStartDate = 'rawBenefitPaymentTtd4DateFrom',
  RawTTDPeriod4DateEndDate = 'rawBenefitPaymentTtd4DateTo',
  RawTTDPeriod4Payment = 'rawBenefitPaymentTtd4PriorPayment',
  RawTTDPeriod4PaymentNotPreviouslyReported = 'rawBenefitPaymentTtd4PaymentNotPrevReport',
  RawTTDPeriod4TotalPaymentMadeToDate = 'rawBenefitPaymentTtd4TotalPaymentToDate',

  RawTTDPeriod5Days = 'rawBenefitPaymentTtd5Days',
  RawTTDPeriod5DateStartDate = 'rawBenefitPaymentTtd5DateFrom',
  RawTTDPeriod5DateEndDate = 'rawBenefitPaymentTtd5DateTo',
  RawTTDPeriod5Payment = 'rawBenefitPaymentTtd5PriorPayment',
  RawTTDPeriod5PaymentNotPreviouslyReported = 'rawBenefitPaymentTtd5PaymentNotPrevReport',
  RawTTDPeriod5TotalPaymentMadeToDate = 'rawBenefitPaymentTtd5TotalPaymentToDate',

  RawTTDPeriod6Days = 'rawBenefitPaymentTtd6Days',
  RawTTDPeriod6DateStartDate = 'rawBenefitPaymentTtd6DateFrom',
  RawTTDPeriod6DateEndDate = 'rawBenefitPaymentTtd6DateTo',
  RawTTDPeriod6Payment = 'rawBenefitPaymentTtd6PriorPayment',
  RawTTDPeriod6PaymentNotPreviouslyReported = 'rawBenefitPaymentTtd6PaymentNotPrevReport',
  RawTTDPeriod6TotalPaymentMadeToDate = 'rawBenefitPaymentTtd6TotalPaymentToDate',

  RawTTDPeriod7Days = 'rawBenefitPaymentTtd7Days',
  RawTTDPeriod7DateStartDate = 'rawBenefitPaymentTtd7DateFrom',
  RawTTDPeriod7DateEndDate = 'rawBenefitPaymentTtd7DateTo',
  RawTTDPeriod7Payment = 'rawBenefitPaymentTtd7PriorPayment',
  RawTTDPeriod7PaymentNotPreviouslyReported = 'rawBenefitPaymentTtd7PaymentNotPrevReport',
  RawTTDPeriod7TotalPaymentMadeToDate = 'rawBenefitPaymentTtd7TotalPaymentToDate',

  RawTTDPeriod8Days = 'rawBenefitPaymentTtd8Days',
  RawTTDPeriod8DateStartDate = 'rawBenefitPaymentTtd8DateFrom',
  RawTTDPeriod8DateEndDate = 'rawBenefitPaymentTtd8DateTo',
  RawTTDPeriod8Payment = 'rawBenefitPaymentTtd8PriorPayment',
  RawTTDPeriod8PaymentNotPreviouslyReported = 'rawBenefitPaymentTtd8PaymentNotPrevReport',
  RawTTDPeriod8TotalPaymentMadeToDate = 'rawBenefitPaymentTtd8TotalPaymentToDate',

  RawTTDPeriod9Days = 'rawBenefitPaymentTtd9Days',
  RawTTDPeriod9DateStartDate = 'rawBenefitPaymentTtd9DateFrom',
  RawTTDPeriod9DateEndDate = 'rawBenefitPaymentTtd9DateTo',
  RawTTDPeriod9Payment = 'rawBenefitPaymentTtd9PriorPayment',
  RawTTDPeriod9PaymentNotPreviouslyReported = 'rawBenefitPaymentTtd9PaymentNotPrevReport',
  RawTTDPeriod9TotalPaymentMadeToDate = 'rawBenefitPaymentTtd9TotalPaymentToDate',

  RawTTDPeriod10Days = 'rawBenefitPaymentTtd10Days',
  RawTTDPeriod10DateStartDate = 'rawBenefitPaymentTtd10DateFrom',
  RawTTDPeriod10DateEndDate = 'rawBenefitPaymentTtd10DateTo',
  RawTTDPeriod10Payment = 'rawBenefitPaymentTtd10PriorPayment',
  RawTTDPeriod10PaymentNotPreviouslyReported = 'rawBenefitPaymentTtd10PaymentNotPrevReport',
  RawTTDPeriod10TotalPaymentMadeToDate = 'rawBenefitPaymentTtd10TotalPaymentToDate',

  RawTTDPeriod11Days = 'rawBenefitPaymentTtd11Days',
  RawTTDPeriod11DateStartDate = 'rawBenefitPaymentTtd11DateFrom',
  RawTTDPeriod11DateEndDate = 'rawBenefitPaymentTtd11DateTo',
  RawTTDPeriod11Payment = 'rawBenefitPaymentTtd11PriorPayment',
  RawTTDPeriod11PaymentNotPreviouslyReported = 'rawBenefitPaymentTtd11PaymentNotPrevReport',
  RawTTDPeriod11TotalPaymentMadeToDate = 'rawBenefitPaymentTtd11TotalPaymentToDate',

  RawTTDPeriod12Days = 'rawBenefitPaymentTtd12Days',
  RawTTDPeriod12DateStartDate = 'rawBenefitPaymentTtd12DateFrom',
  RawTTDPeriod12DateEndDate = 'rawBenefitPaymentTtd12DateTo',
  RawTTDPeriod12Payment = 'rawBenefitPaymentTtd12PriorPayment',
  RawTTDPeriod12PaymentNotPreviouslyReported = 'rawBenefitPaymentTtd12PaymentNotPrevReport',
  RawTTDPeriod12TotalPaymentMadeToDate = 'rawBenefitPaymentTtd12TotalPaymentToDate',

  RawTotalTTDDays = 'rawBenefitPaymentTtdTotalDays',
  RawTotalTTDPriorPayment = 'rawBenefitPaymentTtdTotalPriorPayment',
  RawTotalTTDPaymentNotPreviouslyReported = 'rawBenefitPaymentTtdTotalPaymentNotPrevReport',
  RawTotalTTDTotalPaymentMadeToDate = 'rawBenefitPaymentTtdTotalTotalPaymentToDate',

  //====================================TPD
  RawTPDPeriod1Days = 'rawBenefitPaymentTpd1Days',
  RawTPDPeriod1StartDate = 'rawBenefitPaymentTpd1DateFrom',
  RawTPDPeriod1EndDate = 'rawBenefitPaymentTpd1DateTo',
  RawTPDPeriod1Payment = 'rawBenefitPaymentTpd1PriorPayment',
  RawTPDPeriod1PaymentNotPreviouslyReported = 'rawBenefitPaymentTpd1PaymentNotPrevReport',
  RawTPDPeriod1TotalPaymentMadeToDate = 'rawBenefitPaymentTpd1TotalPaymentToDate',

  RawTPDPeriod2Days = 'rawBenefitPaymentTpd2Days',
  RawTPDPeriod2StartDate = 'rawBenefitPaymentTpd2DateFrom',
  RawTPDPeriod2EndDate = 'rawBenefitPaymentTpd2DateTo',
  RawTPDPeriod2Payment = 'rawBenefitPaymentTpd2PriorPayment',
  RawTPDPeriod2PaymentNotPreviouslyReported = 'rawBenefitPaymentTpd2PaymentNotPrevReport',
  RawTPDPeriod2TotalPaymentMadeToDate = 'rawBenefitPaymentTpd2TotalPaymentToDate',

  RawTPDPeriod3Days = 'rawBenefitPaymentTpd3Days',
  RawTPDPeriod3StartDate = 'rawBenefitPaymentTpd3DateFrom',
  RawTPDPeriod3EndDate = 'rawBenefitPaymentTpd3DateTo',
  RawTPDPeriod3Payment = 'rawBenefitPaymentTpd3PriorPayment',
  RawTPDPeriod3PaymentNotPreviouslyReported = 'rawBenefitPaymentTpd3PaymentNotPrevReport',
  RawTPDPeriod3TotalPaymentMadeToDate = 'rawBenefitPaymentTpd3TotalPaymentToDate',

  RawTPDPeriod4Days = 'rawBenefitPaymentTpd4Days',
  RawTPDPeriod4StartDate = 'rawBenefitPaymentTpd4DateFrom',
  RawTPDPeriod4EndDate = 'rawBenefitPaymentTpd4DateTo',
  RawTPDPeriod4Payment = 'rawBenefitPaymentTpd4PriorPayment',
  RawTPDPeriod4PaymentNotPreviouslyReported = 'rawBenefitPaymentTpd4PaymentNotPrevReport',
  RawTPDPeriod4TotalPaymentMadeToDate = 'rawBenefitPaymentTpd4TotalPaymentToDate',

  RawTPDPeriod5Days = 'rawBenefitPaymentTpd5Days',
  RawTPDPeriod5StartDate = 'rawBenefitPaymentTpd5DateFrom',
  RawTPDPeriod5EndDate = 'rawBenefitPaymentTpd5DateTo',
  RawTPDPeriod5Payment = 'rawBenefitPaymentTpd5PriorPayment',
  RawTPDPeriod5PaymentNotPreviouslyReported = 'rawBenefitPaymentTpd5PaymentNotPrevReport',
  RawTPDPeriod5TotalPaymentMadeToDate = 'rawBenefitPaymentTpd5TotalPaymentToDate',

  RawTotalTPDDays = 'rawBenefitPaymentTpdTotalDays',
  RawTotalTPDPriorPayment = 'rawBenefitPaymentTpdTotalPriorPayment',
  RawTotalTPDPaymentNotPreviouslyReported = 'rawBenefitPaymentTpdTotalPaymentNotPrevReport',
  RawTotalTPDTotalPaymentMadeToDate = 'rawBenefitPaymentTpdTotalTotalPaymentToDate',

  //====================================PTD
  RawPTDPeriod1Days = 'rawBenefitPaymentPtd1Days',
  RawPTDPeriod1StartDate = 'rawBenefitPaymentPtd1DateFrom',
  RawPTDPeriod1EndDate = 'rawBenefitPaymentPtd1DateTo',
  RawPTDPeriod1Payment = 'rawBenefitPaymentPtd1PriorPayment',
  RawPTDPeriod1PaymentNotPreviouslyReported = 'rawBenefitPaymentPtd1PaymentNotPrevReport',
  RawPTDPeriod1TotalPaymentMadeToDate = 'rawBenefitPaymentPtd1TotalPaymentToDate',

  RawPTDPeriod2Days = 'rawBenefitPaymentPtd2Days',
  RawPTDPeriod2StartDate = 'rawBenefitPaymentPtd2DateFrom',
  RawPTDPeriod2EndDate = 'rawBenefitPaymentPtd2DateTo',
  RawPTDPeriod2Payment = 'rawBenefitPaymentPtd2PriorPayment',
  RawPTDPeriod2PaymentNotPreviouslyReported = 'rawBenefitPaymentPtd2PaymentNotPrevReport',
  RawPTDPeriod2TotalPaymentMadeToDate = 'rawBenefitPaymentPtd2TotalPaymentToDate',

  RawPTDPeriod3Days = 'rawBenefitPaymentPtd3Days',
  RawPTDPeriod3StartDate = 'rawBenefitPaymentPtd3DateFrom',
  RawPTDPeriod3EndDate = 'rawBenefitPaymentPtd3DateTo',
  RawPTDPeriod3Payment = 'rawBenefitPaymentPtd3PriorPayment',
  RawPTDPeriod3PaymentNotPreviouslyReported = 'rawBenefitPaymentPtd3PaymentNotPrevReport',
  RawPTDPeriod3TotalPaymentMadeToDate = 'rawBenefitPaymentPtd3TotalPaymentToDate',

  RawPTDPeriod4Days = 'rawBenefitPaymentPtd4Days',
  RawPTDPeriod4StartDate = 'rawBenefitPaymentPtd4DateFrom',
  RawPTDPeriod4EndDate = 'rawBenefitPaymentPtd4DateTo',
  RawPTDPeriod4Payment = 'rawBenefitPaymentPtd4PriorPayment',
  RawPTDPeriod4PaymentNotPreviouslyReported = 'rawBenefitPaymentPtd4PaymentNotPrevReport',
  RawPTDPeriod4TotalPaymentMadeToDate = 'rawBenefitPaymentPtd4TotalPaymentToDate',

  RawPTDPeriod5Days = 'rawBenefitPaymentPtd5Days',
  RawPTDPeriod5StartDate = 'rawBenefitPaymentPtd5DateFrom',
  RawPTDPeriod5EndDate = 'rawBenefitPaymentPtd5DateTo',
  RawPTDPeriod5Payment = 'rawBenefitPaymentPtd5PriorPayment',
  RawPTDPeriod5PaymentNotPreviouslyReported = 'rawBenefitPaymentPtd5PaymentNotPrevReport',
  RawPTDPeriod5TotalPaymentMadeToDate = 'rawBenefitPaymentPtd5TotalPaymentToDate',

  RawTotalPTDDays = 'rawBenefitTotalPTDTotalDays',
  RawTotalPTDPriorPayment = 'rawBenefitTotalPTDTotalPriorPayment',
  RawTotalPTDPaymentNotPreviouslyReported = 'rawBenefitTotalPTDTotalPaymentNotPrevReport',
  RawTotalPTDTotalPaymentMadeToDate = 'rawBenefitTotalPTDTotalTotalPaymentToDate',
  RawTotalPTDPriorPaymentNotPreviouslyReported = 'rawBenefitTotalPTDTotalPriorPaymentNotPrevReport',

  //====================================PPD
  RawPPDPeriod1Days = 'rawBenefitPaymentPpd1Days',
  RawPPDPeriod1StartDate = 'rawBenefitPaymentPpd1DateFrom',
  RawPPDPeriod1EndDate = 'rawBenefitPaymentPpd1DateTo',
  RawPPDPeriod1Payment = 'rawBenefitPaymentPpd1PriorPayment',
  RawPPDPeriod1PaymentNotPreviouslyReported = 'rawBenefitPaymentPpd1PaymentNotPrevReport',
  RawPPDPeriod1TotalPaymentMadeToDate = 'rawBenefitPaymentPpd1TotalPaymentToDate',

  RawPPDPeriod2Days = 'rawBenefitPaymentPpd2Days',
  RawPPDPeriod2StartDate = 'rawBenefitPaymentPpd2DateFrom',
  RawPPDPeriod2EndDate = 'rawBenefitPaymentPpd2DateTo',
  RawPPDPeriod2Payment = 'rawBenefitPaymentPpd2PriorPayment',
  RawPPDPeriod2PaymentNotPreviouslyReported = 'rawBenefitPaymentPpd2PaymentNotPrevReport',
  RawPPDPeriod2TotalPaymentMadeToDate = 'rawBenefitPaymentPpd2TotalPaymentToDate',

  RawTotalPPDDays = 'rawBenefitPaymentPpdTotalDays',
  RawTotalPPDPriorPayment = 'rawBenefitPaymentPpdTotalPriorPayment',
  RawTotalPPDPaymentNotPreviouslyReported = 'rawBenefitPaymentPpdTotalPaymentNotPrevReport',
  RawTotalPPDTotalPaymentMadeToDate = 'rawBenefitPaymentPpdTotalTotalPaymentToDate',

  RawDeathSpouseDays = 'rawBenefitPaymentDeathSpouseDays',
  RawDeathSpouseStartDate = 'rawBenefitPaymentDeathSpouseDateFrom',
  RawDeathSpouseEndDate = 'rawBenefitPaymentDeathSpouseDateTo',
  RawDeathSpousePayment = 'rawBenefitPaymentDeathSpousePriorPayment',
  RawDeathSpousePaymentNotPreviouslyReported = 'rawBenefitPaymentDeathSpousePaymentNotPrevReport',
  RawDeathSpouseTotalPaymentMadeToDate = 'rawBenefitPaymentDeathSpouseTotalPaymentToDate',

  //====================================Death
  RawDeathDependent1Days = 'rawBenefitPaymentDeathDep1Days',
  RawDeathDependent1StartDate = 'rawBenefitPaymentDeathDep1DateFrom',
  RawDeathDependent1EndDate = 'rawBenefitPaymentDeathDep1DateTo',
  RawDeathDependent1Payment = 'rawBenefitPaymentDeathDep1PriorPayment',
  RawDeathDependent1PaymentNotPreviouslyReported = 'rawBenefitPaymentDeathDep1PaymentNotPrevReport',
  RawDeathDependent1TotalPaymentMadeToDate = 'rawBenefitPaymentDeathDep1TotalPaymentToDate',

  RawDeathDependent2Days = 'rawBenefitPaymentDeathDep2Days',
  RawDeathDependent2StartDate = 'rawBenefitPaymentDeathDep2DateFrom',
  RawDeathDependent2EndDate = 'rawBenefitPaymentDeathDep2DateTo',
  RawDeathDependent2Payment = 'rawBenefitPaymentDeathDep2PriorPayment',
  RawDeathDependent2PaymentNotPreviouslyReported = 'rawBenefitPaymentDeathDep2PaymentNotPrevReport',
  RawDeathDependent2TotalPaymentMadeToDate = 'rawBenefitPaymentDeathDep2TotalPaymentToDate',

  RawDeathDependent3Days = 'rawBenefitPaymentDeathDep3Days',
  RawDeathDependent3StartDate = 'rawBenefitPaymentDeathDep3DateFrom',
  RawDeathDependent3EndDate = 'rawBenefitPaymentDeathDep3DateTo',
  RawDeathDependent3Payment = 'rawBenefitPaymentDeathDep3PriorPayment',
  RawDeathDependent3PaymentNotPreviouslyReported = 'rawBenefitPaymentDeathDep3PaymentNotPrevReport',
  RawDeathDependent3TotalPaymentMadeToDate = 'rawBenefitPaymentDeathDep3TotalPaymentToDate',

  RawDeathDependent4Days = 'rawBenefitPaymentDeathDep4Days',
  RawDeathDependent4StartDate = 'rawBenefitPaymentDeathDep4DateFrom',
  RawDeathDependent4EndDate = 'rawBenefitPaymentDeathDep4DateTo',
  RawDeathDependent4Payment = 'rawBenefitPaymentDeathDep4PriorPayment',
  RawDeathDependent4PaymentNotPreviouslyReported = 'rawBenefitPaymentDeathDep4PaymentNotPrevReport',
  RawDeathDependent4TotalPaymentMadeToDate = 'rawBenefitPaymentDeathDep4TotalPaymentToDate',

  RawDeathDependent5Days = 'rawBenefitPaymentDeathDep5Days',
  RawDeathDependent5StartDate = 'rawBenefitPaymentDeathDep5DateFrom',
  RawDeathDependent5EndDate = 'rawBenefitPaymentDeathDep5DateTo',
  RawDeathDependent5Payment = 'rawBenefitPaymentDeathDep5PriorPayment',
  RawDeathDependent5PaymentNotPreviouslyReported = 'rawBenefitPaymentDeathDep5PaymentNotPrevReport',
  RawDeathDependent5TotalPaymentMadeToDate = 'rawBenefitPaymentDeathDep5TotalPaymentToDate',

  RawDeathDependent6Days = 'rawBenefitPaymentDeathDep6Days',
  RawDeathDependent6StartDate = 'rawBenefitPaymentDeathDep6DateFrom',
  RawDeathDependent6EndDate = 'rawBenefitPaymentDeathDep6DateTo',
  RawDeathDependent6Payment = 'rawBenefitPaymentDeathDep6PriorPayment',
  RawDeathDependent6PaymentNotPreviouslyReported = 'rawBenefitPaymentDeathDep6PaymentNotPrevReport',
  RawDeathDependent6TotalPaymentMadeToDate = 'rawBenefitPaymentDeathDep6TotalPaymentToDate',

  RawDeathDependent7Days = 'rawBenefitPaymentDeathDep7Days',
  RawDeathDependent7StartDate = 'rawBenefitPaymentDeathDep7DateFrom',
  RawDeathDependent7EndDate = 'rawBenefitPaymentDeathDep7DateTo',
  RawDeathDependent7Payment = 'rawBenefitPaymentDeathDep7PriorPayment',
  RawDeathDependent7PaymentNotPreviouslyReported = 'rawBenefitPaymentDeathDep7PaymentNotPrevReport',
  RawDeathDependent7TotalPaymentMadeToDate = 'rawBenefitPaymentDeathDep7TotalPaymentToDate',

  RawDeathBurialPayment = 'rawBenefitPaymentDeathBurialPriorPayment',
  RawDeathBurialPaymentNotPreviouslyReported = 'rawBenefitPaymentDeathBurialPaymentNotPrevReport',
  RawDeathBurialTotalPaymentMadeToDate = 'rawBenefitPaymentDeathBurialTotalPaymentToDate',

  RawDeathFuneralPayment = 'rawBenefitPaymentDeathFuneralPriorPayment',
  RawDeathFuneralPaymentNotPreviouslyReported = 'rawBenefitPaymentDeathFuneralPaymentNotPrevReport',
  RawDeathFuneralTotalPaymentMadeToDate = 'rawBenefitPaymentDeathFuneralTotalPaymentToDate',

  RawDeathOther1Days = 'rawBenefitPaymentDeathOther1Days',
  RawDeathOther1StartDate = 'rawBenefitPaymentDeathOther1DateFrom',
  RawDeathOther1EndDate = 'rawBenefitPaymentDeathOther1DateTo',
  RawDeathOther1Payment = 'rawBenefitPaymentDeathOther1PriorPayment',
  RawDeathOther1PaymentNotPreviouslyReported = 'rawBenefitPaymentDeathOther1PaymentNotPrevReport',
  RawDeathOther1TotalPaymentMadeToDate = 'rawBenefitPaymentDeathOther1TotalPaymentToDate',

  RawDeathOther2Days = 'rawBenefitPaymentDeathOther2Days',
  RawDeathOther2StartDate = 'rawBenefitPaymentDeathOther2DateFrom',
  RawDeathOther2EndDate = 'rawBenefitPaymentDeathOther2DateTo',
  RawDeathOther2Payment = 'rawBenefitPaymentDeathOther2PriorPayment',
  RawDeathOther2PaymentNotPreviouslyReported = 'rawBenefitPaymentDeathOther2PaymentNotPrevReport',
  RawDeathOther2TotalPaymentMadeToDate = 'rawBenefitPaymentDeathOther2TotalPaymentToDate',

  RawTotalDeathDays = 'rawBenefitPaymentDeathTotalDays',
  RawTotalDeathPriorPayment = 'rawBenefitPaymentDeathTotalPriorPayment',
  RawTotalDeathPaymentNotPreviouslyReported = 'rawBenefitPaymentDeathTotalPaymentNotPrevReport',
  RawTotalDeathPaymentMadeToDate = 'rawBenefitPaymentDeathTotalTotalPaymentToDate',

  RawBPSummaryTTDDays = 'rawBpSummaryTtdDays',
  RawBpSummaryTTDDateFrom = 'rawBpSummaryTtdDateFrom',
  RawBpSummaryTTDDateTo = 'rawBpSummaryTtdDateTo',
  RawBpSummaryTTDPaymentNotPrevReport = 'rawBpSummaryTtdPaymentNotPrevReport',
  RawBpSummaryTTDPriorPayment = 'rawBpSummaryTtdPriorPayment',
  RawBpSummaryTTDTotalPaymentToDate = 'rawBpSummaryTtdTotalPaymentToDate',

  RawBPSummaryTPDDays = 'rawBpSummaryTpdDays',
  RawBpSummaryTPDDateFrom = 'rawBpSummaryTpdDateFrom',
  RawBpSummaryTPDDateTo = 'rawBpSummaryTpdDateTo',
  RawBpSummaryTPDPaymentNotPrevReport = 'rawBpSummaryTpdPaymentNotPrevReport',
  RawBpSummaryTPDPriorPayment = 'rawBpSummaryTpdPriorPayment',
  RawBpSummaryTPDTotalPaymentToDate = 'rawBpSummaryTpdTotalPaymentToDate',

  RawBpSummaryPTDDays = 'rawBpSummaryPtdDays',
  RawBpSummaryPTDDateFrom = 'rawBpSummaryPtdDateFrom',
  RawBpSummaryPTDDateTo = 'rawBpSummaryPtdDateTo',
  RawBpSummaryPTDPaymentNotPrevReport = 'rawBpSummaryPtdPaymentNotPrevReport',
  RawBpSummaryPTDPriorPayment = 'rawBpSummaryPtdPriorPayment',
  RawBpSummaryPTDTotalPaymentToDate = 'rawBpSummaryPtdTotalPaymentToDate',

  RawBpSummaryPPDDays = 'rawBpSummaryPpdDays',
  RawBpSummaryPPDDateFrom = 'rawBpSummaryPpdDateFrom',
  RawBpSummaryPPDDateTo = 'rawBpSummaryPpdDateTo',
  RawBpSummaryPPDPaymentNotPrevReport = 'rawBpSummaryPpdPaymentNotPrevReport',
  RawBpSummaryPPDPriorPayment = 'rawBpSummaryPpdPriorPayment',
  RawBpSummaryPPDTotalPaymentToDate = 'rawBpSummaryPpdTotalPaymentToDate',

  RawBpSummaryDeathDays = 'rawBpSummaryDeathDays',
  RawBpSummaryDeathDateFrom = 'rawBpSummaryDeathDateFrom',
  RawBpSummaryDeathDateTo = 'rawBpSummaryDeathDateTo',
  RawBpSummaryDeathPaymentNotPrevReport = 'rawBpSummaryDeathPaymentNotPrevReport',
  RawBpSummaryDeathPriorPayment = 'rawBpSummaryDeathPriorPayment',
  RawBpSummaryDeathTotalPaymentToDate = 'rawBpSummaryDeathTotalPaymentToDate',

  RawBpSummaryCityCountyDays = 'rawBpSummaryCityCountyDays',
  RawBpSummaryCityCountyDateFrom = 'rawBpSummaryCityCountyDateFrom',
  RawBpSummaryCityCountyDateTo = 'rawBpSummaryCityCountyDateTo',
  RawBpSummaryCityCountyPaymentNotPrevReport = 'rawBpSummaryCityCountyPaymentNotPrevReport',
  RawBpSummaryCityCountyPriorPayment = 'rawBpSummaryCityCountyPriorPayment',
  RawBpSummaryCityCountyTotalPaymentToDate = 'rawBpSummaryCityCountyTotalPaymentToDate',

  RawBpSummaryDisfigurementDateFrom = 'rawBpSummaryDisfigurementDateFrom',
  RawBpSummaryDisfigurementDateTo = 'rawBpSummaryDisfigurementDateTo',
  RawBpSummaryDisfigurementPaymentNotPrevReport = 'rawBpSummaryDisfigurementPaymentNotPrevReport',
  RawBpSummaryDisfigurementPriorPayment = 'rawBpSummaryDisfigurementPriorPayment',
  RawBpSummaryDisfigurementTotalPaymentToDate = 'rawBpSummaryDisfigurementTotalPaymentToDate',

  RawBpSummaryMedicalDateFrom = 'rawBpSummaryMedicalDateFrom',
  RawBpSummaryMedicalDateTo = 'rawBpSummaryMedicalDateTo',
  RawBpSummaryMedicalPaymentNotPrevReport = 'rawBpSummaryMedicalPaymentNotPrevReport',
  RawBpSummaryMedicalPriorPayment = 'rawBpSummaryMedicalPriorPayment',
  RawBpSummaryMedicalTotalPaymentToDate = 'rawBpSummaryMedicalTotalPaymentToDate',

  RawBpSummaryMaterialDateFrom = 'rawBpSummaryMaterialDateFrom',
  RawBpSummaryMaterialDateTo = 'rawBpSummaryMaterialDateTo',
  RawBpSummaryMaterialPaymentNotPrevReport = 'rawBpSummaryMaterialPaymentNotPrevReport',
  RawBpSummaryMaterialPriorPayment = 'rawBpSummaryMaterialPriorPayment',
  RawBpSummaryMaterialTotalPaymentToDate = 'rawBpSummaryMaterialTotalPaymentToDate',

  RawBpSummarySvcAttendantDateFrom = 'rawBpSummarySvcAttendantDateFrom',
  RawBpSummarySvcAttendantDateTo = 'rawBpSummarySvcAttendantDateTo',
  RawBpSummarySvcAttendantPaymentNotPrevReport = 'rawBpSummarySvcAttendantPaymentNotPrevReport',
  RawBpSummarySvcAttendantPriorPayment = 'rawBpSummarySvcAttendantPriorPayment',
  RawBpSummarySvcAttendantTotalPaymentToDate = 'rawBpSummarySvcAttendantTotalPaymentToDate',

  RawBpSummaryRehabDateFrom = 'rawBpSummaryRehabDateFrom',
  RawBpSummaryRehabDateTo = 'rawBpSummaryRehabDateTo',
  RawBpSummaryRehabPaymentNotPrevReport = 'rawBpSummaryRehabPaymentNotPrevReport',
  RawBpSummaryRehabPriorPayment = 'rawBpSummaryRehabPriorPayment',
  RawBpSummaryRehabTotalPaymentToDate = 'rawBpSummaryRehabTotalPaymentToDate',

  RawBpSummaryReimbursementDays = 'rawBpSummaryReimbursementDays',
  RawBpSummaryReimbursementDateFrom = 'rawBpSummaryReimbursementDateFrom',
  RawBpSummaryReimbursementDateTo = 'rawBpSummaryReimbursementDateTo',
  RawBpSummaryReimbursementPaymentNotPrevReport = 'rawBpSummaryReimbursementPaymentNotPrevReport',
  RawBpSummaryReimbursementPriorPayment = 'rawBpSummaryReimbursementPriorPayment',
  RawBpSummaryReimbursementTotalPaymentToDate = 'RawBpSummaryReimbursementTotalPaymentToDate',

  RawBpSummaryAdjustmentDays = 'rawBpSummaryAdjustmentDays',
  RawBpSummaryAdjustmentDateFrom = 'rawBpSummaryAdjustmentDateFrom',
  RawBpSummaryAdjustmentDateTo = 'rawBpSummaryAdjustmentDateTo',
  RawBpSummaryAdjustmentPaymentNotPrevReport = 'rawBpSummaryAdjustmentPaymentNotPrevReport',
  RawBpSummaryAdjustmentPriorPayment = 'rawBpSummaryAdjustmentPriorPayment',
  RawBpSummaryAdjustmentTotalPaymentToDate = 'rawBpSummaryAdjustmentTotalPaymentToDate',

  RawBpSummaryOther1Days = 'rawBpSummaryOther1Days',
  RawBpSummaryOther1DateFrom = 'rawBpSummaryOther1DateFrom',
  RawBpSummaryOther1DateTo = 'rawBpSummaryOther1DateTo',
  RawBpSummaryOther1PaymentNotPrevReport = 'rawBpSummaryOther1PaymentNotPrevReport',
  RawBpSummaryOther1PriorPayment = 'rawBpSummaryOther1PriorPayment',
  RawBpSummaryOther1TotalPaymentToDate = 'rawBpSummaryOther1TotalPaymentToDate',

  RawBpSummaryOther2Days = 'rawBpSummaryOther2Days',
  RawBpSummaryOther2DateFrom = 'rawBpSummaryOther2DateFrom',
  RawBpSummaryOther2DateTo = 'rawBpSummaryOther2DateTo',
  RawBpSummaryOther2PaymentNotPrevReport = 'rawBpSummaryOther2PaymentNotPrevReport',
  RawBpSummaryOther2PriorPayment = 'rawBpSummaryOther2PriorPayment',
  RawBpSummaryOther2TotalPaymentToDate = 'rawBpSummaryOther2TotalPaymentToDate',

  RawBpSummaryGrandTotalDays = 'rawBpSummaryGrandTotalDays',
  RawBpSummaryGrandTotalPaymentNotPrevReport = 'rawBpSummaryGrandTotalPaymentNotPrevReport',
  RawBpSummaryGrandTotalPriorPayment = 'rawBpSummaryGrandTotalPriorPayment',
  RawBpSummaryGrandTotalTotalPaymentToDate = 'rawBpSummaryGrandTotalTotalPaymentToDate',
}
