export enum TrackingLogKey {
  Id = 'id',
  TrackingLogNumber = 'logNumber',
  CreatedDate = 'createdAt',
  Type = 'type',
  Description = 'description',
  Comments = 'comment',
  UserProfile = 'userProfile',

  // ==================== Id =====================
  TrackingLogId = 'trackingLogId',
  AccountId = 'accountId',
  InsurancePolicyId = 'insurancePolicyId',
  TDIPlanId = 'tdiPlanId',
  HCPlanId = 'hcPlanId',
  ComplaintId = 'complaintId',
  DisburmentId = 'disburmentId',
  TDI30Id = 'tdi30Id',
  ReceiptId = 'receiptId',
  EmployerAuditId = 'employerAuditId',

  //===================== Audit Information =====================
  UpdatedUser = 'updatedUser',
  CreatedUser = 'createdUser',
}
