export enum EmployerAuditKey {
  Id = 'id',
  EmployerAuditNumber = 'employerAuditNumber',
  AuditType = 'auditType',
  EmployerID = 'employerId',
  Employer = 'employer',
  EmployerName = 'employerName',
  NatureOfAudit = 'natureOfAudit',
  AuditPeriodStartDate = 'auditPeriodStartDate',
  AuditPeriodEndDate = 'auditPeriodEndDate',
  ParentCompanyId = 'parentCompanyId',
  ParentCompany = 'parentCompany',
  ParentCompanyName = 'parentCompanyName',
  Findings = 'findings',
  Auditor = 'auditor',
  AuditorId = 'auditorId',
  AuditorName = 'auditorName',
  Status = 'status',
  AssignedDate = 'assignedDate',
  DateCompleted = 'completedDate',

  DateReviewedByAdmin = 'dateReviewedByAdmin',
  TaxYear = 'taxYear',
  QuarterCompleted = 'quarterCompleted',
  AuditedAmountPremiumSupplementationClaimed = 'auditedAmountPremiumSupClaimed',
  AuditedAmountPremiumSupplementationRounded = 'auditedAmountPremiumSupRounded',

  DateSentToAdmin = 'dateSentToAdmin',
  ForYearEnded = 'forYearEnded',
  DOLNumber = 'dolNumber',
  Address = 'address',
  City = 'city',
  State = 'state',
  Zip = 'zip',
  PhoneNumber = 'phoneNumber',
  KindOfBusiness = 'kindOfBusiness',
  TotalNumberOfEmployees = 'totalNumberOfEmployees',
  TotalHawaiiEmployees = 'totalNumberOfHawaiiEmployees',
  TotalEligibleHawaiiEmployees = 'totalEligibleHawaiiEE',
  TotalCoveredHawaiiEmployees = 'totalCoveredHawaiiEE',

  // list view
  CityListView = 'employer.city',
  StateListView = 'employer.state',
  ZipListView = 'employer.zip',
  PhoneNumberListView = 'employer.phone',
  KindOfBusinessListView = 'employer.orgType',
  TotalHawaiiEmployeesListView = 'employer.numOfHawaiiEmployees',
  TotalNumberOfEmployeesListView = 'employer.numOfEmployees',
  DOLNumberListView = 'employer.laborNumber',

  //Audit
  CreatedByUser = 'createdByUser',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  UpdatedUser = 'updatedUser',
  UpdatedAt = 'updatedAt',
  LastModifiedDate = 'lastModifiedDate',
  LastModifiedByUser = 'lastModifiedByUser',
  CreatedUser = 'createdUser',
}
