export enum TDIPlanKey {
  Id = 'id',
  PlanType = 'planType',
  PlanNameNumber = 'planName',
  TDIPlanNumber = 'tdiPlanNumber',
  InsuranceCarrierSchema = 'insuranceCarrier.accountName',
  InsuranceCarrier = 'insuranceCarrier',
  InsuranceCarrierCarrierId = 'InsuranceCarrierCarrierId',
  InsuranceCarrierIdSchema = 'insuranceCarrierId',
  CarrierIDSchema = 'insuranceCarrier.carrierId',
  InsuranceCarrierStatusSchema = 'insuranceCarrier.dcdStatus',
  InsuranceCarrierStatusDateSchema = 'insuranceCarrier.dcdStatusDate',
  NameOfUnion = 'nameOfUnion',
  CBAEffectiveDate = 'cbaEffectiveDate',
  CBAExpiredDate = 'cbaExpiredDate',
  EmployerAccountNameSchema = 'employer.accountName',
  EmployerDOLNumberSchema = 'employer.laborNumber',
  EmployerDCDStatusSchema = 'employer.dcdStatus',
  EmployerDCDStatusDateSchema = 'employer.dcdStatusDate',
  InsuranceCarrierName = 'insuranceCarrier',
  CarrierID = 'carrierId',
  Employer = 'employer',
  EmployerObject = 'employerObject',
  EmployerName = 'employerName',
  EmployerId = 'employerId',
  EmployerDOLNumber = 'laborNumber',
  EmployerDCDStatusDate = 'employer.dcdStatusDate',
  //SOLVENCY INFORMATION
  NumberOfEmployees = 'numberOfEmployees',
  IsFinanciallySolvent = 'isFinanciallySolvent',
  RequiredDeposit = 'requiredDeposit',
  DepositAmount = 'depositAmount',
  DepositDate = 'depositDate',
  FinancialStatementYearEnding = 'financialStatementYearEnding',
  Comment = 'comment',
  IsBond = 'isBond',
  Description = 'description',
  //PLAN 1 INFORMATION
  Plan1 = 'plan1',
  Plan1NameNumber = 'plan1NameNumber',
  //2-PLAN INFORMATION
  Plan2 = 'plan2',
  Plan2NameNumber = 'plan2NameNumber',

  //TDIPlanTypesHighlightSection
  TDIPlanID = 'tdiPlanId',
  RecordNum = 'recordNum',

  // Audit
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',

  // INSURANCE CARRIER
  InsuranceCarrierId = 'insuranceCarrierId',
  CarrierId = 'carrierId',
  InsuranceCarrierStatus = 'insuranceCarrierStatus',
  InsuranceCarrierStatusDate = 'insuranceCarrierStatusDate',

  // EMPLOYER
  DOLNumber = 'employerDOLNumber',
  DCDStatus = 'dcdStatus',
  DCDStatusDate = 'dcdStatusDate',

  //COVERAGE EQUIVALENCY
  CoverageEquivalency = 'coverageEquivalencies',
  Tenure = 'tenure',
  AccidentCalendarDayBenefitsCommence = 'accidentCalendarDayBenefitsCommence',
  IllnessCalendarDayBenefitsCommence = 'illnessCalendarDayBenefitsCommence',
  InitialWageLossReplaced = 'initialWageLossReplaced',
  InitialDurationOfBenefits = 'initialDurationOfBenefits',
  SubsequentWageLossReplaced = 'subsequentWageLossReplaced',
  SubsequentDurationOfBenefits = 'subsequentDurationOfBenefits',
  EquivalencyValueOfBenefitSchedule = 'equivalencyValueOfBenefitSchedule',

  //Filter keys
  CarrierIdFilter = 'insuranceCarrier.carrierId',
  InsuranceCarrierStatusFilter = 'insuranceCarrier.dcdStatus',
  InsuranceCarrierStatusDateFilter = 'insuranceCarrier.dcdStatusDate',
  EmployerDOLNumberFilter = 'employer.laborNumber',
  EmployerDCDStatusFilter = 'employer.dcdStatus',
  EmployerDCDStatusDateFilter = 'employer.dcdStatusDate',
  DCDStatusFilter = 'employer.dcdStatus',

  //COVERAGE EQUIVALENCY
  TenureMinimumAccidentCalendarDayBenefitsCommence = 'equivalentTenureMinimum.accidentCalendarDayBenefitsCommence',
  TenureMinimumIllnessCalendarDayBenefitsCommence = 'equivalentTenureMinimum.illnessCalendarDayBenefitsCommence',
  TenureMinimumInitialWageLossReplaced = 'equivalentTenureMinimum.initialWageLossReplaced',
  TenureMinimumInitialDurationBenefits = 'equivalentTenureMinimum.initialDurationOfBenefits',
  TenureMinimumSubsequentWageLossReplaced = 'equivalentTenureMinimum.subsequentWageLossReplaced',
  TenureMinimumSubsequentDurationBenefits = 'equivalentTenureMinimum.subsequentDurationOfBenefits',
  TenureMinimumEquivalencyValueOfBenefitSchedule = 'equivalentTenureMinimum.equivalencyValueOfBenefitSchedule',

  Tenure6MonthAccidentCalendarDayBenefitsCommence = 'equivalentTenure6Month.accidentCalendarDayBenefitsCommence',
  Tenure6MonthIllnessCalendarDayBenefitsCommence = 'equivalentTenure6Month.illnessCalendarDayBenefitsCommence',
  Tenure6MonthInitialWageLossReplaced = 'equivalentTenure6Month.initialWageLossReplaced',
  Tenure6MonthInitialDurationBenefits = 'equivalentTenure6Month.initialDurationOfBenefits',
  Tenure6MonthSubsequentWageLossReplaced = 'equivalentTenure6Month.subsequentWageLossReplaced',
  Tenure6MonthSubsequentDurationBenefits = 'equivalentTenure6Month.subsequentDurationOfBenefits',
  Tenure6MonthEquivalencyValueOfBenefitSchedule = 'equivalentTenure6Month.equivalencyValueOfBenefitSchedule',

  Tenure12MonthAccidentCalendarDayBenefitsCommence = 'equivalentTenure12Month.accidentCalendarDayBenefitsCommence',
  Tenure12MonthIllnessCalendarDayBenefitsCommence = 'equivalentTenure12Month.illnessCalendarDayBenefitsCommence',
  Tenure12MonthInitialWageLossReplaced = 'equivalentTenure12Month.initialWageLossReplaced',
  Tenure12MonthInitialDurationBenefits = 'equivalentTenure12Month.initialDurationOfBenefits',
  Tenure12MonthSubsequentWageLossReplaced = 'equivalentTenure12Month.subsequentWageLossReplaced',
  Tenure12MonthSubsequentDurationBenefits = 'equivalentTenure12Month.subsequentDurationOfBenefits',
  Tenure12MonthEquivalencyValueOfBenefitSchedule = 'equivalentTenure12Month.equivalencyValueOfBenefitSchedule',

  Tenure2YearAccidentCalendarDayBenefitsCommence = 'equivalentTenure2Year.accidentCalendarDayBenefitsCommence',
  Tenure2YearIllnessCalendarDayBenefitsCommence = 'equivalentTenure2Year.illnessCalendarDayBenefitsCommence',
  Tenure2YearInitialWageLossReplaced = 'equivalentTenure2Year.initialWageLossReplaced',
  Tenure2YearInitialDurationBenefits = 'equivalentTenure2Year.initialDurationOfBenefits',
  Tenure2YearSubsequentWageLossReplaced = 'equivalentTenure2Year.subsequentWageLossReplaced',
  Tenure2YearSubsequentDurationBenefits = 'equivalentTenure2Year.subsequentDurationOfBenefits',
  Tenure2YearEquivalencyValueOfBenefitSchedule = 'equivalentTenure2Year.equivalencyValueOfBenefitSchedule',

  Tenure3YearAccidentCalendarDayBenefitsCommence = 'equivalentTenure3Year.accidentCalendarDayBenefitsCommence',
  Tenure3YearIllnessCalendarDayBenefitsCommence = 'equivalentTenure3Year.illnessCalendarDayBenefitsCommence',
  Tenure3YearInitialWageLossReplaced = 'equivalentTenure3Year.initialWageLossReplaced',
  Tenure3YearInitialDurationBenefits = 'equivalentTenure3Year.initialDurationOfBenefits',
  Tenure3YearSubsequentWageLossReplaced = 'equivalentTenure3Year.subsequentWageLossReplaced',
  Tenure3YearSubsequentDurationBenefits = 'equivalentTenure3Year.subsequentDurationOfBenefits',
  Tenure3YearEquivalencyValueOfBenefitSchedule = 'equivalentTenure3Year.equivalencyValueOfBenefitSchedule',

  Tenure5YearAccidentCalendarDayBenefitsCommence = 'equivalentTenure5Year.accidentCalendarDayBenefitsCommence',
  Tenure5YearIllnessCalendarDayBenefitsCommence = 'equivalentTenure5Year.illnessCalendarDayBenefitsCommence',
  Tenure5YearInitialWageLossReplaced = 'equivalentTenure5Year.initialWageLossReplaced',
  Tenure5YearInitialDurationBenefits = 'equivalentTenure5Year.initialDurationOfBenefits',
  Tenure5YearSubsequentWageLossReplaced = 'equivalentTenure5Year.subsequentWageLossReplaced',
  Tenure5YearSubsequentDurationBenefits = 'equivalentTenure5Year.subsequentDurationOfBenefits',
  Tenure5YearEquivalencyValueOfBenefitSchedule = 'equivalentTenure5Year.equivalencyValueOfBenefitSchedule',

  Tenure10YearAccidentCalendarDayBenefitsCommence = 'equivalentTenure10Year.accidentCalendarDayBenefitsCommence',
  Tenure10YearIllnessCalendarDayBenefitsCommence = 'equivalentTenure10Year.illnessCalendarDayBenefitsCommence',
  Tenure10YearInitialWageLossReplaced = 'equivalentTenure10Year.initialWageLossReplaced',
  Tenure10YearInitialDurationBenefits = 'equivalentTenure10Year.initialDurationOfBenefits',
  Tenure10YearSubsequentWageLossReplaced = 'equivalentTenure10Year.subsequentWageLossReplaced',
  Tenure10YearSubsequentDurationBenefits = 'equivalentTenure10Year.subsequentDurationOfBenefits',
  Tenure10YearEquivalencyValueOfBenefitSchedule = 'equivalentTenure10Year.equivalencyValueOfBenefitSchedule',

  Tenure15YearAccidentCalendarDayBenefitsCommence = 'equivalentTenure15Year.accidentCalendarDayBenefitsCommence',
  Tenure15YearIllnessCalendarDayBenefitsCommence = 'equivalentTenure15Year.illnessCalendarDayBenefitsCommence',
  Tenure15YearInitialWageLossReplaced = 'equivalentTenure15Year.initialWageLossReplaced',
  Tenure15YearInitialDurationBenefits = 'equivalentTenure15Year.initialDurationOfBenefits',
  Tenure15YearSubsequentWageLossReplaced = 'equivalentTenure15Year.subsequentWageLossReplaced',
  Tenure15YearSubsequentDurationBenefits = 'equivalentTenure15Year.subsequentDurationOfBenefits',
  Tenure15YearEquivalencyValueOfBenefitSchedule = 'equivalentTenure15Year.equivalencyValueOfBenefitSchedule',

  Tenure20YearAccidentCalendarDayBenefitsCommence = 'equivalentTenure20Year.accidentCalendarDayBenefitsCommence',
  Tenure20YearIllnessCalendarDayBenefitsCommence = 'equivalentTenure20Year.illnessCalendarDayBenefitsCommence',
  Tenure20YearInitialWageLossReplaced = 'equivalentTenure20Year.initialWageLossReplaced',
  Tenure20YearInitialDurationBenefits = 'equivalentTenure20Year.initialDurationOfBenefits',
  Tenure20YearSubsequentWageLossReplaced = 'equivalentTenure20Year.subsequentWageLossReplaced',
  Tenure20YearSubsequentDurationBenefits = 'equivalentTenure20Year.subsequentDurationOfBenefits',
  Tenure20YearEquivalencyValueOfBenefitSchedule = 'equivalentTenure20Year.equivalencyValueOfBenefitSchedule',

  TenureOver20YearAccidentCalendarDayBenefitsCommence = 'equivalentTenureOver20Year.accidentCalendarDayBenefitsCommence',
  TenureOver20YearIllnessCalendarDayBenefitsCommence = 'equivalentTenureOver20Year.illnessCalendarDayBenefitsCommence',
  TenureOver20YearInitialWageLossReplaced = 'equivalentTenureOver20Year.initialWageLossReplaced',
  TenureOver20YearInitialDurationBenefits = 'equivalentTenureOver20Year.initialDurationOfBenefits',
  TenureOver20YearSubsequentWageLossReplaced = 'equivalentTenureOver20Year.subsequentWageLossReplaced',
  TenureOver20YearSubsequentDurationBenefits = 'equivalentTenureOver20Year.subsequentDurationOfBenefits',
  TenureOver20YearEquivalencyValueOfBenefitSchedule = 'equivalentTenureOver20Year.equivalencyValueOfBenefitSchedule',
}

export enum PlanTypeLabelEnum {
  BetterThanStatutory = 'Better than Statutory',
  CollectiveBargainingAgreement = 'Collective Bargaining Agreement (CBA)',
  Combo = 'Combo',
  SelfInsured = 'Self-Insured',
  Statutory = 'Statutory',
}

export enum PlanType {
  Cba = 'CBA',
  BetterThanStatutory = 'BETTER_THAN_STATUTORY',
  Statutory = 'STATUTORY',
  SelfInsured = 'SELF_INSURED',
  Combo = 'COMBO',
}

export const planTypeOptions = [
  { value: PlanType.BetterThanStatutory, label: PlanTypeLabelEnum.BetterThanStatutory },
  { value: PlanType.Cba, label: PlanTypeLabelEnum.CollectiveBargainingAgreement },
  { value: PlanType.Combo, label: PlanTypeLabelEnum.Combo },
  { value: PlanType.SelfInsured, label: PlanTypeLabelEnum.SelfInsured },
  { value: PlanType.Statutory, label: PlanTypeLabelEnum.Statutory },
];

export const subPlanTypeOptions = [
  { value: PlanType.BetterThanStatutory, label: 'Better Than Statutory' },
  { value: PlanType.Cba, label: 'Collective Bargaining Agreement (CBA)' },
  { value: PlanType.SelfInsured, label: 'Self-Insured' },
  { value: PlanType.Statutory, label: 'Statutory' },
];

export enum PlanTypeInformation {
  BetterThanStatutoryPlanInformation = 'Better than Statutory Plan Information',
  CBAPlanInformation = 'CBA',
  ComboPlanInformation = 'Combo Plan Information ',
  SelfInsuredPlanInformation = 'Self-Insured Plan Information',
  StatutoryPlanInformation = 'Statutory Plan Information',
}

export enum Tenure {
  Minimum = 'Minimum',
  ZeroToSixMonths = '0-6 Months',
  SevenToTwelveMonths = '7-12 Months',
  TwoYears = '2 Years',
  ThreeYears = '3 Years',
  FourToFiveYears = '4-5 Years',
  SixToTenYears = '6-10 Years',
  ElevenToFifTeenYears = '11-15 Years',
  SixTeenToTwentyYears = '16-20 Years',
  OverTwentyYears = 'Over 20 Years',
  //update
  EmployerId = 'employerId',
}

export const TenureOptions = Object.entries(PlanType).map(([label, value]) => ({
  value,
  label: value,
}));

export enum AccidentCalendarDayBenefitsCommence {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven = 7,
  Eight = 8,
}

export const AccidentCalendarDayBenefitsCommenceOptions = Object.values(
  AccidentCalendarDayBenefitsCommence
).map((value) => ({
  value,
  label: value,
}));
