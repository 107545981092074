export enum PHCCoverageKey {
  Id = 'id',
  CoverageNumber = 'coverageNumber',
  EmployerId = 'employerId',
  HCPlanType = 'hcPlanType',
  HCPlanName = 'hcPlanName',
  HCPlanId = 'hcPlanId',
  HealthCarePlanName = 'healthCarePlanName',
  EmployerName = 'employerName',
  DOLNumber = 'dolNumber',
  StatusEffectiveDate = 'statusEffectiveDate',
  HealthCareContractor = 'healthCareContractor',
  HealthCareContractorId = 'healthCareContractorId',
  HealthCarePlanEmployerId = 'healthCarePlanEmployerId',
  HealthCarePlanEmployerLaborNumber = 'healthCarePlanEmployerLaborNumber',
  ContractorId = 'contractorId',
  Status = 'status',
  StatusDate = 'statusDate',
  GroupNumber = 'groupNumber',
  EmployeeClass = 'employeeClass',
  CreatedBy = 'createdUser',
  UpdatedBy = 'updatedUser',
}
