import { PHCCoverageKey } from './keys';

export interface PHCCoverage {
  [PHCCoverageKey.Id]: string;
  [PHCCoverageKey.CoverageNumber]: string;
  [PHCCoverageKey.HCPlanType]: string;
  [PHCCoverageKey.HCPlanName]: string;
  [PHCCoverageKey.HCPlanId]: string;
  [PHCCoverageKey.EmployerId]: string;
  [PHCCoverageKey.HealthCareContractor]: string;
  [PHCCoverageKey.HealthCareContractorId]: string;
  [PHCCoverageKey.EmployerName]: string;
  [PHCCoverageKey.DOLNumber]: string;
  [PHCCoverageKey.StatusEffectiveDate]: string;
  [PHCCoverageKey.ContractorId]: string;
  [PHCCoverageKey.Status]: PHCCoverageStatus;
  [PHCCoverageKey.StatusDate]: string;
  [PHCCoverageKey.GroupNumber]: string;
  [PHCCoverageKey.EmployeeClass]: string;
  [PHCCoverageKey.CreatedBy]: string;
  [PHCCoverageKey.UpdatedBy]: string;
}

export enum PHCCoverageStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Exemption = 'EXEMPTION',
  Expired = 'EXPIRED',
  Reinstate = 'REINSTATE',
}

export interface PHCCoveragePayload {
  [PHCCoverageKey.Id]: string;
  [PHCCoverageKey.HCPlanType]: string;
  [PHCCoverageKey.HCPlanId]: string;
  [PHCCoverageKey.EmployerId]: string;
  [PHCCoverageKey.HealthCareContractor]: string;
  [PHCCoverageKey.DOLNumber]: string;
  [PHCCoverageKey.ContractorId]: string;
  [PHCCoverageKey.Status]: PHCCoverageStatus;
  [PHCCoverageKey.StatusDate]: string;
  [PHCCoverageKey.GroupNumber]: string;
  [PHCCoverageKey.EmployeeClass]: string;
}
