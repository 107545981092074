import { PaginationResponseType, toRelatedAuditData } from '../helpers';
import { RelatedTDICoveragesKey } from './keys';
import { RelatedTDICoveragesType } from './types';

export const toRelatedTDICoverages = (data): PaginationResponseType<RelatedTDICoveragesType> => {
  return {
    ...data,
    data: data?.data.map((item) => ({
      ...item,
      [RelatedTDICoveragesKey.InsuranceCarrierAccountName]: item?.insuranceCarrier
        ? item?.insuranceCarrier?.accountName
        : '',
      [RelatedTDICoveragesKey.CarrierId]: item?.insuranceCarrier
        ? item?.insuranceCarrier?.carrierId
        : '',
      [RelatedTDICoveragesKey.InsuranceCarrierId]: item?.insuranceCarrier
        ? item?.insuranceCarrier?.id
        : '',
      [RelatedTDICoveragesKey.PlanName]: item?.tdiPlanType ? item?.tdiPlanType?.planName : '',
      [RelatedTDICoveragesKey.PlanType]: item?.tdiPlanType ? item?.tdiPlanType?.planType : '',
      [RelatedTDICoveragesKey.TdiPlanTypeNumber]: item?.tdiPlanType?.tdiPlanNumber || '',
      ...toRelatedAuditData(item),
    })),
  };
};
