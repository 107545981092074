import { PaginationResponseType, toAuditData } from '../helpers';
import { toRelatedAuditData } from './../helpers';
import { PHCCoverageKey } from './keys';
import { PHCCoverage } from './types';

export const toPHCCoverage = (data): PaginationResponseType<PHCCoverage> => ({
  ...data,
  data: data.data.map((phcCoverage) => ({
    ...phcCoverage,
    [PHCCoverageKey.HCPlanId]: phcCoverage?.hcPlan?.id,
    [PHCCoverageKey.HealthCarePlanName]: phcCoverage?.hcPlan?.planName,
    [PHCCoverageKey.HCPlanType]: phcCoverage?.hcPlan?.planType,
    [PHCCoverageKey.HCPlanName]: phcCoverage?.hcPlan?.planName,
    [PHCCoverageKey.EmployerName]: phcCoverage?.employer?.accountName,
    [PHCCoverageKey.EmployerId]: phcCoverage?.employer?.id,
    [PHCCoverageKey.DOLNumber]: phcCoverage?.employer?.laborNumber,
    [PHCCoverageKey.HealthCareContractor]: phcCoverage?.hcPlan?.hcContractor?.accountName,
    [PHCCoverageKey.HealthCareContractorId]: phcCoverage?.hcPlan?.hcContractorId,
    [PHCCoverageKey.ContractorId]: phcCoverage?.hcPlan?.hcContractor?.carrierId,
    ...toRelatedAuditData(phcCoverage),
  })),
});

export const toPHCCoverageDetail = (phcCoverage) => {
  return {
    ...phcCoverage,
    [PHCCoverageKey.HCPlanId]: phcCoverage?.hcPlan?.id,
    [PHCCoverageKey.HealthCarePlanName]: phcCoverage?.hcPlan?.planName,
    [PHCCoverageKey.HCPlanType]: phcCoverage?.hcPlan?.planType,
    [PHCCoverageKey.HCPlanName]: phcCoverage?.hcPlan?.planName,
    [PHCCoverageKey.EmployerName]: phcCoverage?.employer?.accountName,
    [PHCCoverageKey.EmployerId]: phcCoverage?.employer?.id,
    [PHCCoverageKey.DOLNumber]: phcCoverage?.employer?.laborNumber,
    [PHCCoverageKey.StatusEffectiveDate]: phcCoverage?.hcPlan?.statusEffectiveDate,
    [PHCCoverageKey.HealthCareContractor]: phcCoverage?.hcPlan?.hcContractor?.accountName,
    [PHCCoverageKey.HealthCareContractorId]: phcCoverage?.hcPlan?.hcContractorId,
    [PHCCoverageKey.ContractorId]: phcCoverage?.hcPlan?.hcContractor?.carrierId,
    ...toAuditData(phcCoverage),
  };
};
