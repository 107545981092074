import { IMAGES } from 'src/appConfig/images';

export enum RelatedType {
  Contact = 'Contact',
  AccountContact = 'Account Contact',
  AddressHistory = 'Address History',
  TDICaseEmployer = 'TDI Cases (Employee)',
  TDICaseClaimant = 'TDI Cases (Claimant)',
  TDICaseCarrier = 'TDI Cases (Carrier)',
  TDISpecialFundsEmployer = 'TDI SF Cases (Employee)',
  TDISpecialFundsClaimant = 'TDI SF Cases (Claimant)',
  PHCCoverage = 'PHC Coverages',
  TDICase = 'TDI Cases',
  TDICoverage = 'TDI Coverage',
  WCCoverage = 'WC Coverages',
  Hearings = 'Hearings',
  TDISFCRelatedHearing = 'Hearings',
  EmployerAudits = 'Employer Audits',
  RoutingInformation = 'Routing Information',
  WC1Form = 'WC-1s',
  WC2Form = 'WC-2s',
  WC3AForm = 'WC-3As',
  WC5Form = 'WC-5s',
  WC5AForm = 'WC-5As',
  WC3Form = 'WC-3s',
  TDI46Form = 'TDI-46s',
  TDI30s = 'TDI-30s',
  InjuryIllness = 'Injury/Illness (I/I)',
  CaseDependents = 'Case Dependents',
  ApprovalHistory = 'Approval History',
  Receipts = 'Receipts',
  SCFLedger = 'Ledger',
  Disbursements = 'Disbursements',
  SCFScheduleOfDisbursements = 'Schedule of Disbursements',
  PrehearingForEmployer = 'Prehearings for Delinquent Employer',
  CaseStatusHistory = 'Status History',
  AssociatedCases = 'Associated Cases',
  Orders = 'Orders',
  Vendors = 'Vendors',
  HearingsRequiredDocuments = 'Required Documents',
  PeriodsOfTPDDisability = 'Periods of TPD Disability',
  PeriodsOfTTDDisability = 'Periods of TTD Disability',
  VocationalRehabilitation = 'Vocational Rehabilitation Processes',
  CaseAwards = 'Case Awards',
  VRProvider = 'VR Provider',
  EmploymentAtTimeOfII = 'Employment(s) at Time of Injury/Illness (I/I)',
  CaseSettlements = 'Case Settlements',
  PartyNamesAddress = 'Party Names And Addresses',
  CaseProcessingLog = 'Case Processing Log',
  TypePurposes = 'Hearing Type and Purposes',
  DecisionTextBuilder = 'Decision Text Builder',
  AccountReconcile = 'GL Account Reconcile',
  AccountRollUp = 'GL Account Roll-up',
  Decisions = 'Decisions',
  TrackingLog = 'Tracking Log',
  AppealedDecision = 'Appealed Decisions',
  AmendedDecision = 'Amended Decisions',
  ReopenedDecision = 'Reopened Decisions',
  RelatedPartyArchives = 'Party History Archives',
  RelatedAddressHistoryArchives = 'Address History Archives',
  PartyNamesAndAddress = 'Party Names And Addresses',
  HearingRelatedNotes = 'Notes',
  PPDInjuredBodyParts = 'PPD - Injured Body Parts',
  TDICoverages = 'TDI Coverages',
  RequestForReconsideration = 'Requests for Reconsideration',
  AwardEmployments = 'Award Employments',
  Dependents = 'Dependents',
  AwardWorksheets = 'Award Worksheets',
  ContactsForParentAccount = 'Contacts',
  TDICasePartyNamesAndAddress = 'Party Names and Addresses',
  PotentialAssociated = 'Potential Or Associated WC Cases',
  TDISFDisbursement = 'Disbursements',
  SelectHRS = 'Hearing HRS/HAR',
  HC15Account = 'Accounts',
  TDISFCasePartyNamesAddresses = 'Party Names and Addresses',
  TDISFCase = 'TDI Special Fund Cases',
  AccountCase = 'DCD Cases',
  AuditHistory = 'Audit History',
  WC14 = 'WC-14s',
  WC77 = 'WC-77s',
  WCInsurancePolicies = 'WC Insurance Policies',
  PortalUser = 'Portal Users',
}

export const relatedTypeList = [
  {
    title: RelatedType.Contact,
    icon: IMAGES.iconContact,
  },
  {
    title: RelatedType.AccountContact,
    icon: IMAGES.iconAccountContact,
  },
  {
    title: RelatedType.AddressHistory,
    icon: IMAGES.iconAddressHistory,
  },
  {
    title: RelatedType.TDICaseEmployer,
    icon: IMAGES.iconTDICase,
  },
  {
    title: RelatedType.TDICaseClaimant,
    icon: IMAGES.iconTDICase,
  },
  {
    title: RelatedType.TDICaseCarrier,
    icon: IMAGES.iconTDICase,
  },
  {
    title: RelatedType.TDISpecialFundsEmployer,
    icon: IMAGES.iconTDICase,
  },
  {
    title: RelatedType.TDISpecialFundsClaimant,
    icon: IMAGES.iconTDICase,
  },
  {
    title: RelatedType.PHCCoverage,
    icon: IMAGES.phcCoveragePink,
  },
  {
    title: RelatedType.WCCoverage,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.TDI46Form,
    icon: IMAGES.iconFileFilled,
  },
  {
    title: RelatedType.TDI30s,
    icon: IMAGES.iconFileFilled,
  },
  {
    title: RelatedType.Hearings,
    icon: IMAGES.iconHearing,
  },
  {
    title: RelatedType.EmployerAudits,
    icon: IMAGES.iconEmployerAudit,
  },
  {
    title: RelatedType.RoutingInformation,
    icon: IMAGES.iconRouting,
  },
  {
    title: RelatedType.WC1Form,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.WC2Form,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.WC3AForm,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.WC5Form,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.WC5AForm,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.WC3Form,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.SCFLedger,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.SCFScheduleOfDisbursements,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.InjuryIllness,
    icon: IMAGES.iconInjuryIllness,
  },
  {
    title: RelatedType.CaseDependents,
    icon: IMAGES.iconCaseDependents,
  },
  {
    title: RelatedType.Disbursements,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.Receipts,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.PrehearingForEmployer,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.Orders,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.CaseStatusHistory,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.AssociatedCases,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.ApprovalHistory,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.CaseSettlements,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.Vendors,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.HearingRelatedNotes,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.HearingsRequiredDocuments,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.CaseAwards,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.VocationalRehabilitation,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.PartyNamesAddress,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.VRProvider,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.EmploymentAtTimeOfII,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.TypePurposes,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.CaseProcessingLog,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.AccountReconcile,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.AccountRollUp,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.Decisions,
    icon: IMAGES.iconDecisionOrange,
  },
  {
    title: RelatedType.AppealedDecision,
    icon: IMAGES.iconDecisionPurple,
  },
  {
    title: RelatedType.AmendedDecision,
    icon: IMAGES.iconDecisionGreen,
  },
  {
    title: RelatedType.ReopenedDecision,
    icon: IMAGES.iconDecisionOrange,
  },
  {
    title: RelatedType.PartyNamesAndAddress,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.TDICoverages,
    icon: IMAGES.iconTdiCoverage,
  },
  {
    title: RelatedType.RelatedPartyArchives,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.RelatedAddressHistoryArchives,
    icon: IMAGES.addressHistoryArchives,
  },
  {
    title: RelatedType.PeriodsOfTPDDisability,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.PeriodsOfTTDDisability,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.DecisionTextBuilder,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.PPDInjuredBodyParts,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.RequestForReconsideration,
    icon: IMAGES.iconRequestReconsideration,
  },
  {
    title: RelatedType.AwardEmployments,
    icon: IMAGES.iconAwardEmployment,
  },
  {
    title: RelatedType.Dependents,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.AwardWorksheets,
    icon: IMAGES.iconAwardWorksheet,
  },
  {
    title: RelatedType.ContactsForParentAccount,
    icon: IMAGES.iconContact,
  },
  {
    title: RelatedType.TrackingLog,
    icon: IMAGES.iconTrackingLog,
  },
  {
    title: RelatedType.TDICasePartyNamesAndAddress,
    icon: IMAGES.iconContact,
  },
  {
    title: RelatedType.PotentialAssociated,
    icon: IMAGES.iconPotential,
  },
  {
    title: RelatedType.TDISFCRelatedHearing,
    icon: IMAGES.iconRelatedHearingTDISFC,
  },
  {
    title: RelatedType.TDISFDisbursement,
    icon: IMAGES.iconTDISFDisbursements,
  },
  {
    title: RelatedType.SelectHRS,
    icon: IMAGES.iconHRS,
  },
  {
    title: RelatedType.HC15Account,
    icon: IMAGES.iconAccountRelated,
  },
  {
    title: RelatedType.TDISFCasePartyNamesAddresses,
    icon: IMAGES.iconPNA,
  },
  {
    title: RelatedType.TDISFCase,
    icon: IMAGES.iconTDIsf,
  },
  {
    title: RelatedType.TDICase,
    icon: IMAGES.iconTDICase,
  },
  {
    title: RelatedType.AccountCase,
    icon: IMAGES.iconAccountCases,
  },
  {
    title: RelatedType.AuditHistory,
    icon: IMAGES.iconAuditHistory,
  },
  {
    title: RelatedType.WC14,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.WC77,
    icon: IMAGES.iconWC1Form,
  },
  {
    title: RelatedType.WCInsurancePolicies,
    icon: IMAGES.iconWCInsurancePolicy,
  },
  {
    title: RelatedType.PortalUser,
    icon: IMAGES.iconPortalUser,
  },
];
