import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';
import { useDebounce } from 'src/hooks';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Option } from '../types';
import { TDIPlanKey } from './keys';
import { TDIPlan } from './types';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useLazyTdiPlans(
  options?: UseInfiniteQueryOptions<{ data: TDIPlan[] }, Error> & {
    [key: string]: string | number | string[];
  }
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getTDIPlans,
    fetchNextPage,
  } = useInfiniteQuery<{ data: TDIPlan[] }, Error>(
    [API_QUERIES.TDIPlans, pageParam, debounceSearch, { ...options }],
    (props): Promise<{ data: TDIPlan[] }> => {
      const { pageParam, queryKey } = props;
      const search = queryKey.at(-2);
      const options = queryKey.at(-1);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<{ data: TDIPlan[] }>(apiClient.getAllTDIPlans, [
        { ...pageParam, search, ...(options as Object) },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const tdiPlans: Option[] = useMemo(() => {
    if (isEmpty(data)) return [];
    return data.pages.reduce(
      (state, page, pageIdx) => [
        ...state,
        ...page.data.map(
          (tdiPlan, idx): Option => ({
            label: tdiPlan[TDIPlanKey.TDIPlanNumber],
            value: tdiPlan[TDIPlanKey.Id],
            index: pageIdx * 10 + idx,
          })
        ),
      ],
      []
    ) as Option[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    tdiPlans,
    error,
    isError,
    loading: isFetching,
    getTDIPlans,
    fetchNextPage,
    setInputSearch,
  };
}
