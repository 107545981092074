export enum HC15Key {
  Id = 'id',
  HC15Number = 'hc15Number',
  DateReceived = 'dateReceived',

  //Employer
  Employer = 'employer',
  EmployerName = 'employerName',
  EmployerId = 'employerId',
  DOLNumber = 'rawEmployerDOLNumber',

  //Health Care Plan
  HealthCarePlanName = 'hcPlan',
  HealthCarePlanNumber = 'hcPlanNumber',
  HealthCarePlanIdNumber = 'hcPlanIdNumber',
  HealthCarePlanId = 'hcPlanId',
  RawPlanName = 'rawPlanName',
  RawPlanGroupName = 'rawPlanGroupName',
  CertifiedAction = 'certifiedAction',
  RequestedEffectiveDateOfCoverage = 'requestedEffectiveDateOfCoverage',
  RequestedEffectiveDateOfCancellation = 'requestedEffectiveDateOfCancellation',
  IntendedEffectiveDateOfCancellation = 'intendedEffectiveDateOfCancellation',
  ReasonForCancellationIntentToCancel = 'rawReasonForCancellation',

  //Health Care Contractor
  HealthCareContractor = 'hcContractor',
  HcContractorState = 'hcContractorState',
  HcContractorZipCode = 'hcContractorZipcode',
  SubmittedDate = 'submittedDate',
  HcContractorAddress = 'hcContractorAddress',
  HcContractorCity = 'hcContractorCity',
  HealthCareContractorName = 'hcContractorName',
  HealthCareContractorId = 'hcContractorId',
  ContactEmailAddress = 'contactEmailAddress',
  ContactPhoneNumber = 'contactPhoneNumber',
  State = 'hcContractorState',
  City = 'hcContractorCity',
  Date = 'submittedDate',
  ZipCode = 'hcContractorZipcode',
  SubmittedBy = 'submittedBy',
  SubmittedByUserName = 'submittedByUserName',
  SubmittedByUserId = 'submittedByUserId',
  Address = 'address',

  // Action
  ActionTaken = 'actionTaken',
  RejectionReason = 'rejectionReason',
  CancellationReason = 'cancellationReason',
  ActionDate = 'actionDate',
  IsAccepted = 'isAccepted',
  IsRejected = 'isRejected',
  AssignedToUserName = 'assignedUser',
  AssignedToUserId = 'assignedUserId',
  ActionType = 'actionType',
  RecipientEmailAddress = 'recipientEmailAddress',
  Subject = 'subject',
  Content = 'content',
  Attachments = 'attachments',

  // Audit
  CreatedByUser = 'createdByUser',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  UpdatedUser = 'updatedUser',
  UpdatedAt = 'updatedAt',
  LastModifiedDate = 'lastModifiedDate',
  CreatedUser = 'createdUser',

  // Filter Key
  DOLNumberFilterKey = 'employer.laborNumber',
  StateFilterKey = 'hcContractor.state',
  CityFilterKey = 'hcContractor.city',
  ZipCodeFilterKey = 'hcContractor.zip',

  // Account related
  Accounts = 'accounts',
  AccountId = 'accountId',
  AccountName = 'accountName',
  AccountType = 'accountType',
  AccountLaborNumber = 'laborNumber',
  AccountDCDStatus = 'dcdStatus',
  AccountDCDStatusDate = 'dcdStatusDate',
}
