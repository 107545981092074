import { PaginationResponseType, toAddress, toAuditData } from '../helpers';
import { WC1RawKey } from './WC1Key';
import { SubmittalKey, WC1 } from './types';

export const toWC1Detail = (data) => ({
  ...data,
  [SubmittalKey.AssignedUser]: data?.assignedUser
    ? `${data?.assignedUser?.firstName} ${data?.assignedUser?.lastName}`
    : '',
  [SubmittalKey.Claimant]: data?.claimant?.accountName || '',
  [SubmittalKey.Gender]: data?.claimant?.gender || '',
  [SubmittalKey.SSN]: data?.claimant?.ssn || '',
  [SubmittalKey.Passport]: data?.claimant?.passport || '',
  [SubmittalKey.DateOfBirth]: data?.claimant?.dateOfBirth || '',
  [SubmittalKey.ClaimantEmail]: data?.claimant?.email || '',
  [SubmittalKey.ClaimantPhoneNumber]: data?.claimant?.phone || '',
  [SubmittalKey.RegisteredEmployer]: data?.employer?.accountName || '',
  [SubmittalKey.DBAName]: data?.employer?.dbaName || '',
  [SubmittalKey.EmployerAddress]: toAddress(data?.employer) || '',
  [SubmittalKey.DOLNumber]: data?.employer?.laborNumber || '',
  [SubmittalKey.EmployerPOC]: data?.employerPointOfContact?.contactName || '',
  [SubmittalKey.EmployerPOCPhone]: data?.employerPointOfContact?.phone || '',
  [SubmittalKey.EmployerPOCEmailAddress]: data?.employerPointOfContact?.email || '',
  [SubmittalKey.InsuranceCarrier]: data?.insuranceCarrier?.accountName || '',
  [SubmittalKey.CarrierID]: data?.insuranceCarrier?.carrierId || '',
  [SubmittalKey.NameAdjustingCompany]: data?.adjustingCompany?.accountName || '',
  [SubmittalKey.AdjusterName]: data?.adjusterContact?.contactName || '',
  [SubmittalKey.AdjusterPhone]: data?.adjusterContact?.phone || '',
  [SubmittalKey.AdjusterEmail]: data?.adjusterContact?.email || '',
  [SubmittalKey.AdjusterIdNumber]: data?.adjusterContact?.dcdId || '',
  [SubmittalKey.NameOfPhysician]: data?.physician?.accountName || '',
  [SubmittalKey.PhysicianAddress]: toAddress(data?.physician) || '',
  [SubmittalKey.PhysicianPhoneNumber]: data?.physician?.phone || '',
  [SubmittalKey.PhysicianEmail]: data?.physician?.email || '',
  [SubmittalKey.CaseNumber]: data?.case?.caseNumber || '',
  [SubmittalKey.ClaimantAddress]: toAddress(data?.claimant) || '',
  [WC1RawKey.RawSSN]: data[WC1RawKey.RawIdentityType] === 'SSN' ? data[WC1RawKey.RawIdNumber] : '',
  [WC1RawKey.RawPassportNumber]:
    data[WC1RawKey.RawIdentityType] !== 'SSN' ? data[WC1RawKey.RawIdNumber] : '',
  ...toAuditData(data),

  [SubmittalKey.EmployerNAICS]: data?.employer?.naics || '',
  [SubmittalKey.MaritalStatus]: data?.claimant?.maritalStatus || '',
});

export const toWC1s = (data: PaginationResponseType<any>): PaginationResponseType<WC1> => ({
  ...data,
  data: data.data.map((wc1s) => ({
    ...wc1s,
    [SubmittalKey.CarrierID]: wc1s?.insuranceCarrier?.carrierId || '',
    [SubmittalKey.AdjusterIdNumber]: wc1s?.adjusterContact?.dcdId || '',
    [WC1RawKey.RawSSN]:
      wc1s[WC1RawKey.RawIdentityType] === 'SSN' ? wc1s[WC1RawKey.RawIdNumber] : '',
    [WC1RawKey.RawPassportNumber]:
      wc1s[WC1RawKey.RawIdentityType] !== 'SSN' ? wc1s[WC1RawKey.RawIdNumber] : '',
    ...toAuditData(wc1s),
  })),
});
