export enum TDICoverageKey {
  Id = 'id',
  CoverageNumber = 'tdiCoverageNumber',
  EmployerId = 'employerId',
  EmployerName = 'employerName',
  DOLNumber = 'dolNumber',
  InsuranceCarrierId = 'insuranceCarrierId',
  InsuranceCarrierName = 'insuranceCarrierName',
  CarrierId = 'carrierId',
  Plan = 'tdiPlanTypeId',
  PlanType = 'planType',
  ActionTaken = 'actionTaken',
  EmployeeClass = 'employeeClass',
  EffectiveDate = 'effectiveDate',
  PercentageWagesReplaced = 'wagesReplacedPercent',
  WaitingPeriod = 'waitingPeriodDay',
  Duration = 'weekDuration',

  CreatedAt = 'createdAt',
  CreatedUser = 'createdUser',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  UpdatedUser = 'updatedUser',
  UpdatedAt = 'updatedAt',
}
