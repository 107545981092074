import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toContactData } from './helpers';
import { ContactPayload } from './types';

export function useGetContact(
  options?: UseQueryOptions<ApiResponseType<{ contact: ContactPayload }>, Error, ContactPayload> & {
    id: ContactPayload['id'];
  }
) {
  const {
    data: contact,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetContactById,
  } = useQuery<ApiResponseType<{ contact: ContactPayload }>, Error, ContactPayload>(
    [API_QUERIES.Contacts, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ contact: ContactPayload }>>(
          apiClient.getContact,
          params
        );
      },
      select: (data) => toContactData(data.data.contact),
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateContact = () =>
    queryClient.invalidateQueries([API_QUERIES.Contacts, { id: options.id }]);

  return {
    contact,
    error,
    isError,
    isLoading,
    onGetContactById,
    handleInvalidateContact,
  };
}
