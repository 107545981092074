import React from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Avatar } from './types';

export function useGetPresignedDownloadAvatarUrl(
  options?: UseQueryOptions<ApiResponseType<Avatar>, Error, string> & {
    filePath: string;
  }
) {
  const {
    data,
    error,
    isError,
    isLoading,
    refetch: getPresignedDownloadAvatarUrl,
  } = useQuery<ApiResponseType<Avatar>, Error, string>(
    [API_QUERIES.PRESIGNED_DOWNLOAD_URL, { filePath: options.filePath }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<Avatar>>(
          apiClient.getPresignedDownloadAvatarUrl,
          params
        );
      },
      enabled: !!options.filePath,
      select(data) {
        return data.data.url;
      },
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleSetStaleAvatar = () =>
    queryClient.invalidateQueries([
      API_QUERIES.PRESIGNED_DOWNLOAD_URL,
      { filePath: options.filePath },
    ]);

  return {
    avatarUrl: React.useMemo(() => {
      if (!data) return null;
      return data;
    }, [data]),
    error,
    isError,
    loading: isLoading,
    getPresignedDownloadAvatarUrl,
    handleSetStaleAvatar,
  };
}
