import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { HistoricalKey } from './keys';
import { CalculatePriorPaymentPayload, PriorPaymentResponse } from './types';

export function useCalculatePriorPayment(
  options?: UseQueryOptions<
    ApiResponseType<{ totalPriorPayments: PriorPaymentResponse[] }>,
    Error,
    PriorPaymentResponse[]
  > & {
    params: CalculatePriorPaymentPayload;
  }
) {
  const {
    data: totalPriorPayments,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetTotalPriorPayments,
  } = useQuery<
    ApiResponseType<{ totalPriorPayments: PriorPaymentResponse[] }>,
    Error,
    PriorPaymentResponse[]
  >([API_QUERIES.PRIOR_PAYMENT, options?.params], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ totalPriorPayments: PriorPaymentResponse[] }>>(
        apiClient.getPriorPayment,
        params
      );
    },
    select: ({ data }) => data.totalPriorPayments,
    enabled: !!(
      options.params[HistoricalKey.PaymentYear] &&
      options.params[HistoricalKey.InsuranceCarrierId] &&
      options.params[HistoricalKey.CaseId]
    ),
    cacheTime: 0,
    ...options,
  });

  return {
    totalPriorPayments,
    error,
    isError,
    isLoading,
    onGetTotalPriorPayments,
  };
}
