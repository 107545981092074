export enum PrehearingForEmployerKey {
  CaseNumber = 'caseNumber',
  PrehearingID = 'prehearingNumber',
  HearingDate = 'hearingDate',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  CaseId = 'caseId',
  EmployerName = 'employerName',
  EmployerId = 'employerId',
  ClaimantName = 'claimantName',
  ClaimantId = 'claimantId',
  DateOfII = 'dateOfII',
  ConfirmationEmployerBusiness = 'employerBusinessNameConfirmation',
  EmployerAddressOther = 'employerAddressOther',
  EmployerAddressStatus = 'employerAddressStatus',
  EmployerExplainDifferent = 'employerExplainTheDifference',
  UnknownAddressReason = 'employerReasonAddressIsUnknown',
  UpdateAddressTo = 'employerUpdateAddressTo',
  EmployerCity = 'employerCity',
  EmployerState = 'employerState',
  EmployerZipCode = 'employerZip',
  EmployerNumOfHawaiiEmployees = 'employerNumOfHawaiiEmployees',

  EmployerDOLNumber = 'employerDOLNumber',
  IsRegistered = 'employerIsNotRegistered',
  EffectiveCoverageFrom = 'employerEffectiveCoverageFrom',
  EffectiveCoverageTo = 'employerEffectiveCoverageTo',
  PolicyNumber = 'employerPolicyNumber',
  IsStillDelinquent = 'employerIsStillDelinquent',
  CoverageNotRequiredExplain = 'employerCoverageNotRequiredReason',

  RecommendedAssessmentPeriodFrom = 'penaltyRecommendedAssessmentPeriodFrom',
  RecommendedAssessmentPeriodTo = 'penaltyRecommendedAssessmentPeriodTo',
  PenaltyAmount = 'penaltyAmount',
  PenaltyNonCoverageOther = 'penaltyOther',

  EmployerWorkerOther = 'employerWcOther',

  PenaltyFailureToFileRecommendedAssessmentOfPenalty = 'penaltyAssessmentIsRecommended',
  PenaltyFailureToFilePenaltyAmount = 'penaltyAssessmentAmount',
  DateWC1FileOn = 'penaltyAssessmentNotRecommendedDate',
  ERNotInBusiness = 'fabricationIsErNotLocated',
  DCCADateOfLastDateInBusiness = 'fabricationBusinessLastDate',

  EmployerCanNotBeLocated = 'fabricationIsEmployerNotLocated',
  ERRefuseReason = 'fabricationErRefusedToCompleteReason',
  UITerminationDate = 'fabricationUiTerminationDate',
  FabricationOther = 'fabricationOther',

  EmployerFinancialSolvency = 'employerFinancialSolvency',
  EmployerAudit = 'employerAuditId',

  EmployerAcceptLiability = 'employerAbilityAcceptsLiability',
  EmployerLiabilityOther = 'employerAbilityOther',

  DCDReceivedDate = 'dcdReceivedDate',
  EmployeeRepresentedBy = 'employeeRepresentedBy',
  GeneralContractor = 'generalContractor',
  GeneralContractorAddress = 'generalContractorAddress',
  GeneralContractorCity = 'generalContractorCity',
  GeneralContractorState = 'generalContractorState',
  GeneralContractorZipCode = 'generalContractorZip',

  Subcontractor = 'subcontractor',
  SubcontractorAddress = 'subcontractorAddress',
  SubcontractorCity = 'subcontractorCity',
  SubcontractorState = 'subcontractorState',
  SubcontractorZipCode = 'subcontractorZip',
  AddressOfOtherCONTR = 'otherNameAddressOnProject',

  IsNoLawSuitFiled = 'isNoLawSuitFiled',
  LawSuitFiledDate = 'lawSuitFiledDate',
  CircuitCourtNumber = 'circuitCourtNumber',
  FederalCourtNumber = 'federalCourtNumber',
  ClaimantAttorneyName = 'claimantAttorneyName',

  DateReferredToLegalOffice = 'dateReferredtoLegalOffice',
  Kind = 'kind',
  ContemplatedKind = 'contemplatedKind',
  NotContemplated = 'isNotContemplated',
}
