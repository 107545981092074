import { WC1RawKey } from './WC1Key';
import { WC1RawInterface } from './WC1RawInterface';

export enum WC1Status {
  Review = 'RS_REVIEW',
  Coding = 'RS_CODING',
  Complete = 'RS_CODING_COMPLETE',
}

export enum SubmittalKey {
  // ---- Claim review status ----//
  Case = 'case',
  AssignedUser = 'assignedUser',
  AssignedUserId = 'assignedUserId',
  CaseId = 'caseId',
  CaseNumber = 'caseNumber',
  Reviewed = 'isReviewed',
  RCCodingStatus = 'rcCodingStatus',
  RCCompletedWithCase = 'rcCompletedDate',
  IsCompleteWithCase = 'claimCompleted',
  HoldingIssueType = 'holdingIssueType',
  JacketCreateDate = 'jacketCreatedDate',
  ClaimWithdrawnDate = 'claimWithdrawnDate',
  SubmittalMethod = 'methodSubmittal',
  CaseCompleteDate = 'rsCompletedDate',
  IsReadyUploadStatusChange = 'changeReadyUploadStatus',
  SubmittalTitle = 'submitterTitle',
  SubmittalDate = 'submittalDate',
  SubmitterName = 'submitterName',
  CaseEndDate = 'caseEndDate',
  CurrentLocationCode = 'originalLocationCode',
  OriginalServiceLocation = 'currentServicingLocation',
  FullTimePartTime = 'fulltimeParttime',

  DocumentValidationComments = 'documentValidationComment',

  DCISDownloadBatchNumber = 'dcisDownloadBatchNumber',
  UploadBatchCreateDate = 'dcisUploadBatchDate',
  DCISDownloadBatchCreateDate = 'dcisDownloadBatchDate',

  ClaimantId = 'claimantId',
  Claimant = 'claimant',
  Gender = 'gender',
  MaritalStatus = 'maritalStatus',
  SSN = 'ssn',
  Passport = 'passport',
  DateOfBirth = 'dateOfBirth',
  ClaimantAddress = 'claimantAddress',
  AdditionalAddress = 'employeeAdditionAddress',
  ClaimantEmail = 'claimantEmail',
  ClaimantPhoneNumber = 'claimantPhoneNumber',
  DateHired = 'dateHired',
  YearsEmployed = 'yearEmployed',
  AgeAtTimeOfII = 'ageInjury',
  MonthsEmployed = 'monthEmployed',
  Occupation = 'occupation',
  Department = 'department',
  Payroll = 'payrollCompClassCode',
  SOC = 'socCode',
  OCC = 'occCode',

  RegisteredEmployerId = 'employerId',
  RegisteredEmployer = 'employer',
  DBAName = 'dbaName',
  EmployerAddress = 'employerAddress',
  EmployerCity = 'city',
  EmployerState = 'state',
  EmployerZipCode = 'zip',
  DOLNumber = 'laborNumber',
  FederalIdNumber = 'federalId',
  NatureOfBusiness = 'natureOfBusiness',
  EmployerPOCId = 'employerPointOfContactId',
  EmployerPOC = 'EmployerPOC',
  EmployerPOCPhone = 'employerPOCPhone',
  EmployerPOCEmailAddress = 'employerPOCEmailAddress',
  PreFabricated = 'prefabricatedFrom',

  DateOfIIReported = 'injuryReportedDate',
  DateOfII = 'injuryDate',
  TimeOfII = 'injuryTime',
  DidEmployeeWorkingFullShift = 'didEmployeeWorkFullShift',
  OnEmployerPremise = 'onEmployerPremise',
  WhereIIOccur = 'whereInjuryOccur',
  IICityLocation = 'injuryCity',
  IIStateLocation = 'injuryState',
  IIZipCodeLocation = 'injuryZipcode',
  WC1SignatureDate = 'signatureDate',
  DateWC1SentICSD = 'dateSentToIcsd',
  DateClaim = 'dateClaimReceivedByDcd',
  WC5ReceivedDate = 'wc5ReceivedDate',
  WC18Sent = 'isWc18Sent',
  IsLiabilityDenied = 'isLiabilityDenied',
  WhyLiabilityDenied = 'liabilityDeniedWhy',
  WC44DateSent = 'wc44SentDate',
  DateSentToEnforcement = 'dateSentToEnforcement',
  HowDidIIOccur = 'injuryHow',
  TimeWorkShiftBegan = 'timeWorkShiftBegan',
  TimeWorkShiftEnd = 'timeWorkShiftEnd',
  IISource = 'sourceInjury',
  DoingWhenInjury = 'whatWasEeDoing',
  ObjectDirectlyInjuredEmployee = 'objectInjuredEmployee',

  DescribeNatureOfII = 'injuryDescribeDetail',
  PossibilityOfDisfigurement = 'possibilityDisfigurement',
  PossibilityOfBurn = 'possibilityBurn',

  DateDisabilityBegan = 'dateDisabilityBegan',
  EmployeeFurnished = 'wasEmployeeFurnishesMealsTipsLodging',
  AverageWeeklyWage = 'avgWeeklyWage',
  BackToWorkDate = 'dateBackToWork',
  IsEmployeePaidInFullDay = 'isEmployeePaidForFullDayOfInjury',
  DateDeceased = 'employeeDeceasedDate',
  HourlyWage = 'hourlyWage',
  MonthlySalary = 'monthlySalary',
  HrsWorkedWeek = 'hoursWorkWeek',
  WeighingFactor = 'weightingFactor',
  LapsedTime = 'lapsedTimeHours',

  InsuranceCarrierId = 'insuranceCarrierId',
  InsuranceCarrier = 'insuranceCarrier',
  CarrierID = 'carrierId',
  NameAdjustingCompany = 'adjustingCompany',
  AdjustingCompanyId = 'adjustingCompanyId',
  AdjusterName = 'adjusterName',
  AdjusterId = 'adjusterId',
  AdjusterEmail = 'adjusterEmail',
  AdjusterPhone = 'adjusterPhone',
  AdjusterIdNumber = 'adjusterIdNumber',
  AdjusterClaimNumber = 'adjusterClaimNumber',
  PolicyNumber = 'policyNumber',
  CarrierClaimNumber = 'carrierClaimNumber',
  PolicyPeriod = 'policyPeriodCoverage',
  MedicalDeductible = 'medicalDeductible',
  MedicalDeductibleFlag = 'medicalDeductibleFlag',
  TotalBenefitAmountPaid = 'totalBenefitAmountPaid',
  IMERequestedDate = 'imeRequestDate',

  NameOfPhysician = 'physicianName',
  Physician = 'physician',
  PhysicianId = 'physicianId',
  PhysicianPhoneNumber = 'physicianPhoneNumber',
  PhysicianEmail = 'physicianEmail',
  PhysicianAddress = 'physicianAddress',
  PhysicianCity = 'physicianCity',
  PhysicianState = 'physicianState',
  PhysicianZipCode = 'physicianZipCode',
  InpatientOvernight = 'inpatientOvernight',
  EmergencyRoomOnly = 'emergencyRoomOnly',
  MedicalFacilityName = 'medicalFacilityName',
  MedicalFacilityAddress = 'medicalFacilityAddress',
  MedicalFacilityCity = 'medicalFacilityCity',
  MedicalFacilityState = 'medicalFacilityState',
  MedicalFacilityCountry = 'medicalFacilityCountry',
  MedicalFacilityZipCode = 'medicalFacilityZipcode',

  EmployerNAICS = 'naics',
  HazardousAct = 'hazardousAct',
  HazardousCondition = 'hazardousCondition',
  Event = 'injuryEvent',
  Activity = 'injuryActivity',
  MultipleBodyParts = 'isMultipleBodyParts',
  AOS = 'aos',
  IIFactor = 'injuryFactor',
  PartOfBody = 'partOfBody', //
  PartOfBodyCode = 'partOfBodyCode',
  SpecialFundCode = 'specialFundCode',
  IIType = 'injuryType',
  CountryOfInjuryCode = 'injuryCountyCode',
  Task = 'injuryTask',
  NatureOfII = 'natureOfInjury',

  AdjusterSubmittalBatchType = 'adjusterSubmittalBatchType',
  AdjusterSubmittalBatchNumber = 'adjusterSubmittalBatchNumber',
  AdjusterRefNoForCarrierClaim = 'adjusterRefNoForCarrierClaim',
  LastModifiedAt = 'updatedAt',
}

export enum RCCodingStatus {
  Completed = 'COMPLETED',
  NotCompleted = 'NOT_COMPLETED',
}

export enum HoldingIssueType {
  SSNMisMatch = 'SSN_MISSMATCH',
  NameDontMatch = 'NAME_NOT_MATCH',
  DolNumberMismatch = 'DOL_NUMBER_MISSMATCH',
  MissingInformation = 'MISSING_INFORMATION',
  MissingInjuryLocation = 'MISSING_INJURY_LOCATION',
}

export enum SubmittalMethod {
  TBD = 'TBD',
  ScannedForm = 'SCANNED_FORM',
  API = 'API',
}

export enum CurrentLocationCode {
  HE = 'HE',
  HW = 'HW',
  KA = 'KA',
  MA = 'MA',
  OA = 'OA',
}

export enum FullTimePartTime {
  FullTime = 'FULL_TIME',
  PartTime = 'PART_TIME',
  Volunteer = 'VOLUNTEER',
}

export enum PreFabricated {
  WC2 = 'WC-2',
  WC5 = 'WC-5',
}

export enum WC1MartialStatus {
  Single = 'SINGLE',
  Married = 'MARRIED',
  Separated = 'SEPARATED',
  Divorced = 'DIVORCED',
}

export enum CountryOfInjuryCode {
  TBD = 'TBD',
}

export enum AdjusterSubmittalBatchType {
  WC1 = 'WC-1',
  WC3A = 'WC-3A',
}

export type WC1 = WC1RawInterface & Submittal;

export const WC1Key = {
  ...WC1RawKey,
  ...SubmittalKey,
  Status: 'status',
};

export const WC1FilterKey = {
  ...WC1Key,
  FilterAdjusterName: 'adjusterContact.contactName',
  FilterAdjusterEmail: 'adjusterContact.email',
  FilterAdjusterIdNumber: 'adjusterContact.dcdId',
  FilterAdjusterPhone: 'adjusterContact.phone',

  FilterClaimantStreet: 'claimant.street', //TODO: check BE again
  FilterClaimantCity: 'claimant.city', //TODO: check BE again
  FilterClaimantEmail: 'claimant.email',
  FilterClaimantPhoneNumber: 'claimant.phone',

  FilterEmployerCity: 'employer.city',
  FilterEmployerNAICS: 'employer.naics',
  FilterEmployerState: 'employer.state',
  FilterEmployerZipCode: 'employer.zip',
  FilterEmployerPOCPhone: 'employerPointOfContact.phone',
  FilterEmployerPOCEmailAddress: 'employerPointOfContact.email',

  FilterNameOfPhysician: 'physician.accountName',
  FilterPhysicianAddress: 'physician.street',
  FilterPhysicianCity: 'physician.city',
  FilterPhysicianEmail: 'physician.email',
  FilterPhysicianPhoneNumber: 'physician.phone',
  FilterPhysicianState: 'physician.state',
  FilterPhysicianZipCode: 'physician.zip',
};

export type updateWC1StatusPayload = {
  id: string;
  status: string;
};

export interface UpdateSubmittalPayload {
  id?: string;
  [SubmittalKey.AssignedUser]?: string;
  [SubmittalKey.AssignedUserId]?: string;
  [SubmittalKey.CaseId]?: string;
  [SubmittalKey.CaseNumber]?: string;
  [SubmittalKey.Reviewed]?: boolean;
  [SubmittalKey.RCCodingStatus]?: string;
  [SubmittalKey.RCCompletedWithCase]?: Date | string | string;
  [SubmittalKey.IsCompleteWithCase]?: boolean;
  [SubmittalKey.HoldingIssueType]?: string;
  [SubmittalKey.JacketCreateDate]?: Date | string;
  [SubmittalKey.ClaimWithdrawnDate]?: Date | string;
  [SubmittalKey.SubmittalMethod]?: string;
  [SubmittalKey.CaseCompleteDate]?: Date | string;
  [SubmittalKey.IsReadyUploadStatusChange]?: boolean;
  [SubmittalKey.SubmittalTitle]?: string;
  [SubmittalKey.SubmittalDate]?: Date | string;
  [SubmittalKey.SubmitterName]?: string;
  [SubmittalKey.CaseEndDate]?: Date | string;
  [SubmittalKey.CurrentLocationCode]?: string;
  [SubmittalKey.OriginalServiceLocation]?: string;
  [SubmittalKey.FullTimePartTime]?: string;
  [SubmittalKey.DocumentValidationComments]?: string;
  [SubmittalKey.DCISDownloadBatchNumber]?: string;
  [SubmittalKey.UploadBatchCreateDate]?: Date | string;
  [SubmittalKey.DCISDownloadBatchCreateDate]?: string;
  [SubmittalKey.ClaimantId]?: string;
  [SubmittalKey.Claimant]?: string;
  [SubmittalKey.Gender]?: string;
  [SubmittalKey.MaritalStatus]?: string;
  [SubmittalKey.SSN]?: string;
  [SubmittalKey.Passport]?: string;
  [SubmittalKey.DateOfBirth]?: Date | string;
  [SubmittalKey.ClaimantAddress]?: string;
  [SubmittalKey.AdditionalAddress]?: string;
  [SubmittalKey.ClaimantEmail]?: string;
  [SubmittalKey.ClaimantPhoneNumber]?: string;
  [SubmittalKey.DateHired]?: Date | string;
  [SubmittalKey.YearsEmployed]?: number;
  [SubmittalKey.AgeAtTimeOfII]?: number;
  [SubmittalKey.MonthsEmployed]?: number;
  [SubmittalKey.Occupation]?: string;
  [SubmittalKey.Department]?: string;
  [SubmittalKey.Payroll]?: string;
  [SubmittalKey.SOC]?: string;
  [SubmittalKey.OCC]?: string;

  [SubmittalKey.RegisteredEmployerId]?: string;
  [SubmittalKey.RegisteredEmployer]?: string;
  [SubmittalKey.DBAName]?: string;
  [SubmittalKey.EmployerAddress]?: string;
  [SubmittalKey.DOLNumber]?: string;
  [SubmittalKey.FederalIdNumber]?: string;
  [SubmittalKey.NatureOfBusiness]?: string;
  [SubmittalKey.EmployerPOCId]?: string;
  [SubmittalKey.EmployerPOC]?: string;
  [SubmittalKey.EmployerPOCPhone]?: string;
  [SubmittalKey.EmployerPOCEmailAddress]?: string;
  [SubmittalKey.PreFabricated]?: string;
  [SubmittalKey.DateOfIIReported]?: Date | string;
  [SubmittalKey.DateOfII]?: Date | string;
  [SubmittalKey.TimeOfII]?: Date | string;
  [SubmittalKey.DidEmployeeWorkingFullShift]?: string;
  [SubmittalKey.OnEmployerPremise]?: string;
  [SubmittalKey.WhereIIOccur]?: string;
  [SubmittalKey.IICityLocation]?: string;
  [SubmittalKey.IIStateLocation]?: string;
  [SubmittalKey.IIZipCodeLocation]?: string;
  [SubmittalKey.WC1SignatureDate]?: string;
  [SubmittalKey.DateWC1SentICSD]?: string;
  [SubmittalKey.DateClaim]?: string;
  [SubmittalKey.WC5ReceivedDate]?: string;
  [SubmittalKey.WC18Sent]?: string;
  [SubmittalKey.IsLiabilityDenied]?: string;
  [SubmittalKey.WhyLiabilityDenied]?: string;
  [SubmittalKey.WC44DateSent]?: string;
  [SubmittalKey.DateSentToEnforcement]?: string;
  [SubmittalKey.HowDidIIOccur]?: string;
  [SubmittalKey.TimeWorkShiftBegan]?: Date | string;
  [SubmittalKey.TimeWorkShiftEnd]?: Date | string;
  [SubmittalKey.IISource]?: string;
  [SubmittalKey.DoingWhenInjury]?: string;
  [SubmittalKey.ObjectDirectlyInjuredEmployee]?: string;
  [SubmittalKey.DescribeNatureOfII]?: string;
  [SubmittalKey.PossibilityOfDisfigurement]?: string;
  [SubmittalKey.PossibilityOfBurn]?: string;
  [SubmittalKey.DateDisabilityBegan]?: string;
  [SubmittalKey.EmployeeFurnished]?: string;
  [SubmittalKey.AverageWeeklyWage]?: number;
  [SubmittalKey.BackToWorkDate]?: Date | string;
  [SubmittalKey.IsEmployeePaidInFullDay]?: boolean;
  [SubmittalKey.DateDeceased]?: Date | string;
  [SubmittalKey.HourlyWage]?: number;
  [SubmittalKey.MonthlySalary]?: number;
  [SubmittalKey.HrsWorkedWeek]?: number;
  [SubmittalKey.WeighingFactor]?: number;
  [SubmittalKey.LapsedTime]?: number;
  [SubmittalKey.InsuranceCarrierId]?: string;
  [SubmittalKey.InsuranceCarrier]?: string;
  [SubmittalKey.CarrierID]?: string;
  [SubmittalKey.NameAdjustingCompany]?: string;
  [SubmittalKey.AdjustingCompanyId]?: string;
  [SubmittalKey.AdjusterName]?: string;
  [SubmittalKey.AdjusterId]?: string;
  [SubmittalKey.AdjusterEmail]?: string;
  [SubmittalKey.AdjusterPhone]?: string;
  [SubmittalKey.AdjusterIdNumber]?: string;
  [SubmittalKey.AdjusterClaimNumber]?: string;
  [SubmittalKey.PolicyNumber]?: string;
  [SubmittalKey.CarrierClaimNumber]?: string;
  [SubmittalKey.PolicyPeriod]?: string;
  [SubmittalKey.MedicalDeductible]?: number;
  [SubmittalKey.MedicalDeductibleFlag]?: string;
  [SubmittalKey.TotalBenefitAmountPaid]?: number;
  [SubmittalKey.IMERequestedDate]?: string;
  [SubmittalKey.NameOfPhysician]?: string;
  [SubmittalKey.Physician]?: any;
  [SubmittalKey.PhysicianId]?: string;
  [SubmittalKey.PhysicianPhoneNumber]?: string;
  [SubmittalKey.PhysicianEmail]?: string;
  [SubmittalKey.PhysicianAddress]?: string;
  [SubmittalKey.PhysicianZipCode]?: string;
  [SubmittalKey.InpatientOvernight]?: string;
  [SubmittalKey.EmergencyRoomOnly]?: string;
  [SubmittalKey.MedicalFacilityName]?: string;
  [SubmittalKey.MedicalFacilityAddress]?: string;
  [SubmittalKey.MedicalFacilityCity]?: string;
  [SubmittalKey.MedicalFacilityState]?: string;
  [SubmittalKey.MedicalFacilityCountry]?: string;
  [SubmittalKey.MedicalFacilityZipCode]?: string;

  [SubmittalKey.EmployerNAICS]?: string;
  [SubmittalKey.HazardousAct]?: string;
  [SubmittalKey.HazardousCondition]?: string;
  [SubmittalKey.Event]?: string;
  [SubmittalKey.Activity]?: string;
  [SubmittalKey.MultipleBodyParts]?: string;
  [SubmittalKey.AOS]?: string;
  [SubmittalKey.IIFactor]?: string;
  [SubmittalKey.PartOfBody]?: string;
  [SubmittalKey.PartOfBodyCode]?: string;
  [SubmittalKey.SpecialFundCode]?: string;
  [SubmittalKey.IIType]?: string;
  [SubmittalKey.CountryOfInjuryCode]?: string;
  [SubmittalKey.Task]?: string;
  [SubmittalKey.NatureOfII]?: string;

  [SubmittalKey.AdjusterSubmittalBatchType]?: string;
  [SubmittalKey.AdjusterSubmittalBatchNumber]?: string;
  [SubmittalKey.AdjusterRefNoForCarrierClaim]?: string;
  [SubmittalKey.PhysicianState]?: string;
}

export interface Submittal {
  [SubmittalKey.Case]: any;
  [SubmittalKey.PhysicianState]: string;
  [SubmittalKey.AssignedUser]?: string;
  [SubmittalKey.AssignedUserId]?: string;
  [SubmittalKey.CaseId]?: string;
  [SubmittalKey.CaseNumber]?: string;
  [SubmittalKey.Reviewed]?: boolean;
  [SubmittalKey.RCCodingStatus]?: RCCodingStatus;
  [SubmittalKey.RCCompletedWithCase]?: string;
  [SubmittalKey.IsCompleteWithCase]?: boolean;
  [SubmittalKey.HoldingIssueType]?: HoldingIssueType;
  [SubmittalKey.JacketCreateDate]?: string;
  [SubmittalKey.ClaimWithdrawnDate]?: string;
  [SubmittalKey.SubmittalMethod]?: SubmittalMethod;
  [SubmittalKey.CaseCompleteDate]?: string;
  [SubmittalKey.IsReadyUploadStatusChange]?: boolean;
  [SubmittalKey.SubmittalTitle]?: string;
  [SubmittalKey.SubmittalDate]?: string;
  [SubmittalKey.SubmitterName]?: string;
  [SubmittalKey.CaseEndDate]?: string;
  [SubmittalKey.CurrentLocationCode]?: string;
  [SubmittalKey.OriginalServiceLocation]?: string;
  [SubmittalKey.FullTimePartTime]?: FullTimePartTime;
  [SubmittalKey.DocumentValidationComments]?: string;
  [SubmittalKey.DCISDownloadBatchNumber]?: string;
  [SubmittalKey.UploadBatchCreateDate]?: string;
  [SubmittalKey.DCISDownloadBatchCreateDate]?: string;
  [SubmittalKey.ClaimantId]?: string;
  [SubmittalKey.Claimant]?: any;
  [SubmittalKey.Gender]?: string;
  [SubmittalKey.MaritalStatus]?: WC1MartialStatus;
  [SubmittalKey.SSN]?: string;
  [SubmittalKey.Passport]?: string;
  [SubmittalKey.DateOfBirth]?: string;
  [SubmittalKey.ClaimantAddress]?: string;
  [SubmittalKey.AdditionalAddress]?: string;
  [SubmittalKey.ClaimantEmail]?: string;
  [SubmittalKey.ClaimantPhoneNumber]?: string;
  [SubmittalKey.DateHired]?: string;
  [SubmittalKey.YearsEmployed]?: number;
  [SubmittalKey.AgeAtTimeOfII]?: number;
  [SubmittalKey.MonthsEmployed]?: number;
  [SubmittalKey.Occupation]?: string;
  [SubmittalKey.Department]?: string;
  [SubmittalKey.Payroll]?: string;
  [SubmittalKey.SOC]?: string;
  [SubmittalKey.OCC]?: string;

  [SubmittalKey.RegisteredEmployerId]?: string;
  [SubmittalKey.RegisteredEmployer]?: any;
  [SubmittalKey.DBAName]?: string;
  [SubmittalKey.EmployerAddress]?: string;
  [SubmittalKey.DOLNumber]?: string;
  [SubmittalKey.FederalIdNumber]?: string;
  [SubmittalKey.NatureOfBusiness]?: string;
  [SubmittalKey.EmployerPOCId]?: string;
  [SubmittalKey.EmployerPOC]?: string;
  [SubmittalKey.EmployerPOCPhone]?: string;
  [SubmittalKey.EmployerPOCEmailAddress]?: string;
  [SubmittalKey.PreFabricated]?: PreFabricated;
  [SubmittalKey.DateOfIIReported]?: string;
  [SubmittalKey.DateOfII]?: string;
  [SubmittalKey.TimeOfII]?: string;
  [SubmittalKey.DidEmployeeWorkingFullShift]?: string;
  [SubmittalKey.OnEmployerPremise]?: string;
  [SubmittalKey.WhereIIOccur]?: string;
  [SubmittalKey.IICityLocation]?: string;
  [SubmittalKey.IIStateLocation]?: string;
  [SubmittalKey.IIZipCodeLocation]?: string;
  [SubmittalKey.WC1SignatureDate]?: string;
  [SubmittalKey.DateWC1SentICSD]?: string;
  [SubmittalKey.DateClaim]?: string;
  [SubmittalKey.WC5ReceivedDate]?: string;
  [SubmittalKey.WC18Sent]?: string;
  [SubmittalKey.IsLiabilityDenied]?: string;
  [SubmittalKey.WhyLiabilityDenied]?: string;
  [SubmittalKey.WC44DateSent]?: string;
  [SubmittalKey.DateSentToEnforcement]?: string;
  [SubmittalKey.HowDidIIOccur]?: string;
  [SubmittalKey.TimeWorkShiftBegan]?: string;
  [SubmittalKey.TimeWorkShiftEnd]?: string;
  [SubmittalKey.IISource]?: string;
  [SubmittalKey.DoingWhenInjury]?: string;
  [SubmittalKey.ObjectDirectlyInjuredEmployee]?: string;
  [SubmittalKey.DescribeNatureOfII]?: string;
  [SubmittalKey.PossibilityOfDisfigurement]?: string;
  [SubmittalKey.PossibilityOfBurn]?: string;
  [SubmittalKey.DateDisabilityBegan]?: string;
  [SubmittalKey.EmployeeFurnished]?: string;
  [SubmittalKey.AverageWeeklyWage]?: number;
  [SubmittalKey.BackToWorkDate]?: string;
  [SubmittalKey.IsEmployeePaidInFullDay]?: boolean;
  [SubmittalKey.DateDeceased]?: string;
  [SubmittalKey.HourlyWage]?: number;
  [SubmittalKey.MonthlySalary]?: number;
  [SubmittalKey.HrsWorkedWeek]?: number;
  [SubmittalKey.WeighingFactor]?: number;
  [SubmittalKey.LapsedTime]?: number;
  [SubmittalKey.InsuranceCarrierId]?: string;
  [SubmittalKey.InsuranceCarrier]?: string;
  [SubmittalKey.CarrierID]?: string;
  [SubmittalKey.NameAdjustingCompany]?: string;
  [SubmittalKey.AdjustingCompanyId]?: string;
  [SubmittalKey.AdjusterName]?: string;
  [SubmittalKey.AdjusterId]?: string;
  [SubmittalKey.AdjusterEmail]?: string;
  [SubmittalKey.AdjusterPhone]?: string;
  [SubmittalKey.AdjusterIdNumber]?: string;
  [SubmittalKey.AdjusterClaimNumber]?: string;
  [SubmittalKey.PolicyNumber]?: string;
  [SubmittalKey.CarrierClaimNumber]?: string;
  [SubmittalKey.PolicyPeriod]?: string;
  [SubmittalKey.MedicalDeductible]?: number;
  [SubmittalKey.MedicalDeductibleFlag]?: string;
  [SubmittalKey.TotalBenefitAmountPaid]?: number;
  [SubmittalKey.IMERequestedDate]?: string;
  [SubmittalKey.NameOfPhysician]?: string;
  [SubmittalKey.PhysicianId]?: string;
  [SubmittalKey.PhysicianPhoneNumber]?: string;
  [SubmittalKey.PhysicianEmail]?: string;
  [SubmittalKey.PhysicianAddress]?: string;
  [SubmittalKey.PhysicianZipCode]?: string;
  [SubmittalKey.InpatientOvernight]?: string;
  [SubmittalKey.EmergencyRoomOnly]?: string;
  [SubmittalKey.MedicalFacilityName]?: string;
  [SubmittalKey.MedicalFacilityAddress]?: string;
  [SubmittalKey.MedicalFacilityCity]?: string;
  [SubmittalKey.MedicalFacilityState]?: string;
  [SubmittalKey.MedicalFacilityCountry]?: string;
  [SubmittalKey.MedicalFacilityZipCode]?: string;

  [SubmittalKey.EmployerNAICS]?: string;
  [SubmittalKey.HazardousAct]?: string;
  [SubmittalKey.HazardousCondition]?: string;
  [SubmittalKey.Event]?: string;
  [SubmittalKey.Activity]?: string;
  [SubmittalKey.MultipleBodyParts]?: string;
  [SubmittalKey.AOS]?: string;
  [SubmittalKey.IIFactor]?: string;
  [SubmittalKey.PartOfBody]?: string;
  [SubmittalKey.PartOfBodyCode]?: string;
  [SubmittalKey.SpecialFundCode]?: string;
  [SubmittalKey.IIType]?: string;
  [SubmittalKey.CountryOfInjuryCode]?: string;
  [SubmittalKey.Task]?: string;
  [SubmittalKey.NatureOfII]?: string;

  [SubmittalKey.AdjusterSubmittalBatchType]?: string;
  [SubmittalKey.AdjusterSubmittalBatchNumber]?: string;
  [SubmittalKey.AdjusterRefNoForCarrierClaim]?: string;
}

export enum CurrentServicingLocation {
  Kauai = 'Kauai',
  Hilo = 'Hilo',
  Kona = 'Kona',
  Maui = 'Maui',
  Oahu = 'Oahu',
}
