import { formatRoundUpDecimal } from 'src/utils';
import { toAuditData } from '../helpers';
import { AcceptRejectAction, TDI62Key } from './keys';

export const toTDI62s = (data) => ({
  ...data,
  data: data.data.map((tdi62) => ({
    ...tdi62,
    [TDI62Key.EmployerName]: tdi62?.employer?.accountName,
    [TDI62Key.EmployerId]: tdi62?.employer?.id,
    [TDI62Key.InsuranceCarrierName]: tdi62?.insuranceCarrier?.accountName,
    [TDI62Key.InsuranceCarrierId]: tdi62?.insuranceCarrier?.id,
    [TDI62Key.TDIPlanNumber]: tdi62?.tdiPlanType?.tdiPlanNumber,
    [TDI62Key.TDIPlanId]: tdi62?.tdiPlanType?.id,
    [TDI62Key.ActionTaken]: tdi62?.tdiCoverage?.actionTaken,
    [TDI62Key.IsAccepted]: !!tdi62?.isAccepted,
    [TDI62Key.IsRejected]: !!tdi62?.isRejected,
    [TDI62Key.WeeklyBenefit]: formatRoundUpDecimal(tdi62?.rawWeeklyBenefit, 2),
    ...toAuditData(tdi62),
  })),
});

export const toTDI62Detail = (data) => ({
  ...data,
  [TDI62Key.EmployerName]: data?.employer?.accountName,
  [TDI62Key.WeeklyBenefit]: formatRoundUpDecimal(data?.rawWeeklyBenefit, 2),
  [TDI62Key.InsuranceCarrierName]: data?.insuranceCarrier?.accountName,
  [TDI62Key.IsAccepted]: data?.actionStatus === AcceptRejectAction.Accepted,
  [TDI62Key.IsRejected]: data?.actionStatus === AcceptRejectAction.Rejected,
  [TDI62Key.CarrierId]: data?.insuranceCarrier?.carrierId,
  ...toAuditData(data),
});
