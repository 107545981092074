import { AccountRecordType } from '../Contacts';
import { TDIPlan } from '../TdiPlans';
import { RelatedContact } from '../types';

export enum Sex {
  Male = 'MALE',
  Female = 'FEMALE',
}

export enum AccountKey {
  Account = 'accountName',
  Type = 'accountType',
  AccountRecordType = 'recordType',
  CreatedBy = 'createdByName',
  LastModifiedBy = 'updatedByName',
  LastModifiedDate = 'updatedAt',
  AccountOwner = 'ownerFullname',
  Email = 'email',
  Parent = 'parentAccount',
  ParentName = 'parentName',
  Street = 'street',
  City = 'city',
  State = 'state',
  ZipCode = 'zip',
  Country = 'country',
  County = 'county',
  District = 'district',
  IslandCode = 'islandCode',
  Phone = 'phone',
  AlternativePhone = 'alternativePhone',
  Fax = 'fax',
  Website = 'website',
  NatureBusiness = 'natureOfBusiness',
  Industry = 'industry',
  Employees = 'employees',
  AdjusterId = 'adjusterId',
  DBAName = 'dbaName',
  LaborNumber = 'laborNumber',
  DCISUiRegistration = 'dcisUiRegistration',
  DCISDbaName = 'dcisDbaName',
  RelationDeceased = 'relationDeceased',
  DOLNumberEffectiveDate = 'dolNumberEffectiveDate',
  DCDStatus = 'dcdStatus',
  DCDStatusDate = 'dcdStatusDate',
  StatusDate = 'statusDate',
  Ownership = 'ownership',
  NAICS = 'naics',
  OrganizationType = 'orgType',
  IsMultipleIndividual = 'isMultipleIndividual',
  AvgAnnualEmployerLv = 'avgAnnualEmployerLv',
  NumOfEmployees = 'numOfEmployees',
  NumOfHawaiiEmployees = 'numOfHawaiiEmployees',
  SuccessorDolNumber = 'successorDolNumber',
  PredecessorDolNumber = 'predecessorDolNumber',
  SubsidiaryDolNumber = 'subsidiaryDolNumber',
  IsPEO = 'isPEO',
  DCDLiabilityDate = 'dcdLiabilityDate',
  NumberPEO = 'numberPEO',
  RegistrationDate = 'registrationDate',
  ExpirationDate = 'expirationDate',
  GeneralExcise = 'generalExcise',
  MailStatus = 'mailStatus',
  CarrierId = 'carrierId',
  IsCaptiveCarrier = 'isCaptiveCarrier',
  IsProblemCarrier = 'isProblemCarrier',
  ProblemCarrierNote = 'problemCarrierNote',
  TDIClaimService = 'tdiClaimService',
  TDIClaimServiceAccount = 'tdiClaimServiceAccount',
  WCClaimService = 'wcClaimService',
  WCClaimServiceAccount = 'wcClaimServiceAccount',
  InsuranceTypes = 'insuranceTypes',
  VendorNumber = 'vendorNumber',
  VendorType = 'vendorType',
  VendorDepartmentCode = 'vendorDepartmentCode',
  VendorMaintenanceDate = 'vendorMaintenanceDate',
  IsConcurrentEmployer = 'isConcurrentEmployer',
  IsDelinquentEmployer = 'isDelinquentEmployer',
  IsVendorConversation = 'isVendorConversation',
  IsRedTag = 'isRedTag',
  Salutation = 'salutation',
  FirstName = 'firstName',
  LastName = 'lastName',
  MiddleName = 'middleInitial',
  Suffix = 'suffix',
  Title = 'title',
  Gender = 'gender',
  DateOfBirth = 'dateOfBirth',
  DateOfDeath = 'dateOfDeath',
  IsDeceased = 'isDeceased',
  DCDId = 'dcdId',
  SSN = 'ssn',
  Passport = 'passport',
  MaritalStatus = 'maritalStatus',
  IsQualifyStudent = 'isQualifyStudent',
  IsNonSelfSufficient = 'isNonSelfSufficient',
  IsWhollyDependent = 'isWholyDependent',
  EffectiveDate = 'effectiveDate',
  Occupation = 'occupation',
  EmployerId = 'employerId',
  ParentId = 'parentId',
  Address = 'address',
  ShortAddress = 'shortAddress',
  TDIAccountId = 'tdiAccountId',
  WCAccountId = 'wcAccountId',
  Status = 'status',

  UIName = 'uiName',
  UIAddress1 = 'uiAddress1',
  UIAddress2 = 'uiAddress2',
  UICity = 'uiCity',
  UIState = 'uiState',
  UIZipCode = 'uiZipCode',
  NCCIAliasName = 'ncciAliasName',
  SICCode = 'sicCode',
  IsCoveredByPEO = 'isCoveredByPEO',
  TerminateDate = 'terminateDate',
  Fein = 'fein',
  UIIDNumber = 'uiIDNumber',
  UIStatus = 'uiStatus',
  UIStatusDate = 'uiStatusDate',
  UILiableDate = 'uiLiableDate',
  DC3ReceivedDate = 'dc3ReceivedDate',
  NumOfCoveredNonUnionEmployees = 'numOfCoveredNonUnionEmployees',
  ExemptionReason = 'exemptionReason',
  NumOfCoveredEmployees = 'numOfCoveredEmployees',
}

export enum AccountHierarchyKey {
  Id = 'id',
  Account = 'accountName',
  State = 'state',
  Phone = 'phone',
  AccountType = 'accountType',
  OwnerFullname = 'ownerFullname',
}

export enum AccountType {
  Adjuster = 'ADJUSTING_COMPANY',
  Agent = 'AGENT',
  Association = 'ASSOCIATION',
  Employer = 'EMPLOYER',
  InsuranceCarrier = 'INSURANCE_CARRIER',
  Provider = 'PROVIDER',
  Vendor = 'VENDOR',
  Claimant = 'CLAIMANT',
  Complainant = 'COMPLAINANT',
  Dependent = 'DEPENDENT',
  Representative = 'REPRESENTATIVE',
  VocationalRehabCounselor = 'VOCATIONAL_REHAB_COUNSELOR',
  VocationalRehabProvider = 'VOCATIONAL_REHAB_PROVIDER',
}

export const accountTypeOptions = [
  {
    value: AccountType.Adjuster,
    label: 'Adjusting Company',
  },
  {
    value: AccountType.Agent,
    label: 'Agent',
  },
  {
    value: AccountType.Association,
    label: 'Association',
  },
  {
    value: AccountType.Employer,
    label: 'Employer',
  },
  {
    value: AccountType.InsuranceCarrier,
    label: 'Insurance Carrier',
  },
  {
    value: AccountType.Provider,
    label: 'Provider',
  },
  {
    value: AccountType.Vendor,
    label: 'Vendor',
  },
  {
    value: AccountType.Claimant,
    label: 'Claimant',
  },
  {
    value: AccountType.Complainant,
    label: 'Complainant',
  },
  {
    value: AccountType.Dependent,
    label: 'Dependent',
  },
  {
    value: AccountType.Representative,
    label: 'Representative',
  },
  {
    value: AccountType.VocationalRehabCounselor,
    label: 'Vocational Rehab Counselor',
  },
  {
    value: AccountType.VocationalRehabProvider,
    label: 'Vocational Rehab Provider',
  },
];

export const recordTypeOptions = [
  {
    value: AccountRecordType.Organization,
    label: 'Organization',
  },
  {
    value: AccountRecordType.Individual,
    label: 'Individual',
  },
];

export enum DCDStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  ACT = 'ACT',
  IN = 'IN',
  APP = 'APP',
  TER = 'TER',
  REV = 'REV',
  DDEC = 'DDEC',
  SUS = 'SUS',
}

export enum OrgType {
  Corporation = 'CORPORATION',
  Individual = 'INDIVIDUAL',
  LLC = 'LLC',
  Partnership = 'PARTNERSHIP',
}

export enum VendorType {
  Employee = 'EMPLOYEE',
  Individual = 'INDIVIDUAL',
  Other = 'OTHER',
}

export enum GenderType {
  Male = 'MALE',
  Female = 'FEMALE ',
}

export enum MaritalStatus {
  Single = 'SINGLE',
  Married = 'MARRIED',
  Separated = 'SEPARATED',
  Divorced = 'DIVORCED',
}

export interface Account {
  [AccountKey.Account]: string;
  [AccountKey.Type]: string;
  [AccountKey.AccountRecordType]: AccountRecordType;
  [AccountKey.AccountOwner]: string;
  [AccountKey.CreatedBy]: string;
  [AccountKey.LastModifiedBy]: string;
  [AccountKey.LastModifiedDate]: string;
  [AccountKey.Email]?: string;
  id: string;
  [AccountKey.LaborNumber]?: string;
  [AccountKey.CarrierId]?: string;
  [AccountKey.VendorNumber]?: string;
  [AccountKey.DCDId]?: string;
  [AccountKey.SSN]?: string;
  [AccountKey.DCDStatus]?: string;
  [AccountKey.IsCaptiveCarrier]: boolean;
  [AccountKey.NumOfHawaiiEmployees]: number;
  [AccountKey.IsPEO]: boolean;
  [AccountKey.TDIClaimServiceAccount]?: AccountDetail;
  [AccountKey.WCClaimServiceAccount]?: AccountDetail;
}

export interface AccountDetail {
  id: string;
  recordType: AccountRecordType | null;
  accountName: string;
  accountUserName: string;
  accountType: AccountType;
  createdBy: string | null;
  fein: string | null;
  createdByName: string | null;
  createdAt: Date;
  updatedBy: string | null;
  updatedAt: Date | null;
  updatedByName: string | null;
  parentId: string | null;
  ownerUserId: string | null;
  ownerFullname: string | null;
  address: string | null;
  street: string | null;
  city: string | null;
  state: string | null;
  zipcode: string | null;
  country: string | null;
  county: string | null;
  district: string | null;
  islandCode: string | null;
  status: DCDStatus | null;
  phone: string | null;
  fax: string | null;
  email: string | null;
  website: string | null;
  natureOfBusiness: string | null;
  industry: string | null;
  employees: string | null;
  adjusterId: string | null;
  dbaName: string | null;
  laborNumber: string | null;
  dcisUiRegistration: string | null;
  dcisDbaName: string | null;
  relationDeceased: string | null;
  dolNumberEffectiveDate: Date | null;
  dcdStatus: DCDStatus | null;
  dcdStatusDate: string;
  ownership: string | null;
  naics: string | null;
  orgType: OrgType | null;
  isMultipleIndividual: boolean;
  isNotified?: boolean;
  avgAnnualEmployerLv: string | null;
  numOfEmployees: number | null;
  numOfHawaiiEmployees: number | null;
  successorDolNumber: string | null;
  predecessorDolNumber: string | null;
  subsidiaryDolNumber: string | null;
  isPEO: boolean | null;
  numberPEO: string | null;
  registrationDate: Date | null;
  expirationDate: Date | null;
  generalExcise: string | null;
  mailStatus: string | null;
  carrierId: string | null;
  isCaptiveCarrier: boolean;
  isProblemCarrier: boolean;
  problemCarrierNote: string | null;
  tdiClaimService: string | null;
  wcClaimService: string | null;
  insuranceTypes: string;
  vendorNumber: string | null;
  vendorType: VendorType | null;
  vendorDepartmentCode: string | null;
  vendorMaintenanceDate: Date | null;
  isConcurrentEmployer: boolean;
  isDelinquentEmployer: boolean;
  isVendorConversation: boolean;
  isRedTag: boolean;
  salutation: string | null;
  firstName: string | null;
  lastName: string | null;
  middleInitial: string | null;
  suffix: string | null;
  title: string | null;
  gender: GenderType | null;
  dateOfBirth: Date | null;
  dateOfDeath: Date | null;
  isDeceased: boolean;
  dcdId: string | null;
  ssn: string | null;
  passport: string | null;
  martialStatus: MaritalStatus | null;
  isQualifyStudent: boolean;
  isNonSelfSufficient: boolean;
  isWholyDependent: boolean;
  effectiveDate: Date | null;
  occupation: string | null;
  employerId: string | null;
  contacts: RelatedContact[];
  accountContact: AccountContact[];
  insuranceTDIPlans?: TDIPlan[];
  shortAddress?: string;
  zip: string | null;
  _count: {
    contacts: number;
    accountContact: number;
  };
  [AccountKey.Parent]?: AccountDetail;
  [AccountKey.ParentName]?: string;
  [AccountKey.UIStatus]?: string;
  [AccountKey.UIStatusDate]?: Date;
  [AccountKey.UILiableDate]?: Date;
  [AccountKey.UIName]?: string;
  [AccountKey.UIAddress1]?: string;
  [AccountKey.UIAddress2]?: string;
  [AccountKey.UICity]?: string;
  [AccountKey.UIState]?: string;
  [AccountKey.UIZipCode]?: string;
  [AccountKey.DC3ReceivedDate]?: Date;
  [AccountKey.NumOfCoveredNonUnionEmployees]?: number;
  [AccountKey.ExemptionReason]?: string;
  [AccountKey.NumOfCoveredEmployees]?: number;
}

export interface AccountHierarchy {
  id: string;
  parentId: string;
  accountName: string;
  state: string;
  phone: string;
  accountType: string;
  ownerFullname: string;
  ownerUserId: string;
  isCurrent: boolean;
  depth: number;
  path: string[];
  status: {
    isLastChild: boolean;
    isAcctive: boolean;
  };
}

export interface AccountContact {
  id: string;
  subType: string | null;
  insuranceType: string | null;
  createdAt: Date;
  updatedAt: Date;
  createdByName: string;
  updatedByName: string;
  contact: {
    id: string;
    salutation: string;
    middleInitial: string;
    firstName: string;
    lastName: string;
    suffix: string;
    street: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    county: string;
    district: string;
    islandCode: string;
  };
}
