import { UploadKey } from 'src/containers/DCDCaseManagement/DCDCaseDetail/DCDTabs/Upload/helpers';
import { formatCaseNumber, getFirstLastName, getStartCase } from 'src/utils';
import { PaginationResponseType, toAddress } from '../helpers';
import { DCDCaseDetailKey, DCDCaseKey } from './types';

export type UploadFilePayload = {
  url: string;
  fileData: File;
};

export type UpLoadFormValue = {
  //Document Information
  [UploadKey.Id]?: string;
  [UploadKey.WCCaseId]?: string;
  [UploadKey.Insurance]?: string;
  [UploadKey.DocumentCategory]?: string;
  [UploadKey.DateOfInjury]?: Date;
  [UploadKey.FillingParty]?: string;
  [UploadKey.DCDFillingDate]?: Date;
  [UploadKey.DocumentType]?: DocumentType;
  [UploadKey.DateDocumentReceived]?: Date | string;
  [UploadKey.DocumentDate]?: Date | string;
  [UploadKey.DocumentDescription]?: string;
  [UploadKey.Attachment]?: string;
  [UploadKey.TDICaseId]?: string;
  [UploadKey.TDISFCaseId]?: string;
  [UploadKey.DecisionId]?: string;

  //Notification Information
  [UploadKey.NotificationRecipientType]?: string;
  [UploadKey.RecipientUsers]?: string[];
  [UploadKey.RecipientProfiles]?: string[];
  [UploadKey.Size]?: Number;
  [UploadKey.DocumentName]?: string;
  [UploadKey.AttachmentURL]?: string;
};

const concatName = (firstName, lastName) => {
  return [firstName, lastName].filter((item) => item).join(' ');
};

export const toDCDCaseData = (data) => {
  const dcdLiaison = data?.dcdLiaison || {};
  const dcdLiaisonName = concatName(dcdLiaison?.firstName, dcdLiaison?.lastName);

  const caseAdmin = data?.caseAdmin || {};
  const caseAdminName = concatName(caseAdmin?.firstName, caseAdmin?.lastName);

  const updatedUser = data?.updatedUser || {};
  const updatedUserName = concatName(updatedUser?.firstName, updatedUser?.lastName);

  const createdUser = data?.createdUser || {};
  const createdUserName = concatName(createdUser?.firstName, createdUser?.lastName);

  return {
    ...data,
    claimantName: data?.claimant?.accountName || '',
    employerName: data?.employer?.accountName || '',
    caseNumberFormat: formatCaseNumber(data?.caseNumber),
    dcdLiaisonName: dcdLiaisonName,
    caseAdminName: caseAdminName,
    [DCDCaseDetailKey.CreatedByName]: createdUserName,
    [DCDCaseDetailKey.UpdatedByName]: updatedUserName,
    [DCDCaseDetailKey.MinimumWCR]: data?.[DCDCaseDetailKey.MinimumWCR]?.toString(),
    [DCDCaseDetailKey.MaximumWCR]: data?.[DCDCaseDetailKey.MaximumWCR]?.toString(),
    [DCDCaseDetailKey.LapsedTime]: data?.recentWc1?.lapsedTimeHours,
    [DCDCaseDetailKey.MostRecentWCId]: data?.recentWc1?.id,
    [DCDCaseDetailKey.MostRecentWC]: data?.recentWc1?.formNumber,
    [DCDCaseDetailKey.Wc1SignatureDate]: data?.recentWc1?.signatureDate,
    [DCDCaseDetailKey.MostRecentWC1Date]: data?.recentWc1?.rawDateReceived,
    [DCDCaseDetailKey.MostRecentWC5Date]: data?.recentWc1?.wc5ReceivedDate,
    [DCDCaseDetailKey.ClaimantSocialSecurityNumber]: data?.[DCDCaseDetailKey.Claimant]?.ssn,
    [DCDCaseDetailKey.ClaimantDateOfDeath]: data?.[DCDCaseDetailKey.Claimant]?.dateOfDeath,
    [DCDCaseDetailKey.ClaimantPassportNumber]: data?.[DCDCaseDetailKey.Claimant]?.passport,
    [DCDCaseDetailKey.DateDisabilityBegan]: data?.recentWc1?.dateDisabilityBegan,
    [DCDCaseDetailKey.IfEmployee]: data?.recentWc1?.dateBackToWork,
    [DCDCaseDetailKey.EmployeePaid]: data?.recentWc1?.isEmployeePaidForFullDayOfInjury,
    [DCDCaseDetailKey.EmployeeFurnished]: data?.recentWc1?.rawWasEmployeeFurnishesMealsTipsLodging,
    [DCDCaseDetailKey.Prefabricated]: data?.recentWc1?.prefabricatedFrom,
    [DCDCaseDetailKey.Occupation]: data?.recentWc1?.occupation,
    [DCDCaseDetailKey.FullTime]: getStartCase(data?.recentWc1?.fulltimeParttime),
    [DCDCaseDetailKey.YearsEmployed]: data?.recentWc1?.yearEmployed?.toString(),
    [DCDCaseDetailKey.HourlyWage]: data?.recentWc1?.hourlyWage?.toString(),
    [DCDCaseDetailKey.AverageWeeklyWage]: data?.recentWc1?.avgWeeklyWage?.toString(),
    [DCDCaseDetailKey.MonthlySalary]: data?.recentWc1?.monthlySalary?.toString(),
    [DCDCaseDetailKey.HrsWorkedWeek]: data?.recentWc1?.hoursWorkWeek?.toString(),
    [DCDCaseDetailKey.WeighingFactor]: data?.recentWc1?.weightingFactor?.toString(),
    [DCDCaseDetailKey.AdjusterCaseNumber]: data?.recentWc1?.adjusterClaimNumber,
    [DCDCaseDetailKey.MedicalDeductibleFlag]: data?.recentWc1?.medicalDeductibleFlag,
    [DCDCaseDetailKey.IsLiabilityDenied]: data?.recentWc1?.isLiabilityDenied,
    [DCDCaseDetailKey.CarrierCaseNumber]: data?.recentWc1?.carrierClaimNumber,
    [DCDCaseDetailKey.MedicalDeductibleAmount]: data?.recentWc1?.medicalDeductible,
    [DCDCaseDetailKey.TotalBenefitsAmountPaid]: data?.recentWc1?.totalBenefitAmountPaid,
    [DCDCaseDetailKey.Task]: data?.recentWc1?.injuryTask,
    [DCDCaseDetailKey.Activity]: data?.recentWc1?.injuryActivity,
    [DCDCaseDetailKey.InjuryFactor]: data?.recentWc1?.injuryFactor,
    [DCDCaseDetailKey.Aos]: data?.recentWc1?.aos,
    [DCDCaseDetailKey.SourceInjury]: data?.recentWc1?.sourceInjury,
    [DCDCaseDetailKey.MultipleBody]: data?.recentWc1?.isMultipleBodyParts,
    [DCDCaseDetailKey.OnEmployerPremise]: data?.recentWc1?.onEmployerPremise,
    [DCDCaseDetailKey.IfNotOnPremise]: data?.recentWc1?.whereInjuryOccur,
    [DCDCaseDetailKey.PossibilityOfBurn]: data?.recentWc1?.possibilityBurn,
    [DCDCaseDetailKey.PossibilityOfDisfigurement]: data?.recentWc1?.possibilityDisfigurement,
    [DCDCaseDetailKey.NatureOfInjury]: data?.recentWc1?.natureOfInjury,
    [DCDCaseDetailKey.ClaimantPhone]: data?.claimant?.phone || '',
    [DCDCaseDetailKey.ClaimantSSN]: data?.claimant?.ssn || '',
    [DCDCaseDetailKey.ClaimantDOB]: data?.claimant?.dateOfBirth || '',
    [DCDCaseDetailKey.ClaimantAddress]: toAddress(data?.claimant) || '',

    [DCDCaseDetailKey.EmployerState]: data?.employer?.state || '',
    [DCDCaseDetailKey.EmployerCity]: data?.employer?.city || '',
    [DCDCaseDetailKey.EmployerZip]: data?.employer?.zip || '',
    [DCDCaseDetailKey.EmployerStreet]: data?.employer?.street || '',

    // SCF
    [DCDCaseDetailKey.SCFNumberOfWeeks]: data?.scfNumberOfWeeks
      ? Number(data?.scfNumberOfWeeks).toFixed(4)
      : '',
    [DCDCaseDetailKey.SCFDailyBenefitAmount]: data?.scfDailyBenefitAmount
      ? Number(data?.scfDailyBenefitAmount).toFixed(2)
      : '',
    [DCDCaseDetailKey.SCFMonthlyBenefitAmount]: data?.scfDailyBenefitAmount
      ? Number(data?.scfMonthlyBenefitAmount).toFixed(2)
      : '',
    [DCDCaseDetailKey.EmployerLaborNumber]: data?.employer?.laborNumber || '',
    [DCDCaseDetailKey.EmployerNumOfHawaiiEmployees]: data?.employer?.numOfHawaiiEmployees || '',

    [DCDCaseDetailKey.RecentWC1Insurance]: data?.recentWc1?.insuranceCarrier?.accountName || '',
    [DCDCaseDetailKey.RecentWC1AC]: data?.recentWc1?.adjustingCompany?.accountName || '',
  };
};

export const toDCDCases = (data: PaginationResponseType<any>): PaginationResponseType<any> => ({
  ...data,
  data: data.data.map((dcdCase) => ({
    ...dcdCase,
    [DCDCaseKey.LapsedTime]: dcdCase?.recentWc1?.lapsedTimeHours,
    [DCDCaseKey.MostRecentWC1]: dcdCase?.recentWc1?.formNumber,
    [DCDCaseKey.WC1SignatureDate]: dcdCase?.recentWc1?.signatureDate,
    [DCDCaseKey.MostRecentWC1ReceivedDate]: dcdCase?.recentWc1?.rawDateReceived,
    [DCDCaseKey.MostRecentWC5ReceivedDate]: dcdCase?.recentWc1?.wc5ReceivedDate,
    [DCDCaseKey.DCDCaseLiaison]: dcdCase?.dcdLiaison
      ? `${dcdCase?.dcdLiaison?.firstName} ${dcdCase?.dcdLiaison?.lastName}`
      : '',
    [DCDCaseKey.ClaimantSocialSecurityNumber]: dcdCase?.claimant?.ssn,
    [DCDCaseKey.ClaimantPassportNumber]: dcdCase?.claimant?.passport,
    [DCDCaseKey.DateDisabilityBegan]: dcdCase?.recentWc1?.dateDisabilityBegan,
    [DCDCaseKey.DateBackToWork]: dcdCase?.recentWc1?.dateBackToWork,
    [DCDCaseKey.EmployeePaidInFullForDayOfII]: dcdCase?.recentWc1?.isEmployeePaidForFullDayOfInjury,
    [DCDCaseKey.Prefabricated]: dcdCase?.recentWc1?.prefabricatedFrom,
    [DCDCaseKey.Occupation]: dcdCase?.recentWc1?.occupation,
    [DCDCaseKey.FullTimePartTime]: dcdCase?.recentWc1?.fulltimeParttime,
    [DCDCaseKey.YearsEmployed]: dcdCase?.recentWc1?.yearEmployed,
    [DCDCaseKey.HourlyWage]: dcdCase?.recentWc1?.hourlyWage,
    [DCDCaseKey.AverageWeeklyWage]: dcdCase?.recentWc1?.avgWeeklyWage,
    [DCDCaseKey.MonthlySalary]: dcdCase?.recentWc1?.monthlySalary,
    [DCDCaseKey.HRSWorkedWeek]: dcdCase?.recentWc1?.hoursWorkWeek,
    [DCDCaseKey.WeighingFactor]: dcdCase?.recentWc1?.weightingFactor,
    [DCDCaseKey.AdjusterCaseNumber]: dcdCase?.recentWc1?.adjusterClaimNumber,
    [DCDCaseKey.MedicalDeductibleFlag]: dcdCase?.recentWc1?.medicalDeductibleFlag,
    [DCDCaseKey.IsLiabilityDenied]: dcdCase?.recentWc1?.isLiabilityDenied,
    [DCDCaseKey.MedicalDeductibleAmount]: dcdCase?.recentWc1?.medicalDeductible,
    [DCDCaseKey.TotalBenefitsAmountPaid]: dcdCase?.recentWc1?.totalBenefitAmountPaid,
    [DCDCaseKey.Task]: dcdCase?.recentWc1?.injuryTask,
    [DCDCaseKey.Activity]: dcdCase?.recentWc1?.injuryActivity,
    [DCDCaseKey.InjuryIllnessFactor]: dcdCase?.recentWc1?.injuryFactor,
    [DCDCaseKey.MultipleBodyParts]: dcdCase?.recentWc1?.isMultipleBodyParts,
    [DCDCaseKey.AOS]: dcdCase?.recentWc1?.aos,
    [DCDCaseKey.SourceOfInjuryIllness]: dcdCase?.recentWc1?.sourceInjury,
    [DCDCaseKey.OnEmployerPremise]: dcdCase?.recentWc1?.onEmployerPremise,
    [DCDCaseKey.WhereDidIIOccur]: dcdCase?.recentWc1?.whereInjuryOccur,
    [DCDCaseKey.PossibilityOfBurn]: dcdCase?.recentWc1?.possibilityBurn,
    [DCDCaseKey.PossibilityOfDisfigurement]: dcdCase?.recentWc1?.possibilityDisfigurement,
    [DCDCaseKey.CaseAdministrator]: getFirstLastName(dcdCase?.caseAdmin),
    [DCDCaseKey.CreatedBy]: `${dcdCase?.createdUser?.firstName} ${dcdCase?.createdUser?.lastName}`,
    [DCDCaseKey.UpdatedBy]:
      dcdCase?.updatedUser &&
      `${dcdCase?.updatedUser?.firstName} ${dcdCase?.updatedUser?.lastName}`,
    [DCDCaseDetailKey.SCFNumberOfWeeks]: dcdCase?.scfNumberOfWeeks
      ? Number(dcdCase?.scfNumberOfWeeks).toFixed(4)
      : '',
  })),
});
