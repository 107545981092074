export enum RelatedInjuryIllnessKey {
  Id = 'id',
  BodyPartId = 'id',
  BodyPart = 'partOfBody',
  SideLeftRight = 'leftRightSide',
  SideFrontBack = 'frontBackSide',
  Disfigurement = 'disfigurement',
  Burn = 'burn',
  CreatedBy = 'createdUser',
  CreatedAt = 'createdAt',
  LastModifiedBy = 'updatedUser',
  LastModifiedAt = 'updatedAt',
  DCDCase = 'caseId',
  FullName = 'fullName',
  FirstName = 'firstName',
  LastName = 'lastName',
  FormNumber = 'formNumber',
}

export enum WCType {
  Case = 'case',
  WC1 = 'wc1',
  WC2 = 'wc2',
}

interface CreatedUser {
  [RelatedInjuryIllnessKey.Id]: string;
  [RelatedInjuryIllnessKey.FullName]: string;
  [RelatedInjuryIllnessKey.FirstName]: string;
  [RelatedInjuryIllnessKey.LastName]: string;
}

interface UpdatedUser {
  [RelatedInjuryIllnessKey.Id]: string;
  [RelatedInjuryIllnessKey.FullName]: string;
  [RelatedInjuryIllnessKey.FirstName]: string;
  [RelatedInjuryIllnessKey.LastName]: string;
}

export interface RelatedInjuryIllnessType {
  [RelatedInjuryIllnessKey.Id]?: string;
  [RelatedInjuryIllnessKey.BodyPartId]?: string;
  [RelatedInjuryIllnessKey.BodyPart]?: string;
  [RelatedInjuryIllnessKey.SideLeftRight]?: string;
  [RelatedInjuryIllnessKey.Disfigurement]?: boolean;
  [RelatedInjuryIllnessKey.Burn]?: boolean;
  [RelatedInjuryIllnessKey.CreatedBy]?: CreatedUser;
  [RelatedInjuryIllnessKey.LastModifiedBy]?: UpdatedUser;
  [RelatedInjuryIllnessKey.CreatedAt]?: string;
  [RelatedInjuryIllnessKey.LastModifiedAt]?: string;
  [RelatedInjuryIllnessKey.FormNumber]?: string;
  [RelatedInjuryIllnessKey.DCDCase]: string;
}
