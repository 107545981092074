import { RelatedInjuryIllnessKey } from 'src/queries/RelatedInjuryIllness/types';

export enum SubmitMode {
  Submit = 'Submit',
  SubmitAndNew = 'SubmitAndNew',
}

export const initialValues = (data?: {}) => {
  return {
    [RelatedInjuryIllnessKey.BodyPart]: data?.[RelatedInjuryIllnessKey.BodyPart] || null,
    [RelatedInjuryIllnessKey.SideFrontBack]: data?.[RelatedInjuryIllnessKey.SideFrontBack] || null,
    [RelatedInjuryIllnessKey.SideLeftRight]: data?.[RelatedInjuryIllnessKey.SideLeftRight] || null,
    [RelatedInjuryIllnessKey.Disfigurement]: data?.[RelatedInjuryIllnessKey.Disfigurement],
    [RelatedInjuryIllnessKey.Burn]: data?.[RelatedInjuryIllnessKey.Burn],
    [RelatedInjuryIllnessKey.DCDCase]: data?.[RelatedInjuryIllnessKey.DCDCase] || null,
    [RelatedInjuryIllnessKey.FormNumber]: data?.[RelatedInjuryIllnessKey.FormNumber] || null,
  };
};

export enum SideLeftRightKey {
  Left = 'Left',
  Right = 'Right',
}
export enum FrontBackKey {
  Front = 'Front',
  Back = 'Back',
}

export enum BodyPartKey {
  Arm = 'Arm',
  Hand = 'Hand',
  Ear = 'Ear',
  Ears = 'Ears',
  HearingLoss = 'Loss of hearing',
  Eye = 'Eye',
  EyeEnucleation = 'Eye - (enucleation)',
  Toes = 'First, second, third, fourth toes',
  GreatToe = 'Great toe',
  IndexFinger = 'Index finger',
  LittleFinger = 'Little finger',
  MiddleFinger = 'Middle finger',
  RingFinger = 'Ring finger',
  Thumb = 'Thumb',
  Foot = 'Foot',
  Leg = 'Leg',
  Other = 'Other',
}

export const sideLeftRightTypeOptions = Object.keys(SideLeftRightKey).map((key) => ({
  value: SideLeftRightKey[key as string],
  label: SideLeftRightKey[key as string],
}));

export const sideFrontBackTypeOptions = Object.keys(FrontBackKey).map((key) => ({
  value: FrontBackKey[key as string],
  label: FrontBackKey[key as string],
}));
export const sideBodyPartTypeOptions = Object.keys(BodyPartKey).map((key) => ({
  value: BodyPartKey[key as string],
  label: BodyPartKey[key as string],
}));

export const convertBooleanToYesNo = (boolean: boolean) => {
  if (boolean === true) {
    return 'Yes';
  } else if (boolean === false) {
    return 'No';
  } else {
    return null;
  }
};
