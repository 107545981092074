/* eslint-disable security/detect-object-injection */
import { arraySortByAnotherArray, getStartCase } from 'src/utils';
import { PaginationResponseType, toAddress, toAuditData } from '../helpers';
import { BenefitPaymentSummaryKey, GrandTotalAndList } from './BenefitPaymentSummaryType';
import { WC3Interface } from './WC3Interface';
import { WC3Key } from './keys';
import { BenefitPaymentType } from './types';

export const orderListByType = [
  BenefitPaymentType.TTD,
  BenefitPaymentType.TPD,
  BenefitPaymentType.PTD,
  BenefitPaymentType.PPD,
  BenefitPaymentType.Death,
  BenefitPaymentType.CityCounty,
  BenefitPaymentType.Disfigurement,
  BenefitPaymentType.Medical,
  BenefitPaymentType.Materials,
  BenefitPaymentType.ServicesOfAttendant,
  BenefitPaymentType.Rehabilitation,
  BenefitPaymentType.BenefitReimbursement,
  BenefitPaymentType.BenefitAdjustment,
  BenefitPaymentType.Other,
];

export const toWC3Detail = (data) => ({
  ...data,
  [WC3Key.RawClaimantSSN]: data?.rawClaimantIdNumber,
  [WC3Key.RawClaimant]: `${data?.rawClaimantFirstName} ${data?.rawClaimantMiddleInitial} ${data?.rawClaimantLastName}`,

  [WC3Key.EmployerPointOfContact]: data?.employerPointOfContact?.contactName,
  [WC3Key.Claimant]: data?.claimant?.accountName || '',
  [WC3Key.ClaimantId]: data?.claimant?.id,
  [WC3Key.ClaimantPhone]: data?.claimant?.phone,
  [WC3Key.ClaimantAddress]: toAddress(data?.claimant),
  [WC3Key.ClaimantEmail]: data?.claimant?.email,
  [WC3Key.ClaimantSSN]: data?.claimant?.ssn,
  [WC3Key.ClaimantDateOfInjury]: data?.claimant?.dateOfInjury,
  [WC3Key.ClaimantPassportNumber]: data?.claimant?.passport,
  [WC3Key.Employer]: data?.employer?.accountName,
  [WC3Key.EmployerDbaName]: data?.employer?.dbaName,
  [WC3Key.EmployerAddress]: toAddress(data?.employer),
  [WC3Key.EmployerDOL]: data?.employer?.laborNumber,
  [WC3Key.EmployerPOCPhone]: data?.employerPointOfContact?.phone,
  [WC3Key.EmployerPOCEmail]: data?.employerPointOfContact?.email,
  [WC3Key.InsuranceCarrier]: data?.insuranceCarrier?.accountName,
  [WC3Key.CarrierNumber]: data?.insuranceCarrier?.carrierId,
  [WC3Key.Adjuster]: data?.adjuster?.contactName,
  [WC3Key.AdjusterAddress]: data?.adjuster?.address,
  [WC3Key.AdjusterPhone]: data?.adjuster?.phone,
  [WC3Key.AdjusterIdNumber]: data?.adjuster?.dcdId,
  [WC3Key.AdjusterAddress]: toAddress(data?.adjuster),
  [WC3Key.AdjusterEmail]: data?.adjuster?.email,
  [WC3Key.CaseNumber]: data?.case?.caseNumber,
  ...toAuditData(data),
});

export const toWC3 = (data: PaginationResponseType<any>): PaginationResponseType<WC3Interface> => ({
  ...data,
  data: data.data.map((wc3) => ({
    ...wc3,
    [WC3Key.CaseNumber]: wc3?.case?.caseNumber,
    ...toAuditData(wc3),
  })),
});

export const toBenefitPaymentsSummaryTableData = (data: GrandTotalAndList): GrandTotalAndList => {
  const finalData = getDuplicateLabel(data?.data, BenefitPaymentSummaryKey.PaymentType);

  return {
    ...data,
    data: arraySortByAnotherArray(finalData, orderListByType, BenefitPaymentSummaryKey.PaymentType),
  };
};

export type RepeatType = {
  key: string;
  label: string;
};

export const getDuplicateLabel = (list: any[], key: string) => {
  const labelCounts = list.reduce((labelCount, item) => {
    const counter = labelCount[item[key]] || 0;
    return {
      ...labelCount,
      [item[key]]: counter + 1,
    };
  }, {});

  const listIndices = list.reduce((indices, item, index) => {
    if (labelCounts[item[key]] > 1) {
      return [...indices, index];
    }
    return indices;
  }, []);

  return list.map((item, index) => {
    const label = getStartCase(item[key]);
    if (listIndices.includes(index)) {
      const order = listIndices.length - labelCounts[item[key]]-- + 1;
      return {
        ...item,
        label: `${label} ${order}`,
      };
    }
    return {
      ...item,
      label: label,
    };
  });
};
