import { TDI21Key } from './keys';

export interface TDI21 {
  [TDI21Key.CalendarYear]?: string;
  [TDI21Key.Id]?: string;
  [TDI21Key.CalendarYear]?: string;
  [TDI21Key.TDI21Number]?: string;
  [TDI21Key.EmployerName]?: string;
  [TDI21Key.EmployerId]?: string;
  [TDI21Key.DOLNumber]?: string;
  [TDI21Key.DBAName]?: string;
  [TDI21Key.EmployerAddress]?: string;
  [TDI21Key.EmployerStreet]?: string;
  [TDI21Key.EmployerCity]?: string;
  [TDI21Key.EmployerState]?: string;
  [TDI21Key.EmployerZipCode]?: string;

  [TDI21Key.EmployerNameRelated]?: string;
  [TDI21Key.EmployerIdRelated]?: string;
  [TDI21Key.InsuranceNameRelated]?: string;
  [TDI21Key.InsuranceIdRelated]?: string;
  [TDI21Key.EmployerRelationshipId]?: string;
  [TDI21Key.InsuranceCarrierRelationshipId]?: string;

  [TDI21Key.TypeOfPlanBeingReported]?: TypeOfPlanBeingReported;
  [TDI21Key.ClassOfEmployeesCovered]?: string;
  [TDI21Key.NumberOfCoveredMaleEmployees]?: NumberOfCoveredEmployees[];
  [TDI21Key.NumberOfCoveredFemaleEmployees]?: NumberOfCoveredEmployees[];
  [TDI21Key.NumberOfCoveredEmployeesTable]?: NumberOfCoveredEmployees[];

  [TDI21Key.AmountOfWagesPaidToCoveredEmployees]?: number;
  [TDI21Key.TaxableWagesPaidToCoveredEmployees]?: number;
  [TDI21Key.AmountOfEmployerContributionPaid]?: number;
  [TDI21Key.AmountOfEmployeeContributionsWithheldFromCoveredEmployees]?: number;
  [TDI21Key.AmountOfBenefitPaidToMaleEmployees]?: number;
  [TDI21Key.NumberOfDifferentMaleEmployeesPaidDisabilityBenefits]?: number;
  [TDI21Key.NumberOfWeeksBenefitsPaidToMaleEmployees]?: number;
  [TDI21Key.NumberOfPeriodsBenefitsWerePaidToMaleEmployees]?: number;
  [TDI21Key.TotalNumberOfClaimsDeniedToMaleEmployees]?: number;
  [TDI21Key.AmountOfBenefitsPaidToFemaleEmployees]?: number;
  [TDI21Key.NumberOfDifferentFemaleEmployeesPaidDisabilityBenefits]?: number;
  [TDI21Key.NumberOfWeeksBenefitsPaidToFemaleEmployees]?: number;
  [TDI21Key.NumberOfPeriodsBenefitsPaidToFemaleEmployees]?: number;
  [TDI21Key.NumberOfClaimsDeniedToFemaleEmployees]?: number;
  [TDI21Key.AmountOfSubrogationPaymentsReceived]?: number;

  [TDI21Key.ReportingAgencyName]?: string;
  [TDI21Key.TDIReportingNumber]?: string;
  [TDI21Key.ReportStreet]?: string;
  [TDI21Key.ReportCity]?: string;
  [TDI21Key.ReportState]?: string;
  [TDI21Key.ReportZipCode]?: string;
  [TDI21Key.PointOfContactEmail]?: string;
  [TDI21Key.PointOfContactName]?: string;

  [TDI21Key.AssociationOrUnionName]?: string;
  [TDI21Key.AssociationTDIReportingNumber]: string;
  [TDI21Key.CoveredByAssociationOrUnionPlan]: boolean;

  [TDI21Key.DateSigned]?: string;
  [TDI21Key.PrintNameAndTitle]?: string;
  [TDI21Key.ContactPhoneNumber]?: string;

  // Audit
  [TDI21Key.CreatedBy]: string;
  [TDI21Key.CreatedAt]: string;
  [TDI21Key.CreatedUser]: string;
  [TDI21Key.UpdatedAt]: string;
  [TDI21Key.UpdatedBy]: string;
  [TDI21Key.UpdatedUser]: string;
}

export interface NumberOfCoveredEmployees {
  title: string;
  [TDI21Key.HeaderTooltip]: string;
  [TDI21Key.January]?: number;
  [TDI21Key.February]?: number;
  [TDI21Key.March]?: number;
  [TDI21Key.April]?: number;
  [TDI21Key.May]?: number;
  [TDI21Key.June]?: number;
  [TDI21Key.July]?: number;
  [TDI21Key.August]?: number;
  [TDI21Key.September]?: number;
  [TDI21Key.October]?: number;
  [TDI21Key.November]?: number;
  [TDI21Key.December]?: number;
}

export enum TypeOfPlanBeingReported {
  InsurancePlan = 'Insured Plan, Statutory',
  SelfInsurePlan = 'Self-Insured Plan',
  CollectiveBargainingPlan = 'Collective Bargaining Plan',
  InsurancePlanBetterThanStatutory = 'Insured Plan, Better Than Statutory',
}
