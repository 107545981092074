export enum VRProviderKey {
  Id = 'id',
  ProviderId = 'providerId',
  VRProcessId = 'vrProcessId',
  ProvidedServiceType = 'providedServiceType',
  Provider = 'provider',
  VRProviderNumber = 'vrProviderNumber',
}

export enum ProviderDetailKey {
  Id = 'id',
  AccountName = 'accountName',
}
