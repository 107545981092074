import { Accept } from 'react-dropzone';

export const ONE_HOUR = 60 * 60 * 1000;

export const COMMON_TYPE: Accept = {
  'image/png': ['.png'],
  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
  'application/csv': ['.csv'],
  'application/pdf': ['.pdf'],
  'application/doc': ['.doc'],
  'application/docx': ['.docx'],
  'application/excel': ['.xls'],
  'application/xlsx': ['.xlsx'],
};

export const muiResponsive = {
  MOBILE: '(max-width:600px)',
  TABLET: '(max-width:960px)',
  LARGE_SCREEN: '(max-width:1200px)',
  EXTRA_LARGE_SCREEN: '(max-width:1440px)',
};

export const COLOR_CODE = {
  PRIMARY: '#005FA9',
  PRIMARY_DARK: '#1f1f1f', // primary 900
  PRIMARY_LIGHT: '#B9B9B9', // primary 300
  SECONDARY: '#0088CC',
  SUCCESS: '#2D934E',
  LIGHT_SUCCESS: '#3EAC57',
  WARNING: '#E87839',
  DANGER: '#FF424F',
  WHITE: '#ffffff',
  LIGHT: '#E0E0E0',
  BLACK: '#000000',
  BACKGROUND: '#f7f8fa',
  INFO: '#0088CC',
  DISABLED: '#91979E',
  PRIMARY_900: '#1f1f1f',
  PRIMARY_800: '#1f1f1f',
  PRIMARY_700: '#333333',
  PRIMARY_600: '#525252',
  PRIMARY_500: '#787878',
  PRIMARY_400: '#999999',
  PRIMARY_300: '#b9b9b9',
  PRIMARY_200: '#d9d9d9',
  PRIMARY_100: '#ececec',
  PRIMARY_50: '#f8f8f8',
  PRIMARY_BG: '#e3f3ff',
  DEFAULT_BORDER: '1px solid #ccc',
  GRAY: '#edeff1',
  GRAY_600: '#6D7176',
  GRAY_300: '#CFD4D9',
  GRAY_50: '#F8F8F9',
  GRAY_900: '#1B1C1E',
  GRAY_92: '#ebebeb',
  WHITE_SMOKE: '#f5f5f5',
  SUCCESS_ALERT: '#d4edda',
  ERROR_ALERT: '#fdecea',
  WARNING_ALERT: '#fff3cd',
  INFO_ALERT: '#e6f2fa',
  BLACK_500: '#2f302f',
  AVAILABLE: '#3eac57',
  BOOKED: '#ff424f',
  DOCUMENT_LINK: '#0070C0',
  FUNC_DANGER: '#BB3131',
};

export enum BOOLEAN {
  true = 'true',
  false = 'false',
}

export const NO_OPENER = 'noopener noreferrer';

export enum SIDEBAR_CATEGORY {
  ECMS = 'eCMS',
  CASES = 'Cases',
  FORMS = 'Forms',
  TDI_CASES = 'TDI Cases',
  WC_COVERAGE = 'WC Coverage',
  TDI_COVERAGE = 'TDI Coverage',
  PHC_COVERAGE = 'PHC Coverage',
}

export enum AppName {
  DCDEcms = 'DCD_ECMS',
  TDI = 'TDI_CASES',
  WCCoverage = 'WC_COVERAGE',
  TDICoverage = 'TDI_COVERAGE',
  PHCCoverage = 'PHC_COVERAGE',
  Forms = 'FORMS',
  Admin = 'ADMIN',
}

export const YYYYMM_REGEX_PATTERN = /^(0[1-9]|[1-9][0-9])[0-9]{2}(0[1-9]|1[0-2])$/;

export const DOCUMENT_DATE_FORMAT = 'MMM D, YYYY';

export enum NewAmend {
  New = 'NEW',
  Amend = 'AMEND',
}

export const newAmendOptions = [
  {
    label: 'New',
    value: NewAmend.New,
  },
  {
    label: 'Amend',
    value: NewAmend.Amend,
  },
];

export enum YesNo {
  Yes = 'Yes',
  No = 'No',
}

export const yesNoOptions = [
  {
    label: 'Yes',
    value: YesNo.Yes,
  },
  {
    label: 'No',
    value: YesNo.No,
  },
];
