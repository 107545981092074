import { Account, AccountDetail } from '../Accounts';
import { DisbursementKey } from '../Disbursements';
import { ChangeControlCaseKey, HoldDisbursementsKey, TransferCaseLocationKey } from '../keys';
import { UserDetail } from '../Users';

export enum DCDCaseKey {
  Id = 'id',
  CaseNumber = 'caseNumber',
  Claimant = 'claimant',
  EmployerName = 'employerName',
  ClaimantId = 'claimantId',
  DateOfInjury = 'dateOfInjury',
  ClaimantPhoneNumber = 'claimantPhoneNumber',
  Employer = 'employer',
  IllnessDate = 'dateOfInjury',
  Status = 'status',
  StatusLastChangedDate = 'statusUpdatedAt',
  CreatedByUser = 'createdUser',
  CreatedAt = 'createdAt',
  UpdatedByUser = 'updatedUser',
  LastModifiedDate = 'updatedAt',
  APRepresentative = 'apRepresentative',
  SCFRepresentative = 'scfRepresentative',
  LocationOfInjuryCode = 'injuryLocation',
  DCDCaseLiaisonId = 'dcdLiaisonId',
  DCDCaseLiaison = 'dcdLiaison',
  EmployerPosition = 'employerPosition',
  AppealStatus = 'appealStatus',
  Court = 'court',
  MinimumWCR = 'minWcr',
  MaximumWCR = 'maxWcr',
  MostRecentWC1 = 'mostRecentWC1',
  RecentWc1Id = 'recentWc1Id',
  ClaimWithdrawnDate = 'claimWithdrawDate',
  IMERequestedDate = 'imeRequestedDate',
  Wc18SentDate = 'wc18SentDate',
  TimeOfInjuryIllness = 'timeOfInjury',
  PrimaryCase = 'primaryCaseId', ///
  CaseStatus = 'caseStatus',
  ReopenedCaseExplanation = 'reopenExplain',
  TemporaryRemandStatus = 'remandStatus',
  TransferApprovalStatus = 'transferApprovalStatus',
  TemporaryRemandReason = 'remandReason',
  CaseReopenedDate = 'caseReopenedDate',
  CaseOpenedDate = 'caseOpenedDate',
  ClaimEndedDate = 'claimEndedDate',
  ControlledBy = 'controlledBy',
  OriginalLocation = 'originalLocation',
  TransferredServicingLocation = 'transferServiceLocation',
  OfficeTransferDate = 'officeTransferDate',
  CaseAdministratorId = 'caseAdminId',
  CaseAdministrator = 'caseAdmin',
  CountyCode = 'countyCode',
  TimeWorkdayBegan = 'workdayBegin',
  HowAccidentOccur = 'accidentOccurDesc',
  AccidentEmployeeDoing = 'accidentEmployeeDoing',
  CurrentServicingLocation = 'currentServicingLocation',
  MostRecentWC77ReceivedDate = 'recentWc77ReceivedDate',
  TransferReason = 'transferReason',
  AppealReceivedDate = 'appealReceivedDate',
  // Missing field
  FilterClaimantSocialSecurityNumber = 'claimant.ssn',
  FilterClaimantPassportNumber = 'claimant.passport',
  ClaimantSocialSecurityNumber = 'claimantSocialSecurityNumber',
  ClaimantPassportNumber = 'claimantPassportNumber',
  HourlyWage = 'hourlyWage',
  AverageWeeklyWage = 'avgWeeklyWage',
  MonthlySalary = 'monthlySalary',
  HRSWorkedWeek = 'hoursWorkWeek',
  WeighingFactor = 'weightingFactor',
  AdjusterCaseNumber = 'adjusterClaimNumber',
  MedicalDeductibleFlag = 'medicalDeductibleFlag',
  IsLiabilityDenied = 'isLiabilityDenied',
  CarrierCaseNumber = 'carrierClaimNumber',
  MedicalDeductibleAmount = 'medicalDeductible',
  TotalBenefitsAmountPaid = 'totalBenefitAmountPaid',
  Task = 'injuryTask',
  Activity = 'injuryActivity',
  InjuryIllnessFactor = 'injuryFactor',
  AOS = 'aos',
  SourceOfInjuryIllness = 'sourceInjury',
  MultipleBodyParts = 'isMultipleBodyParts',
  OnEmployerPremise = 'onEmployerPremise',
  WhereDidIIOccur = 'whereInjuryOccur',
  PossibilityOfBurn = 'possibilityBurn',
  PossibilityOfDisfigurement = 'possibilityDisfigurement',
  NatureOfII = 'natureOfBusiness',
  LapsedTime = 'recentWc1.lapsedTimeHours',
  MostRecentWC1ReceivedDate = 'rawDateReceived',
  WC1SignatureDate = 'signatureDate',
  MostRecentWC5ReceivedDate = 'wc5ReceivedDate',

  DateDisabilityBegan = 'dateDisabilityBegan',
  DateBackToWork = 'dateBackToWork',
  EmployeePaidInFullForDayOfII = 'isEmployeePaidForFullDayOfInjury',
  EmployeeFurnishedMealsTips = 'wasEmployeeFurnishesMealsTipsLodging',
  Prefabricated = 'prefabricatedFrom',
  FullTimePartTime = 'fulltimeParttime',
  Occupation = 'occupation',
  YearsEmployed = 'yearEmployed',

  FilterDateDisabilityBegan = 'recentWc1.dateDisabilityBegan',
  FilterDateBackToWork = 'recentWc1.dateBackToWork',
  FilterEmployeePaidInFullForDayOfII = 'recentWc1.isEmployeePaidForFullDayOfInjury',
  FilterEmployeeFurnishedMealsTips = 'recentWc1.wasEmployeeFurnishesMealsTipsLodging',
  FilterPrefabricated = 'recentWc1.prefabricatedFrom',
  FilterFullTimePartTime = 'recentWc1.fulltimeParttime',
  FilterOccupation = 'recentWc1.occupation',
  FilterYearsEmployed = 'recentWc1.yearEmployed',
  EmployerId = 'employerId',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  FilterHourlyWage = 'recentWc1.hourlyWage',
  FilterAverageWeeklyWage = 'recentWc1.avgWeeklyWage',
  FilterMonthlySalary = 'recentWc1.monthlySalary',
  FilterHRSWorkedWeek = 'recentWc1.hoursWorkWeek',
  FilterWeighingFactor = 'recentWc1.weightingFactor',
  FilterAdjusterCaseNumber = 'recentWc1.adjusterClaimNumber',
  FilterMedicalDeductibleFlag = 'recentWc1.medicalDeductibleFlag',
  FilterIsLiabilityDenied = 'recentWc1.isLiabilityDenied',
  FilterCarrierCaseNumber = 'recentWc1.carrierClaimNumber',
  FilterMedicalDeductibleAmount = 'recentWc1.medicalDeductible',
  FilterTotalBenefitsAmountPaid = 'recentWc1.totalBenefitAmountPaid',
  FilterTask = 'recentWc1.injuryTask',
  FilterActivity = 'recentWc1.injuryActivity',
  FilterInjuryIllnessFactor = 'recentWc1.injuryFactor',
  FilterAOS = 'recentWc1.aos',
  FilterSourceOfInjuryIllness = 'recentWc1.sourceInjury',
  FilterMultipleBodyParts = 'recentWc1.isMultipleBodyParts',
  FilterOnEmployerPremise = 'recentWc1.onEmployerPremise',
  FilterWhereDidIIOccur = 'recentWc1.whereInjuryOccur',
  FilterPossibilityOfBurn = 'recentWc1.possibilityBurn',
  FilterPossibilityOfDisfigurement = 'recentWc1.possibilityDisfigurement',
  FilterNatureOfII = 'recentWc1.natureOfBusiness',
  FilterLapsedTime = 'recentWc1.lapsedTimeHours',
  FilterMostRecentWC1ReceivedDate = 'recentWc1.rawDateReceived',
  FilterWC1SignatureDate = 'recentWc1.signatureDate',
  FilterMostRecentWC5ReceivedDate = 'recentWc1.wc5ReceivedDate',

  // SCF Cases
  SCFNumberOfWeeks = 'scfNumberOfWeeks',
  SCFTotalAmount = 'scfTotalAmount',
  SCFWeeklyBenefit = 'scfWeeklyBenefitAmount',
  SCFCalculatedAmount = 'scfCalculatedAmount',
  SCFRunningTotalDisbursements = 'scfRunningTotalDisbursement',
  SCFControlType = 'scfControlType',
  SCFControlNumber = 'scfControlNum',
  SCFInvolving = 'scfInvolving',
  SCFHoldDate = 'scfHoldDate',
  SCFRequestDate = 'scfRequestDate',
  SCFWc3ReceivedDate = 'scfWc3ReceivedDate',
  SCFDagsLastCheckDate = 'scfDagsLastCheckDate',
  SCFDailyBenefitAmount = 'scfDailyBenefitAmount',
  SCFMonthlyBenefitAmount = 'scfMonthlyBenefitAmount',
  SCFComment = 'scfComment',
  SCFIsInCareOf = 'scfIsInCareOf',
  SCFInCareOfContactName = 'scfInCareOfContactName',
  SCFInCareOfAddress = 'scfInCareOfAddress',
  SCFInCareOfCity = 'scfInCareOfCity',
  SCFInCareOfState = 'scfInCareOfState',
  SCFInCareOfZipcode = 'scfInCareOfZipcode',
  SCFFiscalContactName = 'scfFiscalContactName',
  SCFFiscalContactPhone = 'scfFiscalContactPhone',
  SCFCheckContactName = 'scfCheckContactName',
  SCFCheckContactPhone = 'scfCheckContactPhone',
  SCFCheckContactFax = 'scfCheckContactFax',
  RecentWC1 = 'recentWc1',
}

export interface DCDCase {
  [DCDCaseKey.Id]: string;
  [DCDCaseKey.CaseNumber]: string;
  [DCDCaseKey.Claimant]: AccountDetail;
  [DCDCaseKey.Employer]: AccountDetail;
  [DCDCaseKey.IllnessDate]: string;
  [DCDCaseKey.EmployerName]: string;
  [DCDCaseKey.Status]: string;
  [DCDCaseKey.DateOfInjury]: string;
  [DCDCaseKey.ClaimantPhoneNumber]: string;
  [DCDCaseKey.StatusLastChangedDate]: string;
  [DCDCaseKey.CreatedByUser]: string;
  [DCDCaseKey.LastModifiedDate]: string;
  [DCDCaseKey.UpdatedBy]: string;
  [DCDCaseKey.CreatedBy]: string;
}

export enum DCDCaseStatus {
  NewCase = 'NEW_CASE',
  InProgress = 'IN_PROGRESS',
  Filed = 'FILED',
  UnderReview = 'UNDER_REVIEW',
  Established = 'ESTABLISHED',
  HearingReview = 'HEARING_REVIEW',
  Appeals = 'APPEALS',
}

export const dcdCaseStatusOptions = [
  {
    value: DCDCaseStatus.NewCase,
    label: 'New Case',
  },
  {
    value: DCDCaseStatus.InProgress,
    label: 'In Progress',
  },
  {
    value: DCDCaseStatus.Filed,
    label: 'Filed',
  },
  {
    value: DCDCaseStatus.UnderReview,
    label: 'Under Review',
  },
  {
    value: DCDCaseStatus.Established,
    label: 'Established',
  },
  {
    value: DCDCaseStatus.HearingReview,
    label: 'Hearing Review',
  },
  {
    value: DCDCaseStatus.Appeals,
    label: 'Appeals',
  },
];

export enum DCDCaseDetailKey {
  Id = 'id',
  CaseNumber = 'caseNumber',
  CaseNumberFormat = 'caseNumberFormat',
  ClaimantId = 'claimantId',
  Claimant = 'claimant',
  ClaimantName = 'claimantName',
  ClaimantPhone = 'claimantPhone',
  ClaimantSSN = 'claimantSSN',
  ClaimantAddress = 'claimantAddress',
  ClaimantDOB = 'claimantDOB',
  ClaimantDateOfDeath = 'claimantDateOfDeath',
  Employer = 'employer',
  EmployerId = 'employerId',
  EmployerName = 'employerName',
  EmployerLaborNumber = 'employerLaborNumber',
  EmployerNumOfHawaiiEmployees = 'employerNumOfHawaiiEmployees',
  EmployerStreet = 'employerStreet',
  EmployerCity = 'employerCity',
  EmployerState = 'employerState',
  EmployerZip = 'employerZip',
  DateOfII = 'dateOfInjury',
  APRepresentative = 'apRepresentative',
  SCF = 'scfRepresentative',
  InJuryLocation = 'injuryLocation',
  LapsedTime = 'lapsedTimeHours',
  DCDCaseLiaision = 'dcdLiaisonId',
  EmployerPosition = 'employerPosition',
  NoticeOfAppealReceivedDate = 'appealReceivedDate',
  AppealStatus = 'appealStatus',
  Court = 'court',
  MostRecentWC = 'mostRecentWC',
  MostRecentWCId = 'mostRecentWCId',
  MinimumWCR = 'minWcr',
  MaximumWCR = 'maxWcr',
  Status = 'status',
  StatusLastChangedDate = 'statusUpdatedAt',
  ClaimWithdrawnDate = 'claimWithdrawDate',
  Wc1SignatureDate = 'signatureDate',
  MostRecentWC1Date = 'mostRecentWC1Date',
  MostRecentWC5Date = 'mostRecentWC5Date',
  ImeRequestedDate = 'imeRequestedDate',
  Wc18WC18ASent = 'wc18SentDate',
  MostRecentWC77ReceivedDate = 'recentWc77ReceivedDate',
  TimeOfInjury = 'timeOfInjury',
  PrimaryCaseId = 'primaryCaseId',
  PrimaryCase = 'primaryCase',
  CaseStatus = 'caseStatus',
  ReopenedCaseExplanation = 'reopenExplain',

  //Approvals and Requests
  TemporaryRemandStatus = 'remandStatus',
  TransferApprovalStatus = 'transferApprovalStatus',
  TemporaryRemandReason = 'remandReason',
  TransferReason = 'transferReason',

  //Case Administration
  CaseOpenedDate = 'caseOpenedDate',
  ClaimEndedDate = 'claimEndedDate',
  ControlledBy = 'controlledBy',
  CaseReopenedDate = 'caseReopenedDate',

  //DCD Administration
  OriginalLocation = 'originalLocation',
  TransferredServicingLocation = 'transferServiceLocation',
  OfficeTransferDate = 'officeTransferDate',
  CurrentServicingLocation = 'currentServicingLocation',
  CaseAdministrator = 'caseAdminId',
  CountyCode = 'countyCode',

  //Claimant Employment
  ClaimantSocialSecurityNumber = 'claimantSocialSecurityNumber',
  ClaimantPassportNumber = 'claimantPassportNumber',
  DateDisabilityBegan = 'dateDisabilityBegan',
  IfEmployee = 'ifEmployee',
  EmployeePaid = 'employeePaid',
  EmployeeFurnished = 'employeeFurnished',
  Prefabricated = 'prefabricated',
  Occupation = 'occupation',
  FullTime = 'fullTime',
  YearsEmployed = 'yearsEmployed',
  HourlyWage = 'hourlyWage',
  AverageWeeklyWage = 'averageWeeklyWage',
  MonthlySalary = 'monthlySalary',
  HrsWorkedWeek = 'hrsWorkedWeek',
  WeighingFactor = 'weighingFactor',

  //Insurance Carrier
  AdjusterCaseNumber = 'adjusterCaseNumber',
  MedicalDeductibleFlag = 'medicalDeductibleFlag',
  IsLiabilityDenied = 'isLiabilityDenied',
  CarrierCaseNumber = 'carrierCaseNumber',
  MedicalDeductibleAmount = 'medicalDeductibleAmount',
  TotalBenefitsAmountPaid = 'totalBenefitsAmountPaid',

  //Details of Injury
  Task = 'task',
  Activity = 'activity',
  InjuryFactor = 'injuryFactor',
  Aos = 'aos',
  SourceInjury = 'sourceInjury',
  MultipleBody = 'multipleBody',
  TimeWorkdayBegan = 'workdayBegin',
  OnEmployerPremise = 'onEmployerPremise',
  IfNotOnPremise = 'ifNotOnPremise',
  PossibilityOfBurn = 'possibilityOfBurn',
  PossibilityOfDisfigurement = 'possibilityOfDisfigurement',

  //Description of Accident
  How = 'accidentOccurDesc',
  What = 'accidentEmployeeDoing',
  NatureOfInjury = 'natureOfInjury',

  //Audit Information
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedByName = 'createdByName',
  UpdatedByName = 'updatedByName',
  WC1Forms = 'wc1Forms',

  // SCF Cases
  SCFNumberOfWeeks = 'scfNumberOfWeeks',
  SCFTotalAmount = 'scfTotalAmount',
  SCFWeeklyBenefit = 'scfWeeklyBenefitAmount',
  SCFCalculatedAmount = 'scfCalculatedAmount',
  SCFRunningTotalDisbursements = 'scfRunningTotalDisbursement',
  SCFControlType = 'scfControlType',
  SCFControlNumber = 'scfControlNum',
  SCFInvolving = 'scfInvolving',
  SCFHoldDate = 'scfHoldDate',
  SCFRequestDate = 'scfRequestDate',
  SCFWc3ReceivedDate = 'scfWc3ReceivedDate',
  SCFDagsLastCheckDate = 'scfDagsLastCheckDate',
  SCFDailyBenefitAmount = 'scfDailyBenefitAmount',
  SCFMonthlyBenefitAmount = 'scfMonthlyBenefitAmount',
  SCFComment = 'scfComment',
  SCFIsInCareOf = 'scfIsInCareOf',
  SCFInCareOfContactName = 'scfInCareOfContactName',
  SCFInCareOfAddress = 'scfInCareOfAddress',
  SCFInCareOfCity = 'scfInCareOfCity',
  SCFInCareOfState = 'scfInCareOfState',
  SCFInCareOfZipcode = 'scfInCareOfZipcode',
  SCFFiscalContactName = 'scfFiscalContactName',
  SCFFiscalContactPhone = 'scfFiscalContactPhone',
  SCFCheckContactName = 'scfCheckContactName',
  SCFCheckContactPhone = 'scfCheckContactPhone',
  SCFCheckContactFax = 'scfCheckContactFax',

  NewestDecisionCompletedDate = 'newestDecisionCompletedDate',
  RecentWC1Insurance = 'recentWc1Insurance',
  RecentWC1AC = 'recentWc1AC',
  RecentWC1 = 'recentWc1',
}

export enum LedgerKey {
  Id = 'id',
  CaseId = 'caseId',
  Vendor = 'vendor',
  VendorId = 'vendorId',
  DisbursementNumber = 'disburseNumber',
  DisbursementType = 'disburseType',
  Status = 'status',
  DisbursementAmount = 'disburseAmount',
  DAGSDate = 'dagsDate',
  VoucherDate = 'voucherDate',

  AccountName = 'accountName',
}

export enum ScheduleOfDisbursementKey {
  Id = 'id',
  CaseId = 'caseId',
  TypeOfDisability = 'disabilityType',
  HRSSection = 'hrsSection',
  SCFPeriodFrom = 'periodStartDate',
  SCFPeriodTo = 'periodEndDate',
  SCFDisbursementAmount = 'disburseAmount',
  SCFDisbursementCode = 'disburseCode',
  IsAlternatePayee = 'isAlternatePayee',
  CreatedUser = 'createdUser',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  LastModifiedBy = 'updatedUser',
  FirstName = 'firstName',
  LastName = 'lastName',
  ScheduleDisbursementNumber = 'scheduleDisbursementNumber',
}

export enum EmploymentAtTimeOfIIKey {
  Id = 'id',
  CaseId = 'caseId',
  ClaimantId = 'claimantId',
  ClaimantAccount = 'claimantAccount',
  EmployerId = 'employerId',
  EmployerAccount = 'employerAccount',
  IsPrimary = 'isPrimary',
  EmploymentStatus = 'employmentStatus',
  AverageWeeklyWage = 'avgWeeklyWage',
  WC14RequestedDate = 'wc14RequestedDate',
  WC14ReceivedDate = 'wc14ReceivedDate',
  CreatedUser = 'createdUser',
  CreatedAt = 'createdAt',
  UpdatedUser = 'updatedUser',
  UpdatedAt = 'updatedAt',

  CaseNumber = 'caseNumber',
  AccountName = 'accountName',
  AccountType = 'accountType',
}

export enum CasePartiesKey {
  Id = 'id',
  PartyRole = 'partyRole',
  PartyType = 'partyType',
  Account = 'account',
  IsCurrentlyActive = 'isCurrentlyActive',
}

export interface DCDCaseDetail {
  //Case Information
  [DCDCaseDetailKey.Id]?: string;
  [DCDCaseDetailKey.CaseNumber]?: string;
  [DCDCaseDetailKey.CaseNumberFormat]?: string;
  [DCDCaseDetailKey.Claimant]?: AccountDetail;
  [DCDCaseDetailKey.ClaimantId]?: string;
  [DCDCaseDetailKey.ClaimantName]?: string;
  [DCDCaseDetailKey.ClaimantPhone]?: string;
  [DCDCaseDetailKey.ClaimantSSN]?: string;
  [DCDCaseDetailKey.ClaimantAddress]?: string;
  [DCDCaseDetailKey.ClaimantDOB]?: string;
  [DCDCaseDetailKey.EmployerId]?: string;
  [DCDCaseDetailKey.Employer]?: AccountDetail;
  [DCDCaseDetailKey.EmployerName]?: string;
  [DCDCaseDetailKey.EmployerStreet]?: string;
  [DCDCaseDetailKey.EmployerCity]?: string;
  [DCDCaseDetailKey.EmployerState]?: string;
  [DCDCaseDetailKey.EmployerZip]?: string;
  [DCDCaseDetailKey.DateOfII]?: string;
  [DCDCaseDetailKey.APRepresentative]?: string;
  [DCDCaseDetailKey.SCF]?: string;
  [DCDCaseDetailKey.InJuryLocation]?: string;
  [DCDCaseDetailKey.LapsedTime]?: string;
  [DCDCaseDetailKey.DCDCaseLiaision]?: string;
  [DCDCaseDetailKey.EmployerPosition]?: string;
  [DCDCaseDetailKey.NoticeOfAppealReceivedDate]?: Date;
  [DCDCaseDetailKey.AppealStatus]?: string;
  [DCDCaseDetailKey.Court]?: string;
  [DCDCaseDetailKey.MostRecentWC]?: string;
  [DCDCaseDetailKey.MinimumWCR]?: number | string;
  [DCDCaseDetailKey.MaximumWCR]?: number | string;
  [DCDCaseDetailKey.Status]?: string;
  [DCDCaseDetailKey.StatusLastChangedDate]?: Date;
  [DCDCaseDetailKey.ClaimWithdrawnDate]?: Date;
  [DCDCaseDetailKey.Wc1SignatureDate]?: string;
  [DCDCaseDetailKey.MostRecentWC1Date]?: Date;
  [DCDCaseDetailKey.MostRecentWC5Date]?: Date;
  [DCDCaseDetailKey.ImeRequestedDate]?: Date;
  [DCDCaseDetailKey.Wc18WC18ASent]?: Date;
  [DCDCaseDetailKey.MostRecentWC77ReceivedDate]?: Date;
  [DCDCaseDetailKey.TimeOfInjury]?: Date | string;
  [DCDCaseDetailKey.PrimaryCase]?: string;
  [DCDCaseDetailKey.CaseStatus]?: string;
  [DCDCaseDetailKey.ReopenedCaseExplanation]?: string;

  //Approvals and Requests
  [DCDCaseDetailKey.TemporaryRemandStatus]?: string;
  [DCDCaseDetailKey.TransferApprovalStatus]?: string;
  [DCDCaseDetailKey.TemporaryRemandReason]?: string;
  [DCDCaseDetailKey.TransferReason]?: string;

  //Case Administration
  [DCDCaseDetailKey.CaseOpenedDate]?: Date;
  [DCDCaseDetailKey.ClaimEndedDate]?: Date;
  [DCDCaseDetailKey.ControlledBy]?: string;
  [DCDCaseDetailKey.CaseReopenedDate]?: string;

  //DCD Administration
  [DCDCaseDetailKey.OriginalLocation]?: string;
  [DCDCaseDetailKey.TransferredServicingLocation]?: string;
  [DCDCaseDetailKey.OfficeTransferDate]?: Date;
  [DCDCaseDetailKey.CurrentServicingLocation]?: string;
  [DCDCaseDetailKey.CaseAdministrator]?: string;
  [DCDCaseDetailKey.CountyCode]?: string;

  //Claimant Employment
  [DCDCaseDetailKey.ClaimantSocialSecurityNumber]?: string;
  [DCDCaseDetailKey.ClaimantPassportNumber]?: string;
  [DCDCaseDetailKey.DateDisabilityBegan]?: Date;
  [DCDCaseDetailKey.IfEmployee]?: Date;
  [DCDCaseDetailKey.EmployeePaid]?: boolean;
  [DCDCaseDetailKey.EmployeeFurnished]?: string;
  [DCDCaseDetailKey.Prefabricated]?: string;
  [DCDCaseDetailKey.Occupation]?: string;
  [DCDCaseDetailKey.FullTime]?: string;
  [DCDCaseDetailKey.YearsEmployed]?: string;
  [DCDCaseDetailKey.HourlyWage]?: string;
  [DCDCaseDetailKey.AverageWeeklyWage]?: string;
  [DCDCaseDetailKey.MonthlySalary]?: string;
  [DCDCaseDetailKey.HrsWorkedWeek]?: string;
  [DCDCaseDetailKey.WeighingFactor]?: string;

  //Insurance Carrier
  [DCDCaseDetailKey.AdjusterCaseNumber]?: string;
  [DCDCaseDetailKey.MedicalDeductibleFlag]?: boolean;
  [DCDCaseDetailKey.IsLiabilityDenied]?: boolean;
  [DCDCaseDetailKey.CarrierCaseNumber]?: string;
  [DCDCaseDetailKey.MedicalDeductibleAmount]?: string;
  [DCDCaseDetailKey.TotalBenefitsAmountPaid]?: string;

  //Details of Injury
  [DCDCaseDetailKey.Task]?: string;
  [DCDCaseDetailKey.Activity]?: string;
  [DCDCaseDetailKey.InjuryFactor]?: string;
  [DCDCaseDetailKey.Aos]?: string;
  [DCDCaseDetailKey.SourceInjury]?: string;
  [DCDCaseDetailKey.MultipleBody]?: boolean;
  [DCDCaseDetailKey.TimeWorkdayBegan]?: Date | string;
  [DCDCaseDetailKey.OnEmployerPremise]?: boolean;
  [DCDCaseDetailKey.IfNotOnPremise]?: string;
  [DCDCaseDetailKey.PossibilityOfBurn]?: boolean;
  [DCDCaseDetailKey.PossibilityOfDisfigurement]?: boolean;

  //Description of Accident
  [DCDCaseDetailKey.How]?: string;
  [DCDCaseDetailKey.What]?: string;
  [DCDCaseDetailKey.NatureOfInjury]?: string;

  //Audit Information
  [DCDCaseDetailKey.CreatedAt]?: string;
  [DCDCaseDetailKey.UpdatedAt]?: string;
  [DCDCaseDetailKey.CreatedByName]?: string;
  [DCDCaseDetailKey.UpdatedByName]?: string;
  [DCDCaseDetailKey.WC1Forms]?: any[];

  //SCF Case
  [DCDCaseKey.SCFNumberOfWeeks]?: string;
  [DCDCaseKey.SCFTotalAmount]?: string;
  [DCDCaseKey.SCFWeeklyBenefit]?: string;
  [DCDCaseKey.SCFCalculatedAmount]?: string;
  [DCDCaseKey.SCFRunningTotalDisbursements]?: string;
  [DCDCaseKey.SCFControlType]?: string;
  [DCDCaseKey.SCFControlNumber]?: string;
  [DCDCaseKey.SCFInvolving]?: boolean;
  [DCDCaseKey.SCFHoldDate]?: string;
  [DCDCaseKey.SCFRequestDate]?: string;
  [DCDCaseKey.SCFWc3ReceivedDate]?: string;
  [DCDCaseKey.SCFDagsLastCheckDate]?: string;
  [DCDCaseKey.SCFDailyBenefitAmount]?: string;
  [DCDCaseKey.SCFMonthlyBenefitAmount]?: string;
  [DCDCaseKey.SCFComment]?: string;
  [DCDCaseKey.SCFIsInCareOf]?: boolean;
  [DCDCaseKey.SCFInCareOfContactName]?: string;
  [DCDCaseKey.SCFInCareOfAddress]?: string;
  [DCDCaseKey.SCFInCareOfCity]?: string;
  [DCDCaseKey.SCFInCareOfState]?: string;
  [DCDCaseKey.SCFInCareOfZipcode]?: string;
  [DCDCaseKey.SCFFiscalContactName]?: string;
  [DCDCaseKey.SCFFiscalContactPhone]?: string;
  [DCDCaseKey.SCFCheckContactName]?: string;
  [DCDCaseKey.SCFCheckContactPhone]?: string;
  [DCDCaseKey.SCFCheckContactFax]?: string;

  [DCDCaseDetailKey.NewestDecisionCompletedDate]?: string;
  [DCDCaseDetailKey.RecentWC1Insurance]?: string;
  [DCDCaseDetailKey.RecentWC1AC]?: string;
  [DCDCaseDetailKey.RecentWC1]?: {
    hourlyWage: string;
  };
}

export interface DCDCaseEdit {
  [DCDCaseDetailKey.Id]?: string;
  [DCDCaseDetailKey.Claimant]?: AccountDetail;
  [DCDCaseDetailKey.ClaimantId]?: string;
  [DCDCaseDetailKey.Employer]?: AccountDetail;
  [DCDCaseDetailKey.EmployerId]?: string;
  [DCDCaseDetailKey.DateOfII]?: Date;
  [DCDCaseDetailKey.APRepresentative]?: string;
  [DCDCaseDetailKey.SCF]?: string;
  [DCDCaseDetailKey.InJuryLocation]?: string;
  [DCDCaseDetailKey.EmployerPosition]?: string;
  [DCDCaseDetailKey.NoticeOfAppealReceivedDate]?: Date;
  [DCDCaseDetailKey.AppealStatus]?: string;
  [DCDCaseDetailKey.Court]?: string;
  [DCDCaseDetailKey.MinimumWCR]?: number;
  [DCDCaseDetailKey.MaximumWCR]?: number;
  [DCDCaseDetailKey.Status]?: string;
  [DCDCaseDetailKey.StatusLastChangedDate]?: Date;
  [DCDCaseDetailKey.ImeRequestedDate]?: Date;
  [DCDCaseDetailKey.Wc18WC18ASent]?: Date;
  [DCDCaseDetailKey.MostRecentWC77ReceivedDate]?: Date;
  [DCDCaseDetailKey.TimeOfInjury]?: Date;
  [DCDCaseDetailKey.CaseStatus]?: string;
  [DCDCaseDetailKey.ReopenedCaseExplanation]?: string;
  [DCDCaseDetailKey.TemporaryRemandStatus]?: string;
  [DCDCaseDetailKey.CaseOpenedDate]?: Date;
  [DCDCaseDetailKey.CaseReopenedDate]?: Date;
  [DCDCaseDetailKey.ClaimEndedDate]?: Date;
  [DCDCaseDetailKey.ClaimWithdrawnDate]?: Date;
  [DCDCaseDetailKey.ControlledBy]?: string;
  [DCDCaseDetailKey.OriginalLocation]?: string;
  [DCDCaseDetailKey.TransferredServicingLocation]?: string;
  [DCDCaseDetailKey.OfficeTransferDate]?: Date;
  [DCDCaseDetailKey.CountyCode]?: string;
  [DCDCaseDetailKey.TimeWorkdayBegan]?: Date;
  [DCDCaseDetailKey.How]?: string;
  [DCDCaseDetailKey.What]?: string;
  [DCDCaseDetailKey.CaseAdministrator]?: string;

  //SCF Case
  [DCDCaseKey.SCFNumberOfWeeks]?: number;
  [DCDCaseKey.SCFTotalAmount]?: number;
  [DCDCaseKey.SCFWeeklyBenefit]?: number;
  [DCDCaseKey.SCFCalculatedAmount]?: string;
  [DCDCaseKey.SCFRunningTotalDisbursements]?: string;
  [DCDCaseKey.SCFControlType]?: string;
  [DCDCaseKey.SCFControlNumber]?: string;
  [DCDCaseKey.SCFInvolving]?: string;
  [DCDCaseKey.SCFHoldDate]?: string;
  [DCDCaseKey.SCFRequestDate]?: string;
  [DCDCaseKey.SCFWc3ReceivedDate]?: string;
  [DCDCaseKey.SCFDagsLastCheckDate]?: string;
  [DCDCaseKey.SCFDailyBenefitAmount]?: string;
  [DCDCaseKey.SCFMonthlyBenefitAmount]?: string;
  [DCDCaseKey.SCFComment]?: string;
  [DCDCaseKey.SCFIsInCareOf]?: boolean;
  [DCDCaseKey.SCFInCareOfContactName]?: string;
  [DCDCaseKey.SCFInCareOfAddress]?: string;
  [DCDCaseKey.SCFInCareOfCity]?: string;
  [DCDCaseKey.SCFInCareOfState]?: string;
  [DCDCaseKey.SCFInCareOfZipcode]?: string;
  [DCDCaseKey.SCFFiscalContactName]?: string;
  [DCDCaseKey.SCFFiscalContactPhone]?: string;
  [DCDCaseKey.SCFCheckContactName]?: string;
  [DCDCaseKey.SCFCheckContactPhone]?: string;
  [DCDCaseKey.SCFCheckContactFax]?: string;
}

export interface Vendor {
  [LedgerKey.Id]?: string;
  [LedgerKey.AccountName]?: string;
}

export interface LedgerKeyType {
  [LedgerKey.Id]?: string;
  [LedgerKey.CaseId]?: string;
  [LedgerKey.DisbursementType]?: string;
  [LedgerKey.DisbursementAmount]?: string;
  [LedgerKey.Vendor]?: Vendor;
  [LedgerKey.VendorId]?: string;
  [LedgerKey.VoucherDate]?: string;
  [LedgerKey.DAGSDate]?: string;
  [LedgerKey.DisbursementNumber]?: string;
}

export interface ScheduleOfDisbursement {
  [ScheduleOfDisbursementKey.Id]?: number;
  [ScheduleOfDisbursementKey.CaseId]?: string;
  [ScheduleOfDisbursementKey.TypeOfDisability]?: string;
  [ScheduleOfDisbursementKey.HRSSection]?: string;
  [ScheduleOfDisbursementKey.SCFPeriodFrom]?: string;
  [ScheduleOfDisbursementKey.SCFPeriodTo]?: string;
  [ScheduleOfDisbursementKey.SCFDisbursementAmount]?: number;
  [ScheduleOfDisbursementKey.SCFDisbursementCode]?: string;
  [ScheduleOfDisbursementKey.IsAlternatePayee]?: boolean;
  [ScheduleOfDisbursementKey.CreatedAt]?: string;
  [ScheduleOfDisbursementKey.UpdatedAt]?: string;
  [ScheduleOfDisbursementKey.CreatedUser]?: UserDetail;
  [ScheduleOfDisbursementKey.LastModifiedBy]?: UserDetail;
  [ScheduleOfDisbursementKey.ScheduleDisbursementNumber]?: string;
}

export interface ScheduleOfDisbursementPayload {
  [ScheduleOfDisbursementKey.Id]?: number;
  [ScheduleOfDisbursementKey.TypeOfDisability]?: string;
  [ScheduleOfDisbursementKey.HRSSection]?: string;
  [ScheduleOfDisbursementKey.SCFPeriodFrom]?: string;
  [ScheduleOfDisbursementKey.SCFPeriodTo]?: string;
  [ScheduleOfDisbursementKey.SCFDisbursementAmount]?: number;
  [ScheduleOfDisbursementKey.SCFDisbursementCode]?: string;
  [ScheduleOfDisbursementKey.IsAlternatePayee]?: boolean;
  [ScheduleOfDisbursementKey.CaseId]?: string;
}

export type UploadDCDFilePayload = {
  url: string;
  fileData: File;
};

export type GetPresignedDCDFilePayload = {
  fileName: string;
  contentType: string;
  type: string;
};

export type updateDCDStatusPayload = {
  id: string;
  status: string;
};

export type HoldDisbursementsPayload = {
  [HoldDisbursementsKey.CaseId]: string;
  [HoldDisbursementsKey.HoldDate]: Date | string;
  [HoldDisbursementsKey.RecipientUsers]?: string[];
  [HoldDisbursementsKey.RecipientProfiles]?: string[];
};

export type TransferCaseLocationPayload = {
  [TransferCaseLocationKey.CurrentLocation]?: string;
  [TransferCaseLocationKey.AssignedToGroupName]?: any;
  [TransferCaseLocationKey.TransferReason]?: string;
};

export enum TransferCaseStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export type ChangeControlCase = {
  [ChangeControlCaseKey.CaseId]?: string;
  [ChangeControlCaseKey.Mode]?: string;
  [ChangeControlCaseKey.AppealNumber]?: string;
  [ChangeControlCaseKey.AppealReceivedDate]?: Date | string;
  [ChangeControlCaseKey.RemandReason]?: string;
};

export type EmploymentAccount = {
  [EmploymentAtTimeOfIIKey.Id]?: string;
  [EmploymentAtTimeOfIIKey.AccountName]?: string;
  [EmploymentAtTimeOfIIKey.AccountType]?: string;
};

export type EmploymentAtTimeOfII = {
  [EmploymentAtTimeOfIIKey.Id]?: string;
  [EmploymentAtTimeOfIIKey.CaseId]?: string;
  [EmploymentAtTimeOfIIKey.ClaimantId]?: string;
  [EmploymentAtTimeOfIIKey.ClaimantAccount]?: EmploymentAccount;
  [EmploymentAtTimeOfIIKey.EmployerId]?: string;
  [EmploymentAtTimeOfIIKey.EmployerAccount]?: EmploymentAccount;
  [EmploymentAtTimeOfIIKey.IsPrimary]?: boolean;
  [EmploymentAtTimeOfIIKey.EmploymentStatus]?: string;
  [EmploymentAtTimeOfIIKey.AverageWeeklyWage]?: number;
  [EmploymentAtTimeOfIIKey.WC14ReceivedDate]?: string;
  [EmploymentAtTimeOfIIKey.WC14RequestedDate]?: string;
  [EmploymentAtTimeOfIIKey.CreatedUser]?: UserDetail;
  [EmploymentAtTimeOfIIKey.CreatedAt]?: string;
  [EmploymentAtTimeOfIIKey.UpdatedUser]?: UserDetail;
  [EmploymentAtTimeOfIIKey.UpdatedAt]?: string;
};

export type PaymentReminder = {
  [DisbursementKey.Id]?: string;
  [DisbursementKey.PeriodStartDate]?: Date | string;
  [DisbursementKey.TimeOfNotification]?: Date | string;
  [DisbursementKey.RecipientUsers]?: string[];
  [DisbursementKey.RecipientProfiles]?: string[];
  [DisbursementKey.PaymentReminderAt]?: string;
};

export enum PartyType {
  ACCOUNT = 'Account',
  CONTACT = 'Contact',
}

export enum PartyRole {
  AccidentWitness = 'Accident Witness',
  Adjuster = 'Adjuster',
  AdjustingCompany = 'Adjusting Company',
  Agent = 'Agent',
  Association = 'Association',
  Carrier = 'Carrier',
  Claimant = 'Claimant',
  Dependent = 'Dependent',
  Employer = 'Employer',
  HealthCareProvider = 'Health Care Provider',
  Interpreter = 'Interpreter',
  RelatedParty = 'Related Party',
  Representative = 'Representative',
  VocationalRehabCounselor = 'Vocational Rehab Counselor',
  VocationalRehabProvider = 'Vocational Rehab Provider',
}

export enum RepresentativeFor {
  AdjustingCompanyEmployer = 'Adjusting Company/Employer',
  Carrier = 'Carrier',
  Claimant = 'Claimant',
  SpecialCompensationFund = 'Special Compensation Fund',
}

// TODO: update the complete type
export type CasePartiesAndAddresses = {
  [CasePartiesKey.Id]: string;
  [CasePartiesKey.Account]: Account;
  [CasePartiesKey.PartyRole]: PartyRole;
  [CasePartiesKey.PartyType]: PartyType;
  [CasePartiesKey.IsCurrentlyActive]: boolean;
};
