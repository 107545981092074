import { BenefitPaymentHistoricalKey, FinalReportKey, HistoricalKey, WC3Key } from './keys';

export type HistoricalFormValue = {
  id?: string;
  [HistoricalKey.CaseId]?: string;
  [HistoricalKey.CaseNumber]?: string;
  [HistoricalKey.DateReceived]?: string;
  [HistoricalKey.NewAmend]?: string;
  [HistoricalKey.ClaimantId]?: string;
  [HistoricalKey.EmployerId]?: string;
  [HistoricalKey.InsuranceCarrierId]?: string;
  [HistoricalKey.AdjusterId]?: string;
  [HistoricalKey.AdjusterAddress]?: string;
  [HistoricalKey.WC3Type]?: string;
  [HistoricalKey.WC3TypeValue]?: string;
  [HistoricalKey.ReturnWorkDate]?: string;
  [HistoricalKey.WeeklyCompensationRate]?: string;
  [HistoricalKey.CarrierComment]?: string;
  [HistoricalKey.PrintedNameCarrier]?: string;
  [HistoricalKey.SignatureDate]?: string;
  [HistoricalKey.DateOfII]?: string;
  [HistoricalKey.ClaimantAddress]?: string;
  [HistoricalKey.CarrierId]?: string;
  [HistoricalKey.SSN]?: string;
  [HistoricalKey.CarrierCaseNumber]?: string;
  [HistoricalKey.PaymentYear]?: string;
  [HistoricalKey.BenefitPayments]: {
    [BenefitPaymentHistoricalKey.PaymentType]: BenefitPaymentType;
    [BenefitPaymentHistoricalKey.Days]?: any;
    [BenefitPaymentHistoricalKey.PriorPayments]?: string;
    [BenefitPaymentHistoricalKey.PaymentNotPrevReport]?: any;
  }[];
};

export interface WC3 {
  [WC3Key.RawHeaderCaseNumber]: string;
  [WC3Key.RawHeaderReceived]: string;
  [WC3Key.RawHeaderPrintedNameOfCarrier]: string;
  [WC3Key.RawHeaderPhoneNumber]: string;
  [WC3Key.RawHeaderEmailAddress]: string;
  [WC3Key.RawHeaderSignatureDate]: string;
  [WC3Key.RawHeaderOnBehalfOf]: string;
  [WC3Key.RawClaimant]: string;
  [WC3Key.RawClaimantFirstName]: string;
  [WC3Key.RawClaimantLastName]: string;
  [WC3Key.RawClaimantMiddleInitial]: string;
  [WC3Key.RawClaimantSuffix]: string;
  [WC3Key.RawClaimantSSN]: string;
  [WC3Key.RawClaimantPassportNumber]: string;
  [WC3Key.RawClaimantAddress]: string;
  [WC3Key.RawClaimantCity]: string;
  [WC3Key.RawClaimantState]: string;
  [WC3Key.RawClaimantZipCode]: string;
  [WC3Key.RawClaimantEmailAddress]: string;
  [WC3Key.RawClaimantPhoneNumber]: string;
  [WC3Key.RawClaimantIdNumber]: string;
  [WC3Key.RawClaimantDateOfInjury]: string;
  [WC3Key.RawEmployerName]: string;
  [WC3Key.RawEmployerDBAName]: string;
  [WC3Key.RawEmployerDOL]: string;
  [WC3Key.RawEmployerAddress]: string;
  [WC3Key.RawEmployerCity]: string;
  [WC3Key.RawEmployerState]: string;
  [WC3Key.RawEmployerZipCode]: string;
  [WC3Key.RawEmployerPOC]: string;
  [WC3Key.RawEmployerPOCPhoneNumber]: string;
  [WC3Key.RawEmployerPOCEmailAddress]: string;
  [WC3Key.RawInsuranceCarrier]: string;
  [WC3Key.RawInsuranceCarrierId]: string;
  [WC3Key.RawInsuranceCarrierClaimNumber]: string;
  [WC3Key.RawInsuranceCarrierPolicyNumber]: string;
  [WC3Key.RawInsuranceCarrierPeriodFrom]: string;
  [WC3Key.RawInsuranceCarrierPeriodTo]: string;
  [WC3Key.RawInsuranceCarrierMedicalDeducible]: string;
  [WC3Key.RawInsuranceCarrierEmailAddress]: string;
  [WC3Key.RawInsuranceCarrierAdjusterName]: string;
  [WC3Key.RawInsuranceCarrierPhoneNumber]: string;
  [WC3Key.RawInsuranceCarrierAdjusterId]: string;
  [WC3Key.RawInsuranceCarrierAddress]: string;
  [WC3Key.RawInsuranceCarrierCity]: string;
  [WC3Key.RawInsuranceCarrierState]: string;
  [WC3Key.RawInsuranceCarrierZipCode]: string;
  [WC3Key.RawInsuranceCarrierWC3Type]: string;
  [WC3Key.RawInsuranceCarrierReturnToWorkDate]: string;
  [WC3Key.RawWc3TypeAdditionInformation]: string;
  [WC3Key.RawInsuranceCarrierWeeklyCompensationRate]: string;
  [WC3Key.RawInsuranceCarrierBenefitAdjustment]: string;
  [WC3Key.RawInsuranceCarrierReimbursement]: string;
  [WC3Key.RawInsuranceCarrierCarrierComment]: string;
  [WC3Key.RawTTDPeriod1Days]: string;
  [WC3Key.RawTTDPeriod1StartDate]: string;
  [WC3Key.RawTTDPeriod1EndDate]: string;
  [WC3Key.RawTTDPeriod1Payment]: string;
  [WC3Key.RawTTDPeriod1PaymentNotPreviouslyReported]: string;
  [WC3Key.RawTTDPeriod1TotalPaymentMadeToDate]: string;
  [WC3Key.RawTTDPeriod2Days]: string;
  [WC3Key.RawTTDPeriod2StartDate]: string;
  [WC3Key.RawTTDPeriod2EndDate]: string;
  [WC3Key.RawTTDPeriod2Payment]: string;
  [WC3Key.RawTTDPeriod2PaymentNotPreviouslyReported]: string;
  [WC3Key.RawTTDPeriod2TotalPaymentMadeToDate]: string;
  [WC3Key.RawTTDPeriod3Days]: string;
  [WC3Key.RawTTDPeriod3DateStartDate]: string;
  [WC3Key.RawTTDPeriod3DateEndDate]: string;
  [WC3Key.RawTTDPeriod3Payment]: string;
  [WC3Key.RawTTDPeriod3PaymentNotPreviouslyReported]: string;
  [WC3Key.RawTTDPeriod3TotalPaymentMadeToDate]: string;
  [WC3Key.RawTTDPeriod4Days]: string;
  [WC3Key.RawTTDPeriod4DateStartDate]: string;
  [WC3Key.RawTTDPeriod4DateEndDate]: string;
  [WC3Key.RawTTDPeriod4Payment]: string;
  [WC3Key.RawTTDPeriod4PaymentNotPreviouslyReported]: string;
  [WC3Key.RawTTDPeriod4TotalPaymentMadeToDate]: string;
  [WC3Key.RawTTDPeriod5Days]: string;
  [WC3Key.RawTTDPeriod5DateStartDate]: string;
  [WC3Key.RawTTDPeriod5DateEndDate]: string;
  [WC3Key.RawTTDPeriod5Payment]: string;
  [WC3Key.RawTTDPeriod5PaymentNotPreviouslyReported]: string;
  [WC3Key.RawTTDPeriod5TotalPaymentMadeToDate]: string;

  [WC3Key.RawTTDPeriod6Days]: string;
  [WC3Key.RawTTDPeriod6DateStartDate]: string;
  [WC3Key.RawTTDPeriod6DateEndDate]: string;
  [WC3Key.RawTTDPeriod6Payment]: string;
  [WC3Key.RawTTDPeriod6PaymentNotPreviouslyReported]: string;
  [WC3Key.RawTTDPeriod6TotalPaymentMadeToDate]: string;

  [WC3Key.RawTTDPeriod7Days]: string;
  [WC3Key.RawTTDPeriod7DateStartDate]: string;
  [WC3Key.RawTTDPeriod7DateEndDate]: string;
  [WC3Key.RawTTDPeriod7Payment]: string;
  [WC3Key.RawTTDPeriod7PaymentNotPreviouslyReported]: string;
  [WC3Key.RawTTDPeriod7TotalPaymentMadeToDate]: string;

  [WC3Key.RawTTDPeriod8Days]: string;
  [WC3Key.RawTTDPeriod8DateStartDate]: string;
  [WC3Key.RawTTDPeriod8DateEndDate]: string;
  [WC3Key.RawTTDPeriod8Payment]: string;
  [WC3Key.RawTTDPeriod8PaymentNotPreviouslyReported]: string;
  [WC3Key.RawTTDPeriod8TotalPaymentMadeToDate]: string;

  [WC3Key.RawTTDPeriod9Days]: string;
  [WC3Key.RawTTDPeriod9DateStartDate]: string;
  [WC3Key.RawTTDPeriod9DateEndDate]: string;
  [WC3Key.RawTTDPeriod9Payment]: string;
  [WC3Key.RawTTDPeriod9PaymentNotPreviouslyReported]: string;
  [WC3Key.RawTTDPeriod9TotalPaymentMadeToDate]: string;

  [WC3Key.RawTTDPeriod10Days]: string;
  [WC3Key.RawTTDPeriod10DateStartDate]: string;
  [WC3Key.RawTTDPeriod10DateEndDate]: string;
  [WC3Key.RawTTDPeriod10Payment]: string;
  [WC3Key.RawTTDPeriod10PaymentNotPreviouslyReported]: string;
  [WC3Key.RawTTDPeriod10TotalPaymentMadeToDate]: string;

  [WC3Key.RawTTDPeriod11Days]: string;
  [WC3Key.RawTTDPeriod11DateStartDate]: string;
  [WC3Key.RawTTDPeriod11DateEndDate]: string;
  [WC3Key.RawTTDPeriod11Payment]: string;
  [WC3Key.RawTTDPeriod11PaymentNotPreviouslyReported]: string;
  [WC3Key.RawTTDPeriod11TotalPaymentMadeToDate]: string;

  [WC3Key.RawTTDPeriod12Days]: string;
  [WC3Key.RawTTDPeriod12DateStartDate]: string;
  [WC3Key.RawTTDPeriod12DateEndDate]: string;
  [WC3Key.RawTTDPeriod12Payment]: string;
  [WC3Key.RawTTDPeriod12PaymentNotPreviouslyReported]: string;
  [WC3Key.RawTTDPeriod12TotalPaymentMadeToDate]: string;

  [WC3Key.RawTotalTTDDays]: string;
  [WC3Key.RawTotalTTDPriorPayment]: string;
  [WC3Key.RawTotalTTDPaymentNotPreviouslyReported]: string;
  [WC3Key.RawTotalTTDTotalPaymentMadeToDate]: string;

  //====================================TPD
  [WC3Key.RawTPDPeriod1Days]: string;
  [WC3Key.RawTPDPeriod1StartDate]: string;
  [WC3Key.RawTPDPeriod1EndDate]: string;
  [WC3Key.RawTPDPeriod1Payment]: string;
  [WC3Key.RawTPDPeriod1PaymentNotPreviouslyReported]: string;
  [WC3Key.RawTPDPeriod1TotalPaymentMadeToDate]: string;

  [WC3Key.RawTPDPeriod2Days]: string;
  [WC3Key.RawTPDPeriod2StartDate]: string;
  [WC3Key.RawTPDPeriod2EndDate]: string;
  [WC3Key.RawTPDPeriod2Payment]: string;
  [WC3Key.RawTPDPeriod2PaymentNotPreviouslyReported]: string;
  [WC3Key.RawTPDPeriod2TotalPaymentMadeToDate]: string;

  [WC3Key.RawTPDPeriod3Days]: string;
  [WC3Key.RawTPDPeriod3StartDate]: string;
  [WC3Key.RawTPDPeriod3EndDate]: string;
  [WC3Key.RawTPDPeriod3Payment]: string;
  [WC3Key.RawTPDPeriod3PaymentNotPreviouslyReported]: string;
  [WC3Key.RawTPDPeriod3TotalPaymentMadeToDate]: string;

  [WC3Key.RawTPDPeriod4Days]: string;
  [WC3Key.RawTPDPeriod4StartDate]: string;
  [WC3Key.RawTPDPeriod4EndDate]: string;
  [WC3Key.RawTPDPeriod4Payment]: string;
  [WC3Key.RawTPDPeriod4PaymentNotPreviouslyReported]: string;
  [WC3Key.RawTPDPeriod4TotalPaymentMadeToDate]: string;

  [WC3Key.RawTPDPeriod5Days]: string;
  [WC3Key.RawTPDPeriod5StartDate]: string;
  [WC3Key.RawTPDPeriod5EndDate]: string;
  [WC3Key.RawTPDPeriod5Payment]: string;
  [WC3Key.RawTPDPeriod5PaymentNotPreviouslyReported]: string;
  [WC3Key.RawTPDPeriod5TotalPaymentMadeToDate]: string;

  [WC3Key.RawTotalTPDDays]: string;
  [WC3Key.RawTotalTPDPriorPayment]: string;
  [WC3Key.RawTotalTPDPaymentNotPreviouslyReported]: string;
  [WC3Key.RawTotalTPDTotalPaymentMadeToDate]: string;

  //====================================PTD
  [WC3Key.RawPTDPeriod1Days]: string;
  [WC3Key.RawPTDPeriod1StartDate]: string;
  [WC3Key.RawPTDPeriod1EndDate]: string;
  [WC3Key.RawPTDPeriod1Payment]: string;
  [WC3Key.RawPTDPeriod1PaymentNotPreviouslyReported]: string;
  [WC3Key.RawPTDPeriod1TotalPaymentMadeToDate]: string;

  [WC3Key.RawPTDPeriod2Days]: string;
  [WC3Key.RawPTDPeriod2StartDate]: string;
  [WC3Key.RawPTDPeriod2EndDate]: string;
  [WC3Key.RawPTDPeriod2Payment]: string;
  [WC3Key.RawPTDPeriod2PaymentNotPreviouslyReported]: string;
  [WC3Key.RawPTDPeriod2TotalPaymentMadeToDate]: string;

  [WC3Key.RawPTDPeriod3Days]: string;
  [WC3Key.RawPTDPeriod3StartDate]: string;
  [WC3Key.RawPTDPeriod3EndDate]: string;
  [WC3Key.RawPTDPeriod3Payment]: string;
  [WC3Key.RawPTDPeriod3PaymentNotPreviouslyReported]: string;
  [WC3Key.RawPTDPeriod3TotalPaymentMadeToDate]: string;

  [WC3Key.RawPTDPeriod4Days]: string;
  [WC3Key.RawPTDPeriod4StartDate]: string;
  [WC3Key.RawPTDPeriod4EndDate]: string;
  [WC3Key.RawPTDPeriod4Payment]: string;
  [WC3Key.RawPTDPeriod4PaymentNotPreviouslyReported]: string;
  [WC3Key.RawPTDPeriod4TotalPaymentMadeToDate]: string;

  [WC3Key.RawPTDPeriod5Days]: string;
  [WC3Key.RawPTDPeriod5StartDate]: string;
  [WC3Key.RawPTDPeriod5EndDate]: string;
  [WC3Key.RawPTDPeriod5Payment]: string;
  [WC3Key.RawPTDPeriod5PaymentNotPreviouslyReported]: string;
  [WC3Key.RawPTDPeriod5TotalPaymentMadeToDate]: string;

  [WC3Key.RawTotalPTDDays]: string;
  [WC3Key.RawTotalPTDPriorPayment]: string;
  [WC3Key.RawTotalPTDPaymentNotPreviouslyReported]: string;
  [WC3Key.RawTotalPTDTotalPaymentMadeToDate]: string;
  [WC3Key.RawTotalPTDPriorPaymentNotPreviouslyReported]: string;

  //====================================PPD
  [WC3Key.RawPPDPeriod1Days]: string;
  [WC3Key.RawPPDPeriod1StartDate]: string;
  [WC3Key.RawPPDPeriod1EndDate]: string;
  [WC3Key.RawPPDPeriod1Payment]: string;
  [WC3Key.RawPPDPeriod1PaymentNotPreviouslyReported]: string;
  [WC3Key.RawPPDPeriod1TotalPaymentMadeToDate]: string;

  [WC3Key.RawPPDPeriod2Days]: string;
  [WC3Key.RawPPDPeriod2StartDate]: string;
  [WC3Key.RawPPDPeriod2EndDate]: string;
  [WC3Key.RawPPDPeriod2Payment]: string;
  [WC3Key.RawPPDPeriod2PaymentNotPreviouslyReported]: string;
  [WC3Key.RawPPDPeriod2TotalPaymentMadeToDate]: string;

  [WC3Key.RawTotalPPDDays]: string;
  [WC3Key.RawTotalPPDPriorPayment]: string;
  [WC3Key.RawTotalPPDPaymentNotPreviouslyReported]: string;
  [WC3Key.RawTotalPPDTotalPaymentMadeToDate]: string;

  [WC3Key.RawDeathSpouseDays]: string;
  [WC3Key.RawDeathSpouseStartDate]: string;
  [WC3Key.RawDeathSpouseEndDate]: string;
  [WC3Key.RawDeathSpousePayment]: string;
  [WC3Key.RawDeathSpousePaymentNotPreviouslyReported]: string;
  [WC3Key.RawDeathSpouseTotalPaymentMadeToDate]: string;

  //====================================Death
  [WC3Key.RawDeathDependent1Days]: string;
  [WC3Key.RawDeathDependent1StartDate]: string;
  [WC3Key.RawDeathDependent1EndDate]: string;
  [WC3Key.RawDeathDependent1Payment]: string;
  [WC3Key.RawDeathDependent1PaymentNotPreviouslyReported]: string;
  [WC3Key.RawDeathDependent1TotalPaymentMadeToDate]: string;

  [WC3Key.RawDeathDependent2Days]: string;
  [WC3Key.RawDeathDependent2StartDate]: string;
  [WC3Key.RawDeathDependent2EndDate]: string;
  [WC3Key.RawDeathDependent2Payment]: string;
  [WC3Key.RawDeathDependent2PaymentNotPreviouslyReported]: string;

  [WC3Key.RawDeathDependent3Days]: string;
  [WC3Key.RawDeathDependent3StartDate]: string;
  [WC3Key.RawDeathDependent3EndDate]: string;
  [WC3Key.RawDeathDependent3Payment]: string;
  [WC3Key.RawDeathDependent3PaymentNotPreviouslyReported]: string;

  [WC3Key.RawDeathDependent4Days]: string;
  [WC3Key.RawDeathDependent4StartDate]: string;
  [WC3Key.RawDeathDependent4EndDate]: string;
  [WC3Key.RawDeathDependent4Payment]: string;
  [WC3Key.RawDeathDependent4PaymentNotPreviouslyReported]: string;

  [WC3Key.RawDeathDependent5Days]: string;
  [WC3Key.RawDeathDependent5StartDate]: string;
  [WC3Key.RawDeathDependent5EndDate]: string;
  [WC3Key.RawDeathDependent5Payment]: string;
  [WC3Key.RawDeathDependent5PaymentNotPreviouslyReported]: string;

  [WC3Key.RawDeathBurialPayment]: string;
  [WC3Key.RawDeathBurialPaymentNotPreviouslyReported]: string;
  [WC3Key.RawDeathBurialTotalPaymentMadeToDate]: string;

  [WC3Key.RawDeathFuneralPayment]: string;
  [WC3Key.RawDeathFuneralPaymentNotPreviouslyReported]: string;
  [WC3Key.RawDeathFuneralTotalPaymentMadeToDate]: string;

  [WC3Key.RawDeathOther1Days]: string;
  [WC3Key.RawDeathOther1StartDate]: string;
  [WC3Key.RawDeathOther1EndDate]: string;
  [WC3Key.RawDeathOther1Payment]: string;
  [WC3Key.RawDeathOther1PaymentNotPreviouslyReported]: string;
  [WC3Key.RawDeathOther1TotalPaymentMadeToDate]: string;

  [WC3Key.RawDeathOther2Days]: string;
  [WC3Key.RawDeathOther2StartDate]: string;
  [WC3Key.RawDeathOther2EndDate]: string;
  [WC3Key.RawDeathOther2Payment]: string;
  [WC3Key.RawDeathOther2PaymentNotPreviouslyReported]: string;
  [WC3Key.RawDeathOther2TotalPaymentMadeToDate]: string;

  [WC3Key.RawTotalDeathDays]: string;
  [WC3Key.RawTotalDeathPriorPayment]: string;
  [WC3Key.RawTotalDeathPaymentNotPreviouslyReported]: string;
  [WC3Key.RawTotalDeathPaymentMadeToDate]: string;

  [WC3Key.RawBPSummaryTTDDays]: string;
  [WC3Key.RawBpSummaryTTDDateFrom]: string;
  [WC3Key.RawBpSummaryTTDDateTo]: string;
  [WC3Key.RawBpSummaryTTDPaymentNotPrevReport]: string;
  [WC3Key.RawBpSummaryTTDPriorPayment]: string;
  [WC3Key.RawBpSummaryTTDTotalPaymentToDate]: string;

  [WC3Key.RawBPSummaryTPDDays]: string;
  [WC3Key.RawBpSummaryTPDDateFrom]: string;
  [WC3Key.RawBpSummaryTPDDateTo]: string;
  [WC3Key.RawBpSummaryTPDPaymentNotPrevReport]: string;
  [WC3Key.RawBpSummaryTPDPriorPayment]: string;
  [WC3Key.RawBpSummaryTPDTotalPaymentToDate]: string;

  [WC3Key.RawBpSummaryPTDDays]: string;
  [WC3Key.RawBpSummaryPTDDateFrom]: string;
  [WC3Key.RawBpSummaryPTDDateTo]: string;
  [WC3Key.RawBpSummaryPTDPaymentNotPrevReport]: string;
  [WC3Key.RawBpSummaryPTDPriorPayment]: string;
  [WC3Key.RawBpSummaryPTDTotalPaymentToDate]: string;

  [WC3Key.RawBpSummaryPPDDays]: string;
  [WC3Key.RawBpSummaryPPDDateFrom]: string;
  [WC3Key.RawBpSummaryPPDDateTo]: string;
  [WC3Key.RawBpSummaryPPDPaymentNotPrevReport]: string;
  [WC3Key.RawBpSummaryPPDPriorPayment]: string;
  [WC3Key.RawBpSummaryPPDTotalPaymentToDate]: string;

  [WC3Key.RawBpSummaryDeathDays]: string;
  [WC3Key.RawBpSummaryDeathDateFrom]: string;
  [WC3Key.RawBpSummaryDeathDateTo]: string;
  [WC3Key.RawBpSummaryDeathPaymentNotPrevReport]: string;
  [WC3Key.RawBpSummaryDeathPriorPayment]: string;
  [WC3Key.RawBpSummaryDeathTotalPaymentToDate]: string;

  [WC3Key.RawBpSummaryCityCountyDays]: string;
  [WC3Key.RawBpSummaryCityCountyDateFrom]: string;
  [WC3Key.RawBpSummaryCityCountyDateTo]: string;
  [WC3Key.RawBpSummaryCityCountyPaymentNotPrevReport]: string;
  [WC3Key.RawBpSummaryCityCountyPriorPayment]: string;
  [WC3Key.RawBpSummaryCityCountyTotalPaymentToDate]: string;

  [WC3Key.RawBpSummaryDisfigurementDateFrom]: string;
  [WC3Key.RawBpSummaryDisfigurementDateTo]: string;
  [WC3Key.RawBpSummaryDisfigurementPaymentNotPrevReport]: string;
  [WC3Key.RawBpSummaryDisfigurementPriorPayment]: string;
  [WC3Key.RawBpSummaryDisfigurementTotalPaymentToDate]: string;

  [WC3Key.RawBpSummaryMedicalDateFrom]: string;
  [WC3Key.RawBpSummaryMedicalDateTo]: string;
  [WC3Key.RawBpSummaryMedicalPaymentNotPrevReport]: string;
  [WC3Key.RawBpSummaryMedicalPriorPayment]: string;
  [WC3Key.RawBpSummaryMedicalTotalPaymentToDate]: string;

  [WC3Key.RawBpSummaryMaterialDateFrom]: string;
  [WC3Key.RawBpSummaryMaterialDateTo]: string;
  [WC3Key.RawBpSummaryMaterialPaymentNotPrevReport]: string;
  [WC3Key.RawBpSummaryMaterialPriorPayment]: string;
  [WC3Key.RawBpSummaryMaterialTotalPaymentToDate]: string;

  [WC3Key.RawBpSummarySvcAttendantDateFrom]: string;
  [WC3Key.RawBpSummarySvcAttendantDateTo]: string;
  [WC3Key.RawBpSummarySvcAttendantPaymentNotPrevReport]: string;
  [WC3Key.RawBpSummarySvcAttendantPriorPayment]: string;
  [WC3Key.RawBpSummarySvcAttendantTotalPaymentToDate]: string;

  [WC3Key.RawBpSummaryRehabDateFrom]: string;
  [WC3Key.RawBpSummaryRehabDateTo]: string;
  [WC3Key.RawBpSummaryRehabPaymentNotPrevReport]: string;
  [WC3Key.RawBpSummaryRehabPriorPayment]: string;
  [WC3Key.RawBpSummaryRehabTotalPaymentToDate]: string;

  [WC3Key.RawBpSummaryReimbursementDays]: string;
  [WC3Key.RawBpSummaryReimbursementDateFrom]: string;
  [WC3Key.RawBpSummaryReimbursementDateTo]: string;
  [WC3Key.RawBpSummaryReimbursementPaymentNotPrevReport]: string;
  [WC3Key.RawBpSummaryReimbursementPriorPayment]: string;
  [WC3Key.RawBpSummaryReimbursementTotalPaymentToDate]: string;

  [WC3Key.RawBpSummaryAdjustmentDays]: string;
  [WC3Key.RawBpSummaryAdjustmentDateFrom]: string;
  [WC3Key.RawBpSummaryAdjustmentDateTo]: string;
  [WC3Key.RawBpSummaryAdjustmentPaymentNotPrevReport]: string;
  [WC3Key.RawBpSummaryAdjustmentPriorPayment]: string;
  [WC3Key.RawBpSummaryAdjustmentTotalPaymentToDate]: string;

  [WC3Key.RawBpSummaryOther1Days]: string;
  [WC3Key.RawBpSummaryOther1DateFrom]: string;
  [WC3Key.RawBpSummaryOther1DateTo]: string;
  [WC3Key.RawBpSummaryOther1PaymentNotPrevReport]: string;
  [WC3Key.RawBpSummaryOther1PriorPayment]: string;
  [WC3Key.RawBpSummaryOther1TotalPaymentToDate]: string;

  [WC3Key.RawBpSummaryOther2Days]: string;
  [WC3Key.RawBpSummaryOther2DateFrom]: string;
  [WC3Key.RawBpSummaryOther2DateTo]: string;
  [WC3Key.RawBpSummaryOther2PaymentNotPrevReport]: string;
  [WC3Key.RawBpSummaryOther2PriorPayment]: string;
  [WC3Key.RawBpSummaryOther2TotalPaymentToDate]: string;

  [WC3Key.RawBpSummaryGrandTotalDays]: string;
  [WC3Key.RawBpSummaryGrandTotalPaymentNotPrevReport]: string;
  [WC3Key.RawBpSummaryGrandTotalPriorPayment]: string;
  [WC3Key.RawBpSummaryGrandTotalTotalPaymentToDate]: string;

  [WC3Key.Id]: string;
  [WC3Key.FormNumber]: string;
  [WC3Key.CaseId]: string;
  [WC3Key.CreatedAt]: string;
  [WC3Key.UpdatedAt]: string;
  [WC3Key.CreatedBy]: string;
  [WC3Key.UpdatedBy]: string;
  [WC3Key.NewAmend]: string;
  [WC3Key.DateReceived]: string;
  [WC3Key.SignaturePrintedName]: string;
  [WC3Key.SignaturePhone]: string;
  [WC3Key.SignatureEmail]: string;
  [WC3Key.SignatureDate]: string;
  [WC3Key.OnBehalfOf]: string;
  [WC3Key.DateOfInjury]: string;
  [WC3Key.WC3Type]: string;
  [WC3Key.WC3TypeValue]: string;
  [WC3Key.WC3TypeAdditionInformation]: string;
  [WC3Key.ReturnToWorkDate]: string;
  [WC3Key.WeeklyCompensationRate]: string;
  [WC3Key.BenefitAdjustment]: boolean;
  [WC3Key.BenefitReimbursement]: boolean;
  [WC3Key.CarrierComment]: string;
  [WC3Key.MedicalDeductible]: number;
  [WC3Key.FormType]: FormType;
  [WC3Key.ClaimantId]: string;
  [WC3Key.EmployerId]: string;
  [WC3Key.InsuranceCarrierId]: string;
  [WC3Key.AdjusterId]: string;
  [WC3Key.CarrierID]: string;
  [WC3Key.EmployerPointOfContactId]: string;
  [WC3Key.Case]: string;
  [WC3Key.Claimant]: string;
  [WC3Key.Employer]: string;
  [WC3Key.InsuranceCarrier]: string;
  [WC3Key.EmployerPointOfContact]: string;
  [WC3Key.Adjuster]: string;
  [WC3Key.CreatedUser]: string;
  [WC3Key.UpdatedUser]: string;
  [WC3Key.CaseNumber]: string;
  [WC3Key.ClaimantSSN]: string;
  [WC3Key.ClaimantPassportNumber]: string;
  [WC3Key.ClaimantAddress]: string;
  [WC3Key.ClaimantEmail]: string;
  [WC3Key.ClaimantPhone]: string;
  [WC3Key.ClaimantDateOfInjury]: string;
  [WC3Key.EmployerDbaName]: string;
  [WC3Key.EmployerAddress]: string;
  [WC3Key.EmployerPOCPhone]: string;
  [WC3Key.EmployerPOCEmail]: string;
  [WC3Key.EmployerDOL]: string;
  [WC3Key.CarrierNumber]: string;
  [WC3Key.AdjusterPhone]: string;
  [WC3Key.AdjusterAddress]: string;
  [WC3Key.AdjusterIdNumber]: string;
  [WC3Key.InsuranceCarrierPhoneNumber]: string;
  [WC3Key.InsuranceCarrierEmailAddress]: string;
  [WC3Key.InsuranceCarrierAddress]: string;
}

export interface WC3SubmittalPayload {
  id: string;
  [WC3Key.NewAmend]: string;
  [WC3Key.DateReceived]: string;
  [WC3Key.SignaturePrintedName]: string;
  [WC3Key.SignaturePhone]: string;
  [WC3Key.SignatureEmail]: string;
  [WC3Key.SignatureDate]: string;
  [WC3Key.OnBehalfOf]: string;
  [WC3Key.DateOfInjury]: string;
  [WC3Key.WC3Type]: string;
  [WC3Key.WC3TypeValue]: string;
  [WC3Key.WC3TypeAdditionInformation]: string;
  [WC3Key.ReturnToWorkDate]: string;
  [WC3Key.WeeklyCompensationRate]: number;
  [WC3Key.BenefitAdjustment]: boolean;
  [WC3Key.BenefitReimbursement]: boolean;
  [WC3Key.CarrierComment]: string;
  [WC3Key.MedicalDeductible]: number;
  [WC3Key.CaseId]: string;
  [WC3Key.ClaimantId]: string;
  [WC3Key.EmployerId]: string;
  [WC3Key.InsuranceCarrierId]: string;
  [WC3Key.EmployerPointOfContactId]: string;
  [WC3Key.AdjusterId]: string;
  [WC3Key.CreatedAt]: string;
  [WC3Key.ClaimantSSN]: string;
  [WC3Key.ClaimantPassportNumber]: string;
  [WC3Key.ClaimantAddress]: string;
  [WC3Key.ClaimantEmail]: string;
  [WC3Key.ClaimantPhone]: string;

  [WC3Key.EmployerDbaName]: string;
  [WC3Key.EmployerAddress]: string;
  [WC3Key.EmployerPointOfContactId]: string;
  [WC3Key.EmployerPOCPhone]: string;
  [WC3Key.EmployerPOCEmail]: string;

  [WC3Key.InsuranceCarrierId]: string;
  [WC3Key.CarrierNumber]: string;
  [WC3Key.InsuranceCarrierClaimNumber]: string;
  [WC3Key.InsuranceCarrierPolicyNumber]: string;
  [WC3Key.InsuranceCarrierPeriodFrom]: string;
  [WC3Key.InsuranceCarrierPeriodTo]: string;
  [WC3Key.AdjusterId]: string;
  [WC3Key.AdjusterEmail]: string;
  [WC3Key.AdjusterPhone]: string;
  [WC3Key.AdjusterIdNumber]: string;
  [WC3Key.AdjusterAddress]: string;
  [WC3Key.PaymentYear]: string;

  [WC3Key.FormType]: FormType;
}

export enum FormType {
  Effective = 'EFFECTIVE',
  Historical = 'HISTORICAL',
  FinalReport = 'FINAL_REPORT',
}

export enum BenefitPaymentType {
  TTD = 'TTD',
  TPD = 'TPD',
  PTD = 'PTD',
  PPD = 'PPD',
  Death = 'DEATH',
  Disfigurement = 'DISFIGUREMENT',
  Medical = 'MEDICAL',
  ServicesOfAttendant = 'SERVICE_OF_ATTENDANT',
  Rehabilitation = 'REHABILITATION',
  Other = 'OTHER',
  BenefitReimbursement = 'BENEFIT_REIMBURSEMENT',
  BenefitAdjustment = 'BENEFIT_ADJUSTMENT',
  Materials = 'MATERIAL',
  CityCounty = 'CITY_COUNTY',
}

export interface CalculatePriorPaymentPayload {
  [HistoricalKey.CaseId]?: string;
  [HistoricalKey.InsuranceCarrierId]: string;
  [HistoricalKey.PaymentTypes]: Array<BenefitPaymentType>;
  [HistoricalKey.PaymentYear]: string;
}

export interface PriorPaymentResponse {
  paymentType: BenefitPaymentType;
  priorPayment?: number;
}

export interface FinalReportPayload {
  [FinalReportKey.PaymentYear]: string;
  [FinalReportKey.CaseId]: string;
}
