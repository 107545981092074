import { getFirstLastName } from 'src/utils';
import { isEmpty } from 'src/validations';
import { toAuditData } from '../helpers';
import { HC15Key } from './keys';
import { HC15 } from './types';

const getAccountsRelated = (hc15: HC15) =>
  [hc15?.[HC15Key.Employer], hc15?.[HC15Key.HealthCareContractor]].filter(
    (account) => !isEmpty(account)
  );

export const toHC15Detail = (data) => {
  return {
    ...data,
    // Health Care Plan
    [HC15Key.HealthCarePlanName]: data?.hcPlan?.planName || '',
    [HC15Key.HealthCarePlanId]: data?.hcPlan?.id || '',
    [HC15Key.HealthCarePlanNumber]: data?.hcPlan?.planNumber || '',
    [HC15Key.HealthCarePlanIdNumber]: data?.hcPlan?.planIdNumber || '',
    // Employer
    [HC15Key.EmployerId]: data?.employer?.id || '',
    [HC15Key.EmployerName]: data?.employer?.accountName || '',
    [HC15Key.DOLNumber]: data?.employer?.laborNumber || '',
    //Health Care Contractor
    [HC15Key.HealthCareContractorId]: data?.hcContractor?.id || '',
    [HC15Key.HealthCareContractorName]: data?.hcContractor?.accountName || '',
    [HC15Key.HcContractorCity]: data?.hcContractor?.city || '',
    [HC15Key.HcContractorState]: data?.hcContractor?.state || '',
    [HC15Key.HcContractorAddress]: data?.hcContractor?.street || '',
    [HC15Key.HcContractorZipCode]: data?.hcContractor?.zip || '',
    // Assigned to user
    [HC15Key.AssignedToUserName]: data?.assignedUser ? getFirstLastName(data?.assignedUser) : '',
    [HC15Key.AssignedToUserId]: data?.assignedUser?.id || '',

    ...toAuditData(data),
    [HC15Key.Accounts]: getAccountsRelated(data),
  };
};

export const toHC15s = (data) => {
  return {
    ...data,
    data: data.data.map((hc15) => ({
      ...hc15,
      ...toAuditData(hc15),
      [HC15Key.EmployerName]: hc15?.employer?.accountName,
      [HC15Key.DOLNumber]: hc15?.employer?.laborNumber,
      [HC15Key.HealthCarePlanName]: hc15?.hcPlan?.planName,
      [HC15Key.HealthCareContractorName]: hc15?.hcContractor?.accountName,
      [HC15Key.State]: hc15?.hcContractor?.state,
      [HC15Key.City]: hc15?.hcContractor?.city,
      [HC15Key.ZipCode]: hc15?.hcContractor?.zip,
      [HC15Key.AssignedToUserName]: getFirstLastName(hc15?.assignedUser),
    })),
  };
};
