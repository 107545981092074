import { PrehearingForEmployerKey } from './keys';

export enum EmployerFinancialSolvency {
  Solvent = 'Solvent',
  Insolvent = 'Insolvent',
  ToBeDetermined = 'To be determined',
  AuditorReportAttached = 'Auditor report attached',
}

export enum EmployerAcceptLiability {
  AndWillVoluntarilyPayBenefits = 'and Will Voluntarily Pay Benefits',
  ButClaimsFinancialInabilityToPayBenefits = 'but Claims Financial Inability to Pay Benefits',
}

export enum ERRefuseReason {
  AccidentWasNeverReportedByTheEmployee = 'Accident was never reported by the employee',
  Other = 'Other',
}

export enum EmployerAddressStatus {
  CurrentAddress = 'Current Address',
  Unknown = 'Unknown',
  NotCurrent = 'Not Current',
}

export enum ConfirmationEmployerBusiness {
  DCCARecords = 'DCCA Records',
  UIAlphaIndex = 'UI Alpha Index',
  Other = 'Other',
}

export interface PrehearingForEmployer {
  id?: string;
  [PrehearingForEmployerKey.PrehearingID]: string;
  [PrehearingForEmployerKey.HearingDate]: string;
  [PrehearingForEmployerKey.RecommendedAssessmentPeriodFrom]: string;
  [PrehearingForEmployerKey.RecommendedAssessmentPeriodTo]: string;
  [PrehearingForEmployerKey.PenaltyAmount]: string;
  [PrehearingForEmployerKey.CreatedBy]: string;
  [PrehearingForEmployerKey.UpdatedBy]: string;
  [PrehearingForEmployerKey.CaseId]: string;
  [PrehearingForEmployerKey.EmployerName]: string;
  [PrehearingForEmployerKey.EmployerId]: string;
  [PrehearingForEmployerKey.ClaimantName]: string;
  [PrehearingForEmployerKey.ClaimantId]: string;
  [PrehearingForEmployerKey.DateOfII]: string;
  [PrehearingForEmployerKey.ConfirmationEmployerBusiness]: ConfirmationEmployerBusiness;
  [PrehearingForEmployerKey.EmployerAddressOther]: string;
  [PrehearingForEmployerKey.EmployerExplainDifferent]: string;
  [PrehearingForEmployerKey.UnknownAddressReason]: string;
  [PrehearingForEmployerKey.UpdateAddressTo]: string;
  [PrehearingForEmployerKey.EmployerCity]: string;
  [PrehearingForEmployerKey.EmployerState]: string;
  [PrehearingForEmployerKey.EmployerZipCode]: string;

  [PrehearingForEmployerKey.EmployerDOLNumber]: string;
  [PrehearingForEmployerKey.IsRegistered]: string;
  [PrehearingForEmployerKey.EffectiveCoverageFrom]: string;
  [PrehearingForEmployerKey.EffectiveCoverageTo]: string;
  [PrehearingForEmployerKey.PolicyNumber]: string;
  [PrehearingForEmployerKey.IsStillDelinquent]: string;
  [PrehearingForEmployerKey.CoverageNotRequiredExplain]: string;
  [PrehearingForEmployerKey.EmployerWorkerOther]: string;

  [PrehearingForEmployerKey.PenaltyNonCoverageOther]: string;
  [PrehearingForEmployerKey.PenaltyFailureToFileRecommendedAssessmentOfPenalty]: string;
  [PrehearingForEmployerKey.PenaltyFailureToFilePenaltyAmount]: string;
  [PrehearingForEmployerKey.DateWC1FileOn]: string;
  [PrehearingForEmployerKey.ERNotInBusiness]: string;
  [PrehearingForEmployerKey.DCCADateOfLastDateInBusiness]: string;

  [PrehearingForEmployerKey.EmployerCanNotBeLocated]: string;
  [PrehearingForEmployerKey.ERRefuseReason]: ERRefuseReason;
  [PrehearingForEmployerKey.UITerminationDate]: string;
  [PrehearingForEmployerKey.FabricationOther]: string;

  [PrehearingForEmployerKey.EmployerFinancialSolvency]: EmployerFinancialSolvency;
  [PrehearingForEmployerKey.EmployerAudit]: string;

  [PrehearingForEmployerKey.EmployerAcceptLiability]: EmployerAcceptLiability;
  [PrehearingForEmployerKey.EmployerLiabilityOther]: string;

  [PrehearingForEmployerKey.DCDReceivedDate]: string;
  [PrehearingForEmployerKey.EmployeeRepresentedBy]: string;
  [PrehearingForEmployerKey.GeneralContractor]: string;
  [PrehearingForEmployerKey.GeneralContractorAddress]: string;
  [PrehearingForEmployerKey.GeneralContractorCity]: string;
  [PrehearingForEmployerKey.GeneralContractorState]: string;
  [PrehearingForEmployerKey.GeneralContractorZipCode]: string;

  [PrehearingForEmployerKey.Subcontractor]: string;
  [PrehearingForEmployerKey.SubcontractorAddress]: string;
  [PrehearingForEmployerKey.SubcontractorCity]: string;
  [PrehearingForEmployerKey.SubcontractorState]: string;
  [PrehearingForEmployerKey.SubcontractorZipCode]: string;
  [PrehearingForEmployerKey.AddressOfOtherCONTR]: string;

  [PrehearingForEmployerKey.IsNoLawSuitFiled]: string;
  [PrehearingForEmployerKey.LawSuitFiledDate]: string;
  [PrehearingForEmployerKey.CircuitCourtNumber]: string;
  [PrehearingForEmployerKey.FederalCourtNumber]: string;
  [PrehearingForEmployerKey.ClaimantAttorneyName]: string;

  [PrehearingForEmployerKey.DateReferredToLegalOffice]: string;
  [PrehearingForEmployerKey.Kind]: string;
  [PrehearingForEmployerKey.ContemplatedKind]: string;
  [PrehearingForEmployerKey.NotContemplated]: string;

  [PrehearingForEmployerKey.EmployerAddressStatus]: EmployerAddressStatus;
  [PrehearingForEmployerKey.EmployerNumOfHawaiiEmployees]: string;
  [PrehearingForEmployerKey.EmployerDOLNumber]: string;
}
